import React, { useState } from 'react';
import axios from 'axios';
import { CgClose } from 'react-icons/cg';
import Message from '../../common/Message';
import Loading from '../../common/Loading';
import ErrorText from '../../common/ErrorText';
import { API_FIND_ID } from '../../../constants/apiConstants';

const FindIdModal = ({ setFoundedId, showFindPassword, hideFindId }) => {
  const [id, setId] = useState('');
  const [pageIndex, setPageIndex] = useState(0);

  const InputPage = () => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [messageInfo, setMessageInfo] = useState({});

    const checkValid = () => {
      let validationInfo = {};
      if (name.trim() === '') {
        validationInfo.name = '이름을 입력해 주세요.';
      }
      if (mobile.trim() === '') {
        validationInfo.mobile = '핸드폰 번호를 입력해 주세요.';
      }
      if (email.trim() === '') {
        validationInfo.email = '이메일 주소를 입력해 주세요.';
      }

      setMessageInfo(validationInfo);
      const isValid = Object.keys(validationInfo).length === 0;
      return isValid;
    };

    const sendFindId = async (e) => {
      e.preventDefault();

      const isValid = checkValid();
      if (isValid) {
        try {
          setIsLoading(true);

          const url = API_FIND_ID;
          const method = 'POST';
          const data = { name, mobile, email };
          const res = await axios({ url, method, data });
          console.log('sendFindId() - res', res);

          const resData = res.data || {};
          const { id } = resData.data;
          setId(id);
          setPageIndex(1);
        } catch (err) {
          console.log('err', err);

          // const errorMessage =
          //   err.response && err.response.data.msg
          //     ? err.response.data.msg
          //     : err.message;

          // 로그인/아이디 찾기/비밀번호 찾기 실패 메시지는 서버에서 내려오는 메시지 사용하지 않고, 하드코딩으로 고정
          const errorMessage = '회원 정보를 찾을 수 없습니다.';
          const newMessageInfo = { error: errorMessage };
          setMessageInfo(newMessageInfo);
        } finally {
          setIsLoading(false);
        }
      }
    };

    const clearMessage = (key) => {
      const newMessageInfo = { ...messageInfo };
      delete newMessageInfo[key];
      setMessageInfo(newMessageInfo);
    };

    return (
      <div className="find-id">
        {isLoading && <Loading />}
        <div className="find-id-header">
          <div className="find-id-title">아이디 찾기</div>
          <CgClose className="find-id-close" onClick={hideFindId} />
        </div>

        <div className="find-id-body">
          <div className="find-id-body-title">
            가입 시 등록한 정보를 입력해주세요.
          </div>

          <div className="find-id-form-wrap">
            <ul>
              <li
                className={`find-id-form-item ${
                  messageInfo.name && 'error-box'
                }`}
              >
                <span className="find-id-form-label">이름</span>
                <input
                  className="find-id-form-input"
                  name="name"
                  type="text"
                  placeholder="이름"
                  value={name}
                  onChange={(e) => {
                    clearMessage(e.target.name);
                    setName(e.target.value);
                  }}
                />

                {messageInfo.name && (
                  <ErrorText variant="danger">{messageInfo.name}</ErrorText>
                )}
              </li>

              <li
                className={`find-id-form-item ${
                  messageInfo.mobile && 'error-box'
                }`}
              >
                <span className="find-id-form-label">휴대폰 번호</span>
                <input
                  className="find-id-form-input"
                  name="mobile"
                  type="number"
                  placeholder="핸드폰번호 (예시 : 01012345678)"
                  value={mobile}
                  onChange={(e) => {
                    clearMessage(e.target.name);
                    setMobile(e.target.value);
                  }}
                />

                {messageInfo.mobile && (
                  <ErrorText variant="danger">{messageInfo.mobile}</ErrorText>
                )}
              </li>

              <li
                className={`find-id-form-item ${
                  messageInfo.email && 'error-box'
                }`}
              >
                <span className="find-id-form-label">이메일</span>
                <input
                  className="find-id-form-input"
                  name="email"
                  type="email"
                  placeholder="이메일 (예시 : abc@company.com)"
                  value={email}
                  onChange={(e) => {
                    clearMessage(e.target.name);
                    setEmail(e.target.value);
                  }}
                />

                {messageInfo.email && (
                  <ErrorText variant="danger">{messageInfo.email}</ErrorText>
                )}
              </li>
            </ul>
          </div>

          {messageInfo.error && (
            <Message variant="danger">{messageInfo.error}</Message>
          )}
        </div>

        <div className="find-id-footer">
          <button className="btn-cancel" onClick={hideFindId}>
            취소
          </button>

          <button className="btn-confirm" onClick={sendFindId}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const ResultPage = () => (
    <div className="find-id-result">
      <div className="find-id-result-header">
        <div className="find-id-result-title">
          회원님이 가입하신 아이디는 아래와 같습니다.
        </div>
      </div>

      <div className="find-id-result-body">
        <div className="find-id-result-wrap">
          <div className="find-id-result-user">{id}</div>
        </div>
      </div>

      <div className="find-id-result-footer">
        <button
          className="btn-cancel"
          onClick={() => {
            setFoundedId(id);
            hideFindId();
            showFindPassword(id);
          }}
        >
          비밀번호 찾기
        </button>

        <button
          className="btn-confirm"
          onClick={() => {
            setFoundedId(id);
            hideFindId();
          }}
        >
          로그인 하기
        </button>
      </div>
    </div>
  );

  return (
    <div className="find-id-modal">
      {pageIndex == 0 && <InputPage />}
      {pageIndex == 1 && <ResultPage />}
    </div>
  );
};

export default FindIdModal;
