import {
  FIELD_CODE_INFO_REQUEST,
  FIELD_CODE_INFO_SUCCESS,
  FIELD_CODE_INFO_FAILURE,
  TERMS_AND_PRIVACY_REQUEST,
  TERMS_AND_PRIVACY_SUCCESS,
  TERMS_AND_PRIVACY_FAILURE,
  SHOW_MENU,
  HIDE_MENU,
  SHOW_ALERT,
  HIDE_ALERT,
  SHOW_CONFIRM,
  HIDE_CONFIRM,
  SHOW_TOAST,
  HIDE_TOAST,
} from '../types/globalTypes';

const initialState = { codeInfo: {}, fieldInfo: {} };
export const codeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIELD_CODE_INFO_SUCCESS:
      return {
        ...state,
        codeInfo: action.payload.codeInfo,
        fieldInfo: action.payload.fieldInfo,
      };

    case FIELD_CODE_INFO_REQUEST:
    case FIELD_CODE_INFO_FAILURE:
    default:
      return { ...state };
  }
};

export const termsAndPrivacyReducer = (
  state = { terms: '', privacy: '' },
  action
) => {
  switch (action.type) {
    case TERMS_AND_PRIVACY_SUCCESS:
      return {
        ...state,
        terms: action.payload.terms,
        privacy: action.payload.privacy,
      };

    case TERMS_AND_PRIVACY_REQUEST:
    case TERMS_AND_PRIVACY_FAILURE:
    default:
      return { ...state };
  }
};

export const menuReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_MENU:
      return {
        ...state,
        isShow: true,
      };

    case HIDE_MENU:
      return {
        ...state,
        isShow: false,
      };

    default:
      return state;
  }
};

export const alertReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        title: action.payload.title,
        message: action.payload.message,
        yes: action.payload.yes,
        callback: action.payload.callback,
        isShow: true,
      };

    case HIDE_ALERT:
      return {
        ...state,
        title: '',
        message: '',
        yes: '확인',
        callback: null,
        isShow: false,
      };

    default:
      return state;
  }
};

export const confirmReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        ...state,
        title: action.payload.title,
        message: action.payload.message,
        yes: action.payload.yes,
        no: action.payload.no,
        callback: action.payload.callback,
        isShow: true,
      };

    case HIDE_CONFIRM:
      return {
        ...state,
        title: '',
        message: '',
        yes: '확인',
        no: '취소',
        callback: null,
        isShow: false,
      };

    default:
      return state;
  }
};

export const toastReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        message: action.payload.message,
        duration: action.payload.duration,
        callback: action.payload.callback,
        isShow: true,
      };

    case HIDE_TOAST:
      return {
        ...state,
        message: '',
        callback: null,
        isShow: false,
      };

    default:
      return state;
  }
};
