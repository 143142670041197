import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Home from '../pages/home/Home';
import Login from '../pages/login/Login';
import SignUp from '../pages/signUp/SignUp';
import IntroductionAssessment from '../pages/introduction/IntroductionAssessment';
import IntroductionService from '../pages/introduction/IntroductionService';
import AssessmentList from '../pages/assessment/AssessmentList';
import CustomerNotice from '../pages/customer/CustomerNotice';
import CustomerNoticeDetail from '../pages/customer/CustomerNoticeDetail';
import CustomerFaq from '../pages/customer/CustomerFaq';
import CustomerInquiry from '../pages/customer/CustomerInquiry';
import MypageProfile from '../pages/mypage/MypageProfile';
import MypageAssessment from '../pages/mypage/MypageAssessment';
import EmailCheck from '../common/EmailCheck';
import NotFound from '../common/NotFound';
import ServerError from '../common/ServerError';
import Maintenance from '../common/Maintenance';
import Developing from '../dev/Developing';

const Main = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setMobileXScroll();
    moveScrollTop();
  }, [history, location]);

  const setMobileXScroll = () => {
    const pathname = location.pathname;
    const isHome = pathname === '/';
    const isLogin = pathname === '/login';
    const rootWrap = document.querySelector('#root');

    if (isHome || isLogin) {
      rootWrap.className = 'no-mobile-horizontal-scroll';
    } else {
      rootWrap.className = '';
    }
  };

  const moveScrollTop = () => {
    const scrollWrap = document.querySelector('#root');
    scrollWrap.scrollTop = 0;
  };

  return (
    <div id="main">
      <Switch>
        <Route path="/" component={Home} exact></Route>
        <Route path="/login" component={Login}></Route>
        <Route path="/signup" component={SignUp}></Route>
        <Route
          path="/introduction/assessment"
          component={IntroductionAssessment}
        ></Route>
        <Route
          path="/introduction/service"
          component={IntroductionService}
        ></Route>
        <Route
          path="/assessment/:assessmentStep"
          component={AssessmentList}
        ></Route>
        <Route
          path="/customer/notice/:pageIndex?"
          component={CustomerNotice}
          exact
        ></Route>
        <Route
          path="/customer/notice/:seq/:pageIndex?"
          component={CustomerNoticeDetail}
          exact
        ></Route>
        <Route path="/customer/faq" component={CustomerFaq} exact></Route>
        <Route
          path="/customer/inquiry"
          component={CustomerInquiry}
          exact
        ></Route>
        <Route path="/mypage/info" component={MypageProfile} exact></Route>
        <Route
          path="/mypage/assessment"
          component={MypageAssessment}
          exact
        ></Route>

        {/* 그 외 Route */}
        <Route path="/email_check" component={EmailCheck}></Route>
        <Route path="/maintenance" component={Maintenance}></Route>
        <Route path="/error" component={ServerError}></Route>
        <Route path="*" component={NotFound} exact></Route>
      </Switch>
    </div>
  );
};

export default Main;
