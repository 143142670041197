import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import Message from '../../common/Message';
import Loading from '../../common/Loading';
import {
  agreeAssessment,
  resetAgree,
} from '../../../redux/actions/assessmentActions';

const AssessmentAgree = ({
  documentId,
  groupId,
  setIsAgreeModal,
  startAssessment,
  assessmentStep,
}) => {
  const agreeTexts = [
    '본인은 특허평가의 출원인 또는 의뢰인과 이해관계가 없음을 확인합니다.',
    '본인은 본 특허평가를 성실하고 공정하게 하겠습니다.',
    '본인은 본 특허평가와 관련하여 출원인 또는 의뢰인으로부터 어떠한<br />명목으로도 금품이나 향응 등 부당한 이익제공을 요구하거나 받지 않겠습니다.',
    '상기 사항 위반시 향후 불이익이 발생될 수 있음을 확인합니다.',
  ];

  const dispatch = useDispatch();
  const [agrees, setAgrees] = useState([false, false, false, false]);
  const [message, setMessage] = useState('');
  const { isAgree, isLoading, error } = useSelector(
    (state) => state.assessmentAgree
  );

  useEffect(() => {
    if (isAgree) {
      startAssessment();
      setIsAgreeModal(false);
      resetAgreeModal();
    } else if (error) {
      setMessage(error);
    }
  }, [error, isAgree]);

  const confirm = async () => {
    const isAllAgree = !agrees.includes(false);
    if (isAllAgree) {
      const second = assessmentStep === 'second';
      await dispatch(agreeAssessment(documentId, groupId, second));
    } else {
      setMessage('청렴 서약 문항에 모두 체크한 경우에만 평가가 가능합니다.');
    }
  };

  const cancel = () => {
    setIsAgreeModal(false);
    resetAgreeModal();
  };

  const toggleAgree = (isAgree, index) => {
    const newAgree = [...agrees];
    newAgree[index] = isAgree;
    setAgrees(newAgree);
    setMessage('');
  };

  const resetAgreeModal = () => {
    setAgrees([false, false, false, false]);
    dispatch(resetAgree());
  };

  return (
    <>
      {isLoading && <Loading />}

      <div className="modal">
        <div className={`assessment-agree ${message && 'show-message'}`}>
          <div className="assessment-agree-header">
            <div className="assessment-agree-title">
              청렴 서약 후 평가를 시작합니다.
              <br />
              아래 문항을 확인해주세요
            </div>
          </div>

          <div className="assessment-agree-body">
            <ul className="assessment-agree-list">
              {agreeTexts.map((agreeText, index) => (
                <li className="assessment-agree-item" key={index}>
                  <div className="assessment-agree-bullet"></div>
                  <div className="assessment-agree-text">
                    {parse(agreeText || '')}
                  </div>
                  <div className="assessment-agree-checkbox">
                    <input
                      type="checkbox"
                      id={`assessmentAgree${index}`}
                      checked={agrees[index]}
                      onChange={(e) => toggleAgree(e.target.checked, index)}
                    />{' '}
                    <label htmlFor={`assessmentAgree${index}`}>확인</label>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {message && (
            <div className="assessment-agree-message">
              <Message variant="danger">{message}</Message>
            </div>
          )}

          <div className="assessment-agree-footer">
            <button className="btn-negative" onClick={cancel}>
              취소
            </button>
            <button className="btn-confirm" onClick={confirm}>
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentAgree;
