export const API_HOST =
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_DEPLOY_ENV === 'development'
    ? 'https://dev-kppa.keywert.com/v1/kppa' // 개발 서버 API
    : 'https://api-kppa.keywert.com/v1/kppa'; // 운영 서버 API
export const API_HEADER_SYSTEM_NAME = '3a1e4136-e802-11ea-920c-027d1efdddf2';

// 회원 가입, 로그인, 유저 관련 API
export const API_SIGN_IN = `${API_HOST}/users/signin`;
export const API_SIGN_UP = `${API_HOST}/users/signup`;
export const API_USER_REFRESH = `${API_HOST}/users/refresh`;
export const API_EMAIL_RESEND = `${API_HOST}/users/resendEmailRegist`;
export const API_ID_CHECK = `${API_HOST}/users/duplicateCheck`;
export const API_FIND_COMPANY = `${API_HOST}/users/findCompany`;
export const API_FIND_ID = `${API_HOST}/users/findId`;
export const API_FIND_PASSWORD = `${API_HOST}/users/checkUserInfo`;
export const API_RESET_PASSWORD = `${API_HOST}/users/noLogin/changePassword`;
export const API_CHANGE_PASSWORD = `${API_HOST}/users/password`;
export const API_USER_DETAIL = `${API_HOST}/users/detail`;
export const API_USER_UPDATE = `${API_HOST}/users/detail`;
export const API_PASSWORD_UPDATE = `${API_HOST}/users/password`;

// 특허 평가 관련 API
export const API_ASSESSMENT_LIST = `${API_HOST}/surveys/list`;
export const API_ASSESSMENT_LIST_SECOND = `${API_HOST}/surveys/list2nd`;
export const API_ASSESSMENT_COUNT = `${API_HOST}/surveys/currentCount`;
export const API_ASSESSMENT_AGREE = `${API_HOST}/surveys/popupAgree`;
export const API_ASSESSMENT_REQUEST = `${API_HOST}/surveys/request`;
export const API_ASSESSMENT_SUBMIT = `${API_HOST}/surveys/submit`;
export const API_ASSESSMENT_HISTORY = `${API_HOST}/mypage/mySurveyInfo`;

// 고객 센터 관련
export const API_BOARD_LIST = `${API_HOST}/board/searchBoard`;
export const API_BOARD_DETAIL = `${API_HOST}/board/boardDetailBySeq`;
export const API_BOARD_CREATE = `${API_HOST}/board/board`;

// 기타 API
export const API_GET_FIELD_CODE_INFO = `${API_HOST}/techs/search`;
export const API_TERMS_AND_PRIVACY = `${API_HOST}/board/boardNTypeDetail`;
export const API_FILE_UPLOAD = `${API_HOST}/file/upload`;
export const API_FILE_DELETE = `${API_HOST}/file/delete`;
export const API_POPUP_TODAY = `${API_HOST}/popup/popupByFO`;
export const API_POPUP_MESSAGE = `${API_HOST}/mypage/checkInfo`;
export const API_POPUP_MESSAGE_CHECKED = `${API_HOST}/mypage/readInfo`;
