import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Message from '../common/Message';
import { API_EMAIL_RESEND } from '../../constants/apiConstants';
import { logout } from '../../redux/actions/userActions';

const PopupEmailCheck = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [messageInfo, setMessageInfo] = useState({ type: '', text: '' });
  const { userInfo, token } = useSelector((state) => state.user);

  const handleConfirm = async () => {
    try {
      const url = `${API_EMAIL_RESEND}?userId=${userInfo.id}`;
      const method = 'POST';
      const headers = { Authorization: token };

      const res = await axios({ url, method, headers });
      const resData = res.data || {};
      const isSuccess = resData.data === 'OK';
      if (isSuccess)
        setMessageInfo({
          type: 'info',
          text: '이메일 재발송에 성공하였습니다.',
        });
      else throw { message: '이메일 재발송에 실패하였습니다.' };
    } catch (err) {
      console.log('err', err);
      const errorMessage =
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message;
      setMessageInfo({ type: 'danger', text: errorMessage });
    }
  };

  const handleCancel = async () => {
    await dispatch(logout());
    history.push('/login');
  };

  return (
    <div className="popup">
      {messageInfo.text && (
        <Message variant={messageInfo.type}>{messageInfo.text}</Message>
      )}

      <div className="popup-wrap popup-email-check">
        <div className="popup-header">
          <div className="popup-logo"></div>
        </div>

        <div className="popup-body">
          <div className="popup-title">EXsys Value 이메일 인증</div>

          <div className="popup-main">
            <div className="popup-main-title">이메일 인증을 진행해주세요.</div>
            <div className="popup-main-icon" />

            <div className="popup-main-content">
              서비스를 이용하시려면 먼저 이메일 주소를 인증해야 합니다.
              <br />
              아래 ‘인증 메일 보내기’ 버튼을 클릭해주세요.
              <br />
              받으신 이메일(<strong>{userInfo?.email}</strong>)을 열어
              <br />
              메일 인증 후 서비스 이용이 가능합니다.
            </div>

            <div className="popup-main-btn">
              <button className="btn-cancel" onClick={handleCancel}>
                다시 로그인
              </button>

              <button className="btn-confirm" onClick={handleConfirm}>
                인증 메일 보내기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupEmailCheck;
