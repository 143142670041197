export const FIELD_CODE_INFO_REQUEST = 'FIELD_CODE_INFO_REQUEST';
export const FIELD_CODE_INFO_SUCCESS = 'FIELD_CODE_INFO_SUCCESS';
export const FIELD_CODE_INFO_FAILURE = 'FIELD_CODE_INFO_FAILURE';

export const TERMS_AND_PRIVACY_REQUEST = 'TERMS_AND_PRIVACY_REQUEST';
export const TERMS_AND_PRIVACY_SUCCESS = 'TERMS_AND_PRIVACY_SUCCESS';
export const TERMS_AND_PRIVACY_FAILURE = 'TERMS_AND_PRIVACY_FAILURE';

export const SHOW_MENU = 'SHOW_MENU';
export const HIDE_MENU = 'HIDE_MENU';

export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const HIDE_CONFIRM = 'HIDE_CONFIRM';

export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
