import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import Indicator from '../../layouts/Indicator';
import Footer from '../../layouts/Footer';
import MypageMyInfo from './profile/MypageMyInfo';
import MypagePassword from './profile/MypagePassword';
import { USER_ROLE_NAME_INFO } from '../../../constants/dataConstants';

const MypageProfile = ({ history }) => {
  const Tabs = [MypageMyInfo, MypagePassword];

  const { isAuthenticated } = useSelector((state) => state.user);
  const { userDetail } = useSelector((state) => state.userDetail);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (isAuthenticated) {
      console.log('MypageProfile - isAuthenticated', isAuthenticated);
    } else {
      history.push('/login?redirect=/mypage/profile');
    }
  }, []);

  const getInfoText = (role) => {
    // 준회원인 경우
    if (role === 'ROLE_EMPTY') {
      return [
        `* 정회원이 되시면 <strong>특허문헌 평가 서비스 이용이 가능</strong>합니다.`,
        `- 정회원 승인 방법 : 내 정보 관리 > 변리사번호, 기술등급을 입력 후 정회원 승인 요청 버튼을 클릭하면 관리자 확인 후 승인결과 안내를 받으실 수 있습니다.`,
      ];
    }
    // 준회원이 아닐 때, 탈퇴 회원이 아닌 경우
    else if (role !== 'ROLE_WITH') {
      return [
        `<strong>* 특허문헌 평가 서비스 이용이 가능</strong>합니다.`,
        `- 홈 > 특허평가에서 기재하신 기술분야의 특허문헌 평가
        대상을 추천해드립니다.`,
      ];
    }
  };

  return (
    <div className="mypage">
      <div className="mypage-wrap">
        <Indicator />

        <div className="mypage-profile">
          <div className="mypage-profile-header">
            <div className="mypage-profile-jumbotron">
              <div className="mypage-profile-user-info">
                <div className="mypage-profile-user-info-item">
                  <span>회원가입 일시</span>
                  <strong>{userDetail?.crtDate}</strong>
                </div>

                <div className="mypage-profile-user-info-item">
                  <span>마지막 업데이트 일시</span>
                  <strong>{userDetail?.updDate}</strong>
                </div>
              </div>

              <div className="mypage-profile-title">
                <strong>{userDetail?.name}</strong>{' '}
                {USER_ROLE_NAME_INFO[userDetail?.role]}님 안녕하세요.
              </div>

              <div className="mypage-profile-text-list">
                {getInfoText(userDetail?.role).map((text, index) => (
                  <div className="mypage-profile-text-item" key={index}>
                    {parse(text)}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="mypage-profile-body">
            <div className="mypage-profile-tab-list">
              {['내 정보 수정', '비밀번호 재설정'].map((tab, index) => (
                <button
                  className={`mypage-profile-tab-item ${
                    tabIndex === index ? 'active' : ''
                  }`}
                  onClick={() => setTabIndex(index)}
                >
                  {tab}
                </button>
              ))}
            </div>
            {Tabs.map(
              (Tab, index) =>
                index === tabIndex && <Tab userInfo={userDetail} key={index} />
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MypageProfile;
