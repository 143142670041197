import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BiErrorAlt } from 'react-icons/bi';
import {
  forceClosePopup,
  resetPopupCheck,
} from '../../redux/actions/popupActions';

const ServerError = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(forceClosePopup());
    return () => {
      dispatch(resetPopupCheck());
    };
  }, []);

  return (
    <div className="server-error">
      <BiErrorAlt size="150" fill="#082a75" />

      <div className="server-error-message">
        페이지에 일시적인 오류가 있습니다.
        <br />
        잠시 후 다시 시도해주세요.
      </div>
    </div>
  );
};

export default ServerError;
