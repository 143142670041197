import React, { useEffect, useState } from 'react';

const Pagination = ({
  totalCount,
  pageSize,
  pageIndex,
  setPageIndex,
  renewPage,
}) => {
  const [pageGroupIndex, setPageGroupIndex] = useState(0);
  const [pageGroups, setPageGroups] = useState([[]]);

  useEffect(() => {
    setPagination();
  }, [totalCount, pageSize]);

  useEffect(() => {
    if (typeof renewPage === 'function') renewPage();
  }, [pageIndex, pageSize]);

  const setPagination = () => {
    const lastPageNumber = Math.ceil(totalCount / pageSize);

    let groupIndex = 0;
    let pageNumbers = [];
    let newPageGroups = [];

    for (let i = 0; i < lastPageNumber; i++) {
      let pageNumber = i + 1;
      pageNumbers.push(pageNumber);
      newPageGroups[groupIndex] = pageNumbers;

      if (pageNumber % 10 === 0) {
        pageNumbers = [];
        groupIndex++;
      }
    }

    const newPageGroupIndex = newPageGroups.findIndex((newPageGroup) =>
      newPageGroup.includes(pageIndex + 1)
    );

    setPageGroups(newPageGroups);
    setPageGroupIndex(newPageGroupIndex);
    setPageIndex(pageIndex);
  };

  const prevGroup = () => {
    const newPageGroupIndex = Math.max(pageGroupIndex - 1, 0);
    if (newPageGroupIndex !== pageGroupIndex) {
      const newPages = pageGroups[newPageGroupIndex];
      const newPageIndex = newPages[newPages.length - 1] - 1;
      setPageGroupIndex(newPageGroupIndex);
      setPageIndex(newPageIndex);
    }
  };

  const nextGroup = () => {
    const newPageGroupIndex = Math.min(
      pageGroupIndex + 1,
      pageGroups.length - 1
    );

    if (newPageGroupIndex !== pageGroupIndex) {
      const newPages = pageGroups[newPageGroupIndex];
      const newPageIndex = newPages[0] - 1;
      setPageGroupIndex(newPageGroupIndex);
      setPageIndex(newPageIndex);
    }
  };

  return (
    <div className="pagination-wrap">
      <button className="pagination-prev" onClick={prevGroup}></button>

      <div className="pagination-pages">
        {pageGroups[pageGroupIndex]?.map((pageNumber, index) => (
          <button
            className={`pagination-page ${
              pageNumber === pageIndex + 1 && 'active'
            }`}
            key={index}
            onClick={() => setPageIndex(pageNumber - 1)}
          >
            {pageNumber}
          </button>
        ))}
      </div>

      <button className="pagination-next" onClick={nextGroup}></button>
    </div>
  );
};

export default Pagination;
