import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getPopupMessageChecked,
  resetPopupMessageChecked,
} from '../../redux/actions/popupActions';

const PopupSecondAssessment = ({ hidePopup }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isSuccess } = useSelector((state) => state.popupMessageChecked);

  useEffect(() => {
    if (isSuccess) dispatch(resetPopupMessageChecked());
  }, [isSuccess]);

  const handleConfirm = () => {
    dispatch(getPopupMessageChecked());
    hidePopup();
    history.push('/assessment/second');
  };

  return (
    <div className="popup ">
      <div className="popup-wrap popup-second-assessment">
        <div className="popup-header">
          <div className="popup-logo"></div>
        </div>

        <div className="popup-body">
          <div className="popup-title">특허문헌 2차 평가 요청</div>

          <div className="popup-main">
            <div className="popup-main-icon" />

            <div className="popup-main-content">
              안녕하세요. EXsys Value입니다.
              <br />
              평가해주신 특허문헌이 2차 평가 대상으로 분류되었습니다.
              <br />
              <strong>2차 평가 진행</strong> 부탁드립니다.
              <br />
              <br />
              감사합니다.
            </div>

            <div className="popup-main-btn">
              <button className="btn-inactive" onClick={hidePopup}>
                닫기
              </button>

              <button className="btn-confirm" onClick={handleConfirm}>
                2차 평가하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupSecondAssessment;
