export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_LOGIN_CHECK_REQUEST = 'USER_LOGIN_CHECK_REQUEST';
export const USER_LOGIN_CHECK_SUCCESS = 'USER_LOGIN_CHECK_SUCCESS';
export const USER_LOGIN_CHECK_FAILURE = 'USER_LOGIN_CHECK_FAILURE';

export const USER_SIGN_UP_REQUEST = 'USER_SIGN_UP_REQUEST';
export const USER_SIGN_UP_SUCCESS = 'USER_SIGN_UP_SUCCESS';
export const USER_SIGN_UP_FAILURE = 'USER_SIGN_UP_FAILURE';
export const USER_SIGN_UP_RESET = 'USER_SIGN_UP_RESET';

export const USER_CLEAR_ERROR = 'USER_CLEAR_ERROR';

export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST';
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS';
export const USER_DETAIL_FAILURE = 'USER_DETAIL_FAILURE';
export const USER_DETAIL_RESET = 'USER_DETAIL_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_PASSWORD_UPDATE_REQUEST = 'USER_PASSWORD_UPDATE_REQUEST';
export const USER_PASSWORD_UPDATE_SUCCESS = 'USER_PASSWORD_UPDATE_SUCCESS';
export const USER_PASSWORD_UPDATE_FAILURE = 'USER_PASSWORD_UPDATE_FAILURE';
export const USER_PASSWORD_UPDATE_RESET = 'USER_PASSWORD_UPDATE_RESET';
