export const ASSESSMENT_LIST_REQUEST = 'ASSESSMENT_LIST_REQUEST';
export const ASSESSMENT_LIST_SUCCESS = 'ASSESSMENT_LIST_SUCCESS';
export const ASSESSMENT_LIST_FAILURE = 'ASSESSMENT_LIST_FAILURE';

export const ASSESSMENT_COUNT_REQUEST = 'ASSESSMENT_COUNT_REQUEST';
export const ASSESSMENT_COUNT_SUCCESS = 'ASSESSMENT_COUNT_SUCCESS';
export const ASSESSMENT_COUNT_FAILURE = 'ASSESSMENT_COUNT_FAILURE';

export const ASSESSMENT_AGREE_REQUEST = 'ASSESSMENT_AGREE_REQUEST';
export const ASSESSMENT_AGREE_SUCCESS = 'ASSESSMENT_AGREE_SUCCESS';
export const ASSESSMENT_AGREE_FAILURE = 'ASSESSMENT_AGREE_FAILURE';
export const ASSESSMENT_AGREE_CLEAR_ERROR = 'ASSESSMENT_AGREE_CLEAR_ERROR';
export const ASSESSMENT_AGREE_RESET = 'ASSESSMENT_AGREE_RESET';

export const ASSESSMENT_SURVEY_REQUEST = 'ASSESSMENT_SURVEY_REQUEST';
export const ASSESSMENT_SURVEY_SUCCESS = 'ASSESSMENT_SURVEY_SUCCESS';
export const ASSESSMENT_SURVEY_FAILURE = 'ASSESSMENT_SURVEY_FAILURE';
export const ASSESSMENT_SURVEY_RESET = 'ASSESSMENT_SURVEY_RESET';

export const ASSESSMENT_SUBMIT_REQUEST = 'ASSESSMENT_SUBMIT_REQUEST';
export const ASSESSMENT_SUBMIT_SUCCESS = 'ASSESSMENT_SUBMIT_SUCCESS';
export const ASSESSMENT_SUBMIT_FAILURE = 'ASSESSMENT_SUBMIT_FAILURE';
export const ASSESSMENT_SUBMIT_RESET = 'ASSESSMENT_SUBMIT_RESET';

export const ASSESSMENT_HISTORY_REQUEST = 'ASSESSMENT_HISTORY_REQUEST';
export const ASSESSMENT_HISTORY_SUCCESS = 'ASSESSMENT_HISTORY_SUCCESS';
export const ASSESSMENT_HISTORY_FAILURE = 'ASSESSMENT_HISTORY_FAILURE';
export const ASSESSMENT_HISTORY_RESET = 'ASSESSMENT_HISTORY_RESET';
