import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetSignUp, clearError } from '../../../redux/actions/userActions';
import Footer from '../../layouts/Footer';
import Agreement from './Agreement';
import Register from './Register';
import SignUpComplete from './SignUpComplete';
import SignUpIndicator from './SignUpIndicator';

const SignUp = ({ location, history }) => {
  const Menus = [Agreement, Register, SignUpComplete];
  const [menuIndex, setMenuIndex] = useState(0);
  const [agree, setAgree] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetSignUp());
    dispatch(clearError());
  }, []);

  const goNext = () => {
    setMenuIndex(menuIndex + 1);
  };

  return (
    <div className="sign-up">
      <div className="sign-up-wrap">
        <div className="sign-up-header">
          {/* 가입완료 페이지(마지막 index)에서는 디자인 상 페이지 Title, Indicator 보이지 않음 */}
          {Menus.length - 1 !== menuIndex && (
            <>
              <h1 className="sign-up-title">회원가입</h1>

              <SignUpIndicator menuIndex={menuIndex} />
            </>
          )}
        </div>

        <div className="sign-up-body">
          {Menus.map((Menu, index) =>
            index === menuIndex ? (
              <Menu
                location={location}
                history={history}
                agree={agree}
                setAgree={setAgree}
                goNext={goNext}
                key={index}
              ></Menu>
            ) : (
              ''
            )
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SignUp;
