import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../../common/Message';
import Loading from '../../../common/Loading';
import {
  updatePassword,
  resetPasswordUpdate,
} from '../../../../redux/actions/userActions';
import { showToast } from '../../../../redux/actions/globalActions';
import utils from '../../../../modules/utils';

const MypagePassword = () => {
  const dispatch = useDispatch();
  const { error, isLoading, isSuccess } = useSelector(
    (state) => state.passwordUpdate
  );

  const [passwordInfo, setPasswordInfo] = useState({
    password: '',
    newPassword: '',
    passwordConfirm: '',
  });
  const [messageInfo, setMessageInfo] = useState({});

  useEffect(() => {
    if (isSuccess) handleSuccess();
  }, [isSuccess]);

  const handleSuccess = () => {
    dispatch(
      showToast({
        message: '비밀번호 변경에 성공하였습니다.',
      })
    );

    setPasswordInfo({ password: '', newPassword: '', passwordConfirm: '' });
    setMessageInfo({});
    dispatch(resetPasswordUpdate());
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;

    passwordInfo[name] = value;
    clearMessage(name);
  };

  const clearMessage = (key) => {
    const newMessageInfo = { ...messageInfo };
    delete newMessageInfo[key];
    setMessageInfo(newMessageInfo);
  };

  // 정보 Validation
  const checkValid = () => {
    const { password, newPassword, passwordConfirm } = passwordInfo;
    const validationInfo = {};

    if (
      utils.isEmptyString(password) ||
      !/^(?=.*[a-zA-Z0-9~!@$#^]).{8,12}$/.test(password)
    )
      validationInfo['password'] = '비밀번호를 다시 확인해 주세요.';

    if (
      utils.isEmptyString(newPassword) ||
      !/^(?=.*[a-zA-Z0-9~!@$#^]).{8,12}$/.test(newPassword)
    )
      validationInfo['newPassword'] = '비밀번호를 다시 확인해 주세요.';

    if (
      utils.isEmptyString(passwordConfirm) ||
      !/^(?=.*[a-zA-Z0-9~!@$#^]).{8,12}$/.test(passwordConfirm)
    )
      validationInfo['passwordConfirm'] =
        '비밀번호 재입력을 다시 확인해 주세요.';

    if (newPassword !== passwordConfirm)
      validationInfo['passwordConfirm'] = '비밀번호가 일치하지 않습니다.';

    setMessageInfo(validationInfo);
    const isValid = Object.keys(validationInfo).length === 0;
    return isValid;
  };

  const savePassword = () => {
    const isValid = checkValid();

    if (isValid) {
      dispatch(updatePassword(passwordInfo));
    } else {
      dispatch(
        showToast({
          message: '입력된 정보를 다시 확인해 주세요.',
        })
      );
    }
  };

  return (
    <div className="mypage-profile-form-wrap">
      {isLoading && (
        <>
          <Loading />
          {/* CSS Style 에서, 짝수에 걸린 margin 속성으로 인해, 빈 div 추가 */}
          <div />
        </>
      )}
      {error && (
        <>
          <Message variant="danger">{error}</Message>
          {/* CSS Style 에서, 짝수에 걸린 margin 속성으로 인해, 빈 div 추가 */}
          <div />
        </>
      )}

      <div
        className={`mypage-profile-form-item ${
          messageInfo?.password ? 'error' : ''
        }`}
      >
        <label htmlFor="password">기존 비밀번호</label>
        <input
          id="password"
          name="password"
          type="password"
          placeholder="기존 비밀번호를 입력해주세요."
          value={passwordInfo?.password}
          onChange={handleChange}
        />
      </div>
      <div className="mypage-profile-form-item"></div>

      <div
        className={`mypage-profile-form-item ${
          messageInfo?.newPassword ? 'error' : ''
        }`}
      >
        <label htmlFor="newPassword">비밀번호</label>
        <input
          id="newPassword"
          name="newPassword"
          type="password"
          placeholder="새로운 비밀번호를 입력해주세요."
          value={passwordInfo?.newPassword}
          onChange={handleChange}
        />

        <br />
        <small>
          * 8~32자의 영문 대소문자, 숫자, 특수문자를 조합하여 설정해주세요.
        </small>

        <br />
        <small>
          * 안전한 계정 사용을 위해 비밀번호는 주기적으로 변경해주세요.
        </small>
      </div>

      <div
        className={`mypage-profile-form-item ${
          messageInfo?.passwordConfirm ? 'error' : ''
        }`}
      >
        <label htmlFor="passwordConfirm">비밀번호 확인</label>
        <input
          id="passwordConfirm"
          name="passwordConfirm"
          type="password"
          placeholder="새로운 비밀번호를 한 번 더 입력해주세요."
          value={passwordInfo?.passwordConfirm}
          onChange={handleChange}
        />
      </div>

      <div className="mypage-profile-btn-wrap">
        <button className="btn-cancel" onClick={savePassword}>
          변경하기
        </button>
      </div>
    </div>
  );
};

export default MypagePassword;
