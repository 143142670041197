import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AgreementModal from './AgreementModal';
import { showAlert } from '../../../redux/actions/globalActions';

const Agreement = ({ goNext, setAgree }) => {
  const terms = [
    {
      title: '서비스 이용 약관',
      type: 'terms',
      isEssential: true,
      isAgree: false,
      isEvent: false,
    },
    {
      title: '개인정보 수집 및 이용 동의',
      type: 'privacy',
      isEssential: true,
      isAgree: false,
      isEvent: false,
    },
    // 이벤트 및 마케팅 활용 동의 항목 제거
    // {
    //   title: '이벤트 및 마케팅 활용 동의',
    //   content: '사내 정책으로 정의한 내용',
    //   isEssential: false,
    //   isAgree: false,
    //   isEvent: true,
    // },
  ];

  const dispatch = useDispatch();

  const [agreements, setAgreements] = useState(terms.map(() => false));
  const [agreementDetail, setAgreementDetail] = useState(null);
  const [isAgreeEssentials, setIsAgreeEssentials] = useState(false);

  const checkAgreement = (e, index) => {
    const newAgreements = [...agreements];
    newAgreements[index] = e.target.checked;

    const isAgreeEssentials = newAgreements.every((agreement, index) => {
      const targetTerm = terms[index];
      const isEssential = targetTerm?.isEssential;
      return !isEssential || agreement;
    });

    setAgreements(newAgreements);
    setIsAgreeEssentials(isAgreeEssentials);

    // 이벤트 동의 체크
    checkEventAgree();
  };

  const checkAll = (e) => {
    const isAllChecked = e.target.checked;
    const newAgreements = new Array(terms.length).fill(isAllChecked);
    setAgreements(newAgreements);
    setIsAgreeEssentials(isAllChecked);
    checkEventAgree();
  };

  const checkEventAgree = () => {
    const eventIndex = terms.findIndex((term) => term.isEvent);

    if (eventIndex > -1) {
      const isEventAgreed = agreements[eventIndex];
      setAgree(isEventAgreed);
    }
  };

  const showDetail = (index) => {
    setAgreementDetail(terms[index]);
  };

  const checkNext = () => {
    if (isAgreeEssentials) {
      goNext();
    } else {
      dispatch(showAlert({ message: '필수 항목에 동의해주세요.' }));
    }
  };

  return (
    <div className="agreement">
      {agreementDetail ? (
        <AgreementModal
          agreementDetail={agreementDetail}
          setAgreementDetail={setAgreementDetail}
        />
      ) : (
        ''
      )}

      <div className="agreement-body">
        <ul className="agreement-list">
          <li className="agreement-item agreement-all">
            <div className="agreement-check-wrap">
              <div className="agreement-checkbox">
                <input
                  type="checkbox"
                  id="agreementAll"
                  checked={agreements.every((agreement) => agreement)}
                  onChange={checkAll}
                />

                <label htmlFor="agreementAll" />
              </div>

              <label htmlFor="agreementAll">이용약관 전체 동의하기</label>
            </div>
          </li>

          {terms.map((term, index) => (
            <li className="agreement-item" key={index}>
              <div className="agreement-check-wrap">
                <div className="agreement-checkbox">
                  <input
                    type="checkbox"
                    id={`agreementItem${index}`}
                    checked={agreements[index]}
                    onChange={(e) => checkAgreement(e, index)}
                  />

                  <label htmlFor={`agreementItem${index}`} />
                </div>

                <label htmlFor={`agreementItem${index}`}>
                  {term.title} {term.isEssential && <small>(필수)</small>}
                </label>
              </div>

              <div
                className="agreement-view-wrap"
                onClick={() => showDetail(index)}
              >
                자세히보기 &gt;
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="agreement-footer">
        <button className="btn-confirm" onClick={checkNext}>
          다음
        </button>
      </div>
    </div>
  );
};

export default Agreement;
