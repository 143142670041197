import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IoMdConstruct } from 'react-icons/io';
import {
  forceClosePopup,
  resetPopupCheck,
} from '../../redux/actions/popupActions';

const Maintenance = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(forceClosePopup());
    return () => {
      dispatch(resetPopupCheck());
    };
  }, []);

  return (
    <div className="maintenance">
      <IoMdConstruct size="150" fill="#082a75" />

      <div className="maintenance-message">
        서비스 점검중입니다. <br />
        점검시간 동안 일부 서비스 이용이 제한되는 점 양해 부탁드립니다.
        <br />
        <br />
        점검시간 : 2월 6일 토요일 오전 10시~12시
        <br />
        <small>
          * 서비스 재개 시간은 시스템 점검 상황에 따라 변경될 수 있습니다.
        </small>
      </div>
    </div>
  );
};

export default Maintenance;
