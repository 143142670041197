import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RiErrorWarningFill } from 'react-icons/ri';
import {
  forceClosePopup,
  resetPopupCheck,
} from '../../redux/actions/popupActions';

const NotFound = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(forceClosePopup());
    return () => {
      dispatch(resetPopupCheck());
    };
  }, []);

  return (
    <div className="not-found">
      <RiErrorWarningFill size="150" fill="#082a75" />

      <div className="not-found-message">
        페이지를 찾을 수 없습니다.
        <br />
        주소를 다시 확인해주세요.
      </div>
    </div>
  );
};

export default NotFound;
