import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Indicator from '../../layouts/Indicator';
import Footer from '../../layouts/Footer';
import Loading from '../../common/Loading';
import Message from '../../common/Message';
import parse from 'html-react-parser';
import utils from '../../../modules/utils';

const CustomerNoticeDetail = ({ match, history }) => {
  const { isAuthenticated } = useSelector((state) => state.user);
  const { notices, error, isLoading } = useSelector(
    (state) => state.noticeList
  );

  const [notice, setNotice] = useState({});
  const [pageIndex, _] = useState(
    !isNaN(parseInt(match.params.pageIndex))
      ? parseInt(match.params.pageIndex)
      : 0
  );

  useEffect(() => {
    if (isAuthenticated) {
      console.log('CustomerNoticeDetail - isAuthenticated', isAuthenticated);
    } else {
      history.push(
        `/login?redirect=/customer/notice/${match.params.seq}/${pageIndex}`
      );
    }
  }, []);

  useEffect(() => {
    setData();
  }, [history]);

  const setData = () => {
    const seq = parseInt(match.params.seq);
    const targetNotice = notices?.find((notice) => notice.seq === seq);
    setNotice(targetNotice);
  };

  return (
    <div className="customer">
      <div className="customer-wrap">
        <Indicator />

        {isLoading && <Loading />}
        {error && <Message variant="danger">{error}</Message>}

        <div className="customer-notice-detail">
          <div className="customer-notice-detail-header">
            <div className="customer-notice-detail-title">공지사항</div>
          </div>

          <div className="customer-notice-detail-body">
            <div className="customer-notice-detail-table">
              <div className="customer-notice-detail-table-tr">
                <div className="customer-notice-detail-table-title">
                  {notice?.title}
                </div>

                <div className="customer-notice-detail-table-date">
                  {utils.formatDate(notice?.crtDate)}
                </div>
              </div>

              <div className="customer-notice-detail-table-tr">
                <div className="customer-notice-detail-table-content">
                  {parse(notice?.html || '')}
                </div>
              </div>
            </div>
          </div>

          <div className="customer-notice-detail-footer">
            <div className="customer-notice-detail-btn-wrap">
              <div
                className="btn-confirm"
                onClick={() => history.push(`/customer/notice/${pageIndex}`)}
              >
                목록
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default CustomerNoticeDetail;
