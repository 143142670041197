import React, { useEffect, useRef } from 'react';
import { Alert } from 'react-bootstrap';

const Message = ({ variant, children }) => {
  const messageRef = useRef(null);

  useEffect(() => {
    // 같은 메시지 재노출 시, 애니메이션을 다시 재생 하기 위해, 애니메이션 후 클래스 이름 제거
    setTimeout(() => {
      messageRef?.current?.classList?.remove('animate__heartBeat');
    }, 500);
  });

  return (
    <Alert
      className="modal-message animate__animated animate__heartBeat"
      variant={variant}
      ref={messageRef}
    >
      {children}
    </Alert>
  );
};

Message.defaultProps = {
  variant: 'info',
};

export default Message;
