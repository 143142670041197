import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdNavigateNext } from 'react-icons/md';
import Footer from '../../../components/layouts/Footer';
import homeImage1 from '../../../styles/images/home/home-img01.svg';
import homeImage2 from '../../../styles/images/home/home-img02.svg';
import { getNoticeList } from '../../../redux/actions/customerActions';
import { BOARD_SIZE_INFO } from '../../../constants/dataConstants';

const Home = ({ history }) => {
  const boardType = 'home';
  const pageSize = BOARD_SIZE_INFO[boardType];

  const dispatch = useDispatch();
  const { notices } = useSelector((state) => state.noticeList);

  useEffect(() => {
    dispatch(getNoticeList({ pageIndex: 0, pageSize: pageSize }));
  }, [history]);

  return (
    <div className="home">
      <div className="home-page1">
        <div className="home-page1-main">
          <div className="home-page1-title">EXsys Value</div>

          <div className="home-page1-text">
            <strong>특허평가등급</strong>은<br />
            변리사의 집단 정성평가로
          </div>

          <div className="home-page1-btn-wrap">
            <button
              className="btn-confirm btn-arrow"
              onClick={() => history.push('/introduction/assessment')}
            >
              서비스 이용 방법
              <MdNavigateNext className="btn-icon btn-after-icon"></MdNavigateNext>
            </button>

            <button
              className="btn-cancel btn-arrow"
              onClick={() => history.push('/assessment/first')}
            >
              특허평가 바로가기
              <MdNavigateNext className="btn-icon btn-after-icon"></MdNavigateNext>
            </button>
          </div>
        </div>
      </div>

      <div className="home-page2">
        <div className="home-page2-main">
          <div className="home-page2-title">엑시스 밸류(EXsys Value)란</div>

          <div className="home-page2-text">
            전문성이 검증된 변리사가 평가자로 직접 참여하여 특허 권리의
            <br /> 유효성, 광협, 보호강도, 기술동향 부합도 등에 정성평가가
            이루어지는
            <br /> <strong>온라인 특허등급평가 시스템입니다.</strong>
          </div>

          <div className="home-page2-btn-wrap">
            <button
              className="btn-confirm btn-arrow"
              onClick={() => history.push('/introduction/assessment')}
            >
              서비스 이용 방법
              <MdNavigateNext className="btn-icon btn-after-icon"></MdNavigateNext>
            </button>
          </div>
        </div>

        <div className="home-page2-sub">
          <div className="home-page2-sub-image-wrap">
            <img src={homeImage1} alt="홈 이미지1" />
          </div>

          <div className="home-page2-sub-text-wrap">
            <div className="home-page2-sub-title">01. EXsys Value</div>

            <div className="home-page2-sub-text">
              전문 변리사에 의한
              <br />
              신뢰성 있는 <strong className="underline">평가결과 제공</strong>
            </div>

            <div className="home-page2-sub-btn-wrap">
              <button
                className="btn-inactive btn-arrow"
                onClick={() => history.push('/introduction/assessment')}
              >
                자세히 보기
                <MdNavigateNext className="btn-icon btn-after-icon"></MdNavigateNext>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="home-page3">
        <div className="home-page3-main">
          <div className="home-page3-text-wrap">
            <div className="home-page3-title">02. EXsys Value</div>

            <div className="home-page3-text">
              전문가 집단 정성평가에 관한
              <br />
              <strong className="underline">델파이 기법</strong>(Delphi
              technique)
              <br />
              적용
            </div>

            <div className="home-page3-btn-wrap">
              <button
                className="btn-inactive btn-grey btn-arrow"
                onClick={() => history.push('/introduction/assessment')}
              >
                자세히 보기
                <MdNavigateNext className="btn-icon btn-after-icon"></MdNavigateNext>
              </button>
            </div>
          </div>

          <div className="home-page3-image-wrap">
            <img src={homeImage2} alt="홈 이미지2" />
          </div>
        </div>
      </div>

      <div className="home-page4">
        <div className="home-page4-main">
          <div className="home-page4-text-wrap">
            <div className="home-page4-text">
              대한변리사회의
              <br />
              <strong>새로운 소식</strong>
              입니다
            </div>
          </div>

          <div className="home-page4-post-wrap">
            <div className="home-page4-post-list">
              {notices.map((notice, index) => (
                <div
                  className="home-post-item"
                  onClick={() =>
                    history.push(`/customer/notice/${notice?.seq}`)
                  }
                  key={index}
                >
                  <div className="home-post-header">
                    <div className="home-post-title">
                      {notice?.crtDate.substr(8, 2)}
                    </div>
                    <div className="home-post-subtitle">
                      {notice?.crtDate.substr(2, 2)}.
                      {notice?.crtDate.substr(5, 2)}
                    </div>
                  </div>

                  <div className="home-post-content">{notice?.title}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="home-page4-btn-wrap">
            <button
              className="btn-confirm btn-arrow"
              onClick={() => history.push('/customer/notice')}
            >
              소식 더 보기
              <MdNavigateNext className="btn-icon btn-after-icon"></MdNavigateNext>
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
