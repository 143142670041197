import React, { Fragment } from 'react';

const SignUpIndicator = ({ menuIndex }) => {
  const signUpMenus = ['약관동의', '정보입력', '가입완료'];

  return (
    <div className="sign-up-indicator">
      {signUpMenus.map((menu, index) => (
        <Fragment key={index}>
          <div
            className={`sign-up-indicator-item ${
              index === menuIndex ? 'active' : ''
            } ${index < menuIndex ? 'complete' : ''}`}
          >
            <div className="sign-up-indicator-item-icon" />

            <div className="sign-up-indicator-item-text">{menu}</div>
          </div>

          {signUpMenus.length - 1 !== index && (
            <div
              className={`sign-up-indicator-arrow ${
                index === menuIndex ? 'active' : ''
              } ${index < menuIndex ? 'complete' : ''}`}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default SignUpIndicator;
