import {
  POPUP_TODAY_REQUEST,
  POPUP_TODAY_SUCCESS,
  POPUP_TODAY_FAILURE,
  POPUP_TODAY_RESET,
  POPUP_MESSAGE_REQUEST,
  POPUP_MESSAGE_SUCCESS,
  POPUP_MESSAGE_FAILURE,
  POPUP_MESSAGE_RESET,
  POPUP_MESSAGE_CHECKED_REQUEST,
  POPUP_MESSAGE_CHECKED_SUCCESS,
  POPUP_MESSAGE_CHECKED_FAILURE,
  POPUP_MESSAGE_CHECKED_RESET,
  POPUP_CHECKED,
  POPUP_CHECKED_CLOSE,
  POPUP_CHECKED_RESET,
  POPUP_CLEAR_ERROR,
} from '../types/popupTypes';

export const popupTodayReducer = (state = { popupTodays: null }, action) => {
  switch (action.type) {
    case POPUP_TODAY_REQUEST:
      return { ...state, error: '', isLoading: true };

    case POPUP_TODAY_SUCCESS:
      return {
        ...state,
        popupTodays: action.payload.popupTodays,
        error: '',
        isLoading: false,
      };

    case POPUP_TODAY_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    case POPUP_TODAY_RESET:
      return {
        ...state,
        popupTodays: null,
        error: '',
        isLoading: false,
      };

    case POPUP_CLEAR_ERROR:
      return { ...state, error: '' };

    default:
      return { ...state };
  }
};

export const popupMessageReducer = (
  state = {
    popupMessageInfo: null,
    error: '',
    isLoading: false,
    isSuccess: false,
  },
  action
) => {
  switch (action.type) {
    case POPUP_MESSAGE_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true,
        isSuccess: false,
      };

    case POPUP_MESSAGE_SUCCESS:
      return {
        ...state,
        popupMessageInfo: action.payload.popupMessageInfo,
        isLoading: false,
        isSuccess: true,
      };

    case POPUP_MESSAGE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSuccess: false,
      };

    case POPUP_MESSAGE_RESET:
      return {
        ...state,
        error: '',
        popupMessageInfo: null,
        isLoading: false,
        isSuccess: false,
      };

    default:
      return { ...state };
  }
};

export const popupMessageCheckedReducer = (
  state = { error: '', isLoading: false, isSuccess: false, isChecked: false },
  action
) => {
  switch (action.type) {
    case POPUP_MESSAGE_CHECKED_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true,
        isSuccess: false,
      };

    case POPUP_MESSAGE_CHECKED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isChecked: true,
      };

    case POPUP_MESSAGE_CHECKED_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSuccess: false,
      };

    case POPUP_MESSAGE_CHECKED_RESET:
      return {
        ...state,
        error: '',
        isLoading: false,
        isSuccess: false,
        isChecked: false,
      };

    default:
      return { ...state };
  }
};

export const popupCheckReducer = (
  state = { isChecked: false, isForceClose: false },
  action
) => {
  switch (action.type) {
    case POPUP_CHECKED:
      return {
        ...state,
        isChecked: true,
      };

    case POPUP_CHECKED_CLOSE:
      return {
        ...state,
        isForceClose: true,
      };

    case POPUP_CHECKED_RESET:
      return {
        ...state,
        isForceClose: false,
        isChecked: false,
      };

    default:
      return { ...state };
  }
};
