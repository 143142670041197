import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Indicator from '../../layouts/Indicator';
import Footer from '../../layouts/Footer';
import Loading from '../../common/Loading';
import Message from '../../common/Message';
import Pagination from '../../common/Pagination';
import AssessmentAgree from '../assessment/AssessmentAgree';
import AssessmentSurvey from '../assessment/AssessmentSurvey';
import {
  getAssessmentHistory,
  resetSurvey,
} from '../../../redux/actions/assessmentActions';
import { getPopupMessage } from '../../../redux/actions/popupActions';
import { showAlert, showToast } from '../../../redux/actions/globalActions';
import { BOARD_SIZE_INFO } from '../../../constants/dataConstants';
import moment from 'moment';
import utils from '../../../modules/utils';

const MypageAssessment = ({ history }) => {
  const boardType = 'assessment';
  const pageSize = BOARD_SIZE_INFO[boardType];

  const dispatch = useDispatch();
  const { codeInfo } = useSelector((state) => state.code);
  const { userInfo, isAuthenticated } = useSelector((state) => state.user);
  const { patents, totalCount, countInfo, rankInfo, error, isLoading } =
    useSelector((state) => state.assessmentHistory);

  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, 'M').toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  const [params, setParams] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [selectedPatent, setSelectedPatent] = useState({});
  const [isAgreeModal, setIsAgreeModal] = useState(false);
  const [isSurveyModal, setIsSurveyModal] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      console.log('MypageAssessment - isAuthenticated', isAuthenticated);

      const { role } = userInfo;
      if (role === 'ROLE_EMPTY') {
        dispatch(showAlert({ message: '정회원만 이용 가능한 서비스 입니다.' }));
        history.push('/');
      }
    } else {
      history.push('/login?redirect=/mypage/assessment');
    }
  }, []);

  useEffect(() => {
    checkAssessmentHistory();
  }, []);

  const checkAssessmentHistory = () => {
    if (!patents) setAssessmentHistory();
  };

  const setAssessmentHistory = (searchParams = params) => {
    console.log('setAssessmentHistory() - searchParams', searchParams);
    dispatch(getAssessmentHistory({ pageSize, pageIndex }, searchParams));
  };

  const checkDateValid = () => {
    const fromDateValue = new Date(fromDate).getTime();
    const toDateValue = new Date(toDate).getTime();

    if (
      utils.isEmptyString(fromDate) ||
      utils.isEmptyString(toDate) ||
      fromDateValue <= toDateValue
    ) {
      return true;
    } else {
      return false;
    }
  };

  const findAssessmentHistory = () => {
    const isDateValid = checkDateValid();

    if (isDateValid) {
      const newParams = {};
      if (!utils.isEmptyString(fromDate)) newParams['fromDate'] = fromDate;
      if (!utils.isEmptyString(toDate)) newParams['toDate'] = toDate;
      setParams(newParams);
      setAssessmentHistory(newParams);
    } else {
      dispatch(
        showToast({
          message: '날짜 값을 확인해주세요.',
        })
      );
    }
  };

  const showAssessmentAgree = (patent) => {
    setSelectedPatent(patent);
    setIsAgreeModal(true);
  };

  const showAssessmentSurvey = () => {
    setIsSurveyModal(true);
  };

  const startAssessment = () => {
    dispatch(resetSurvey());
    showAssessmentSurvey();
  };

  const endAssessment = () => {
    dispatch(getPopupMessage());
    setAssessmentHistory();
  };

  return (
    <div className="mypage">
      <div className="mypage-wrap">
        <Indicator />

        {isLoading && <Loading />}
        {error && <Message variant="danger">{error}</Message>}
        {isAgreeModal && (
          <AssessmentAgree
            assessmentStep={'second'}
            documentId={selectedPatent?.documentId}
            groupId={selectedPatent?.groupId}
            startAssessment={startAssessment}
            setIsAgreeModal={setIsAgreeModal}
          />
        )}
        {isSurveyModal && (
          <AssessmentSurvey
            assessmentStep={'second'}
            documentId={selectedPatent?.documentId}
            groupId={selectedPatent?.groupId}
            endAssessment={endAssessment}
            setIsSurveyModal={setIsSurveyModal}
          />
        )}

        <div className="mypage-assessment">
          <div className="mypage-assessment-header">
            <div className="mypage-assessment-jumbotron">
              <div className="mypage-assessment-jumbotron-left">
                <div className="mypage-assessment-info">
                  {userInfo?.name}님의 평가 위치는{' '}
                  <strong>{rankInfo?.userAvg}%</strong> 입니다.
                </div>

                <div className="mypage-assessment-desc">
                  동일한 기술분야의 평가자 평균 대비 나의 평가 위치입니다.
                </div>

                <div className="mypage-assessment-bar-wrap">
                  <div className="mypage-assessment-bar-percent">
                    <div className="mypage-assessment-bar-percent-start">0</div>
                    <div
                      className="mypage-assessment-bar-percent-average"
                      style={{ left: `${rankInfo?.totalAvg}%` }}
                    >
                      평가자 평균
                      <div className="mypage-assessment-bar-percent-average-arrow" />
                    </div>

                    <div
                      className="mypage-assessment-bar-percent-ball"
                      style={{ left: `${rankInfo?.userAvg}%` }}
                    >
                      {rankInfo?.userAvg}%
                    </div>
                    <div className="mypage-assessment-bar-percent-end">100</div>
                  </div>

                  <div className="mypage-assessment-bar" />
                </div>
              </div>

              <div className="mypage-assessment-jumbotron-right">
                <div className="mypage-assessment-count-item">
                  <div className="mypage-assessment-count-title">
                    누적 평가 건수
                  </div>
                  <div className="mypage-assessment-count-title">
                    <strong>{countInfo?.assessmentTotalCount}</strong>
                  </div>
                </div>

                <div className="mypage-assessment-count-item">
                  <div className="mypage-assessment-count-title">
                    2차 평가 건수
                  </div>
                  <div className="mypage-assessment-count-title">
                    <strong>{countInfo?.secondCompleteCount}</strong>/
                    {countInfo?.secondTotalCount}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mypage-assessment-body">
            <div className="mypage-assessment-post-header">
              <div className="mypage-assessment-count">
                Total: <strong>{totalCount}</strong>
              </div>

              <div className="mypage-assessment-search">
                <div className="mypage-assessment-search-title">평가 기간</div>
                <div className="mypage-assessment-search-period">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    onKeyDown={(e) => e.preventDefault()}
                  />

                  <div className="mypage-assessment-search-period-split">~</div>

                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </div>

                <button
                  className="btn-confirm mypage-assessment-search-btn"
                  onClick={findAssessmentHistory}
                >
                  검색
                </button>
              </div>
            </div>

            <table className="mypage-assessment-post-wrap">
              <colgroup>
                <col style={{ width: '2%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '24%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '13%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '13%' }} />
                <col style={{ width: '12%' }} />
              </colgroup>

              <thead>
                <tr>
                  <td>No</td>
                  <td>기술분야</td>
                  <td>발명의 명칭</td>
                  <td>1차 평가 일시</td>
                  <td>1차 평가 점수 (델파이계수)</td>
                  <td>2차 평가 일시</td>
                  <td>2차 평가 점수</td>
                  <td>PA 평가 등급</td>
                </tr>
              </thead>

              <tbody>
                {patents?.map((patent, index) => (
                  <tr className="mypage-assessment-post-item" key={index}>
                    <td>{index + 1 + pageSize * pageIndex}</td>
                    <td>
                      {`${codeInfo[patent?.code]?.field_kr}/${
                        codeInfo[patent?.code]?.sector_kr
                      }`}
                    </td>
                    <td className="mypage-assessment-post-title">
                      {patent?.inventionTitle}
                    </td>
                    <td>{patent?.crtDate}</td>
                    <td>
                      {patent?.percentile}
                      <br />({patent?.delphai})
                    </td>
                    <td>
                      {patent?.second >= 1 ? (
                        patent?.secondCrtDate
                      ) : patent?.isRequest ? (
                        <button
                          className="btn-cancel"
                          onClick={() => showAssessmentAgree(patent)}
                        >
                          평가하기
                        </button>
                      ) : (
                        '대상아님'
                      )}
                    </td>
                    <td>{patent?.secondPercentile}</td>
                    <td>{patent?.rankDesc}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mypage-assessment-footer">
            <Pagination
              totalCount={totalCount}
              pageSize={pageSize}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              renewPage={setAssessmentHistory}
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MypageAssessment;
