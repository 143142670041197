import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPopupTodays,
  getPopupMessage,
  checkPopup,
  resetPopupMessage,
  resetPopupMessageChecked,
  resetPopupCheck,
} from '../../redux/actions/popupActions';
import PopupToday from './PopupToday';
import PopupUserRole from './PopupUserRole';
import PopupSecondAssessment from '../popups/PopupSecondAssessment';

const PopupManager = () => {
  const dispatch = useDispatch();

  const { isLogin } = useSelector((state) => state.user);
  const { popupTodays } = useSelector((state) => state.popupToday);
  const { popupMessageInfo } = useSelector((state) => state.popupMessage);
  const { isChecked, isForceClose } = useSelector((state) => state.popupCheck);

  const [isShowPopupTodays, setIsShowPopupTodays] = useState([]);
  const [isShowPopupUserRole, setIsShowPopupUserRole] = useState(false);
  const [isShowPopupSecondAssessment, setIsShowPopupSecondAssessment] =
    useState(false);

  useEffect(() => {
    dispatch(getPopupTodays());

    if (isLogin) {
      dispatch(getPopupMessage());
    } else {
      dispatch(resetPopupMessage());
      dispatch(resetPopupMessageChecked());
      dispatch(resetPopupCheck());
    }
  }, [isLogin]);

  useEffect(() => {
    if (!isChecked) checkMessagePopup();
  }, [popupMessageInfo]);

  useEffect(() => {
    if (Array.isArray(popupTodays))
      setIsShowPopupTodays(popupTodays.map((_) => true));
  }, [popupTodays]);

  const checkMessagePopup = () => {
    const userRoleMessageInfo = popupMessageInfo?.userRoleMessageInfo;
    const secondCount = popupMessageInfo?.secondCount || 0;

    if (userRoleMessageInfo) {
      setIsShowPopupUserRole(true);
      dispatch(checkPopup());
    }
    if (secondCount > 0) {
      setIsShowPopupSecondAssessment(true);
      dispatch(checkPopup());
    }
  };

  const hidePopupToday = (index) => {
    const newIsShowPopupToday = [...isShowPopupTodays];
    newIsShowPopupToday[index] = false;
    setIsShowPopupTodays(newIsShowPopupToday);
  };

  return (
    !isForceClose && (
      <div className="popup-manager">
        {isShowPopupTodays.map(
          (isShowPopupToday, index) =>
            isShowPopupToday && (
              <PopupToday
                messageInfo={popupTodays[index]}
                hidePopup={() => hidePopupToday(index)}
                key={index}
              />
            )
        )}

        {isShowPopupUserRole && (
          <PopupUserRole
            messageInfo={popupMessageInfo?.userRoleMessageInfo}
            hidePopup={() => setIsShowPopupUserRole(false)}
          />
        )}
        {isShowPopupSecondAssessment && (
          <PopupSecondAssessment
            hidePopup={() => setIsShowPopupSecondAssessment(false)}
          />
        )}
      </div>
    )
  );
};

export default PopupManager;
