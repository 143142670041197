export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';
export const FILE_UPLOAD_RESET = 'FILE_UPLOAD_RESET';

export const FILE_DELETE_REQUEST = 'FILE_DELETE_REQUEST';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';
export const FILE_DELETE_FAILURE = 'FILE_DELETE_FAILURE';
export const FILE_DELETE_RESET = 'FILE_DELETE_RESET';

export const FILE_CLEAR_ERROR = 'FILE_CLEAR_ERROR';
