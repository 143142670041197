import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

const AgreementModal = ({ agreementDetail, setAgreementDetail }) => {
  const { terms, privacy } = useSelector((state) => state.termsAndPrivacy);

  const [agreementContent, setAgreementContent] = useState('');

  useEffect(() => {
    const type = agreementDetail?.type;
    if (type === 'terms') setAgreementContent(terms.html);
    else if (type === 'privacy') setAgreementContent(privacy.html);
  }, []);

  return (
    <div className="modal">
      <div className="agreement-modal">
        <div className="modal-header">
          <div className="agreement-title">{agreementDetail.title}</div>
          <div
            className="modal-close-btn"
            onClick={() => setAgreementDetail(null)}
          />
        </div>

        <div className="modal-body">
          <div className="modal-body-content">
            {parse(agreementContent || '')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgreementModal;
