import {
  NOTICE_LIST_REQUEST,
  NOTICE_LIST_SUCCESS,
  NOTICE_LIST_FAILURE,
  NOTICE_LIST_RESET,
  NOTICE_DETAIL_REQUEST,
  NOTICE_DETAIL_SUCCESS,
  NOTICE_DETAIL_FAILURE,
  NOTICE_DETAIL_RESET,
  FAQ_LIST_REQUEST,
  FAQ_LIST_SUCCESS,
  FAQ_LIST_FAILURE,
  FAQ_LIST_RESET,
  INQUIRY_CREATE_REQUEST,
  INQUIRY_CREATE_SUCCESS,
  INQUIRY_CREATE_FAILURE,
  INQUIRY_CREATE_RESET,
  CUSTOMER_CLEAR_ERROR,
} from '../types/customerTypes';

export const noticeListReducer = (
  state = { notices: [], totalCount: 0 },
  action
) => {
  switch (action.type) {
    case NOTICE_LIST_REQUEST:
      return { ...state, error: '', isLoading: true };

    case NOTICE_LIST_SUCCESS:
      return {
        ...state,
        notices: action.payload.notices,
        totalCount: action.payload.totalCount,
        error: '',
        isLoading: false,
      };

    case NOTICE_LIST_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    case NOTICE_LIST_RESET:
      return {
        ...state,
        notices: [],
        totalCount: 0,
        error: '',
        isLoading: false,
      };

    case CUSTOMER_CLEAR_ERROR:
      return { ...state, error: '' };

    default:
      return { ...state };
  }
};

export const noticeDetailReducer = (state = { notice: {} }, action) => {
  switch (action.type) {
    case NOTICE_DETAIL_REQUEST:
      return { ...state, error: '', isLoading: true, isSuccess: false };

    case NOTICE_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: '',
        isLoading: false,
        isSuccess: true,
      };

    case NOTICE_DETAIL_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    case NOTICE_DETAIL_RESET:
      return {
        ...state,
        notice: {},
        error: '',
        isLoading: false,
        isSuccess: false,
      };

    case CUSTOMER_CLEAR_ERROR:
      return { ...state, error: '' };

    default:
      return { ...state };
  }
};

export const faqListReducer = (state = { faqs: [], totalCount: 0 }, action) => {
  switch (action.type) {
    case FAQ_LIST_REQUEST:
      return { ...state, error: '', isLoading: true };

    case FAQ_LIST_SUCCESS:
      return {
        ...state,
        faqs: action.payload.faqs,
        totalCount: action.payload.totalCount,
        error: '',
        isLoading: false,
      };

    case FAQ_LIST_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    case FAQ_LIST_RESET:
      return {
        ...state,
        faqs: [],
        totalCount: 0,
        error: '',
        isLoading: false,
      };

    case CUSTOMER_CLEAR_ERROR:
      return { ...state, error: '' };

    default:
      return { ...state };
  }
};

export const inquiryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case INQUIRY_CREATE_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true,
        isSuccess: false,
      };

    case INQUIRY_CREATE_SUCCESS:
      return {
        ...state,
        error: '',
        isLoading: false,
        isSuccess: true,
      };

    case INQUIRY_CREATE_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    case INQUIRY_CREATE_RESET:
      return {
        ...state,
        error: '',
        isLoading: false,
        isSuccess: false,
      };

    case CUSTOMER_CLEAR_ERROR:
      return { ...state, error: '' };

    default:
      return { ...state };
  }
};
