import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CgClose } from 'react-icons/cg';
import axios from 'axios';
import Message from '../../common/Message';
import Loading from '../../common/Loading';
import ErrorText from '../../common/ErrorText';
import { showToast } from '../../../redux/actions/globalActions';
import {
  API_FIND_PASSWORD,
  API_RESET_PASSWORD,
} from '../../../constants/apiConstants';

const FindPasswordModal = ({ foundedId, hideFindPassword }) => {
  const dispatch = useDispatch();

  const [uuid, setUuid] = useState('');
  const [pageIndex, setPageIndex] = useState(0);

  const InputPage = () => {
    const [name, setName] = useState('');
    const [id, setId] = useState(foundedId);
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [messageInfo, setMessageInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const checkValid = () => {
      let validationInfo = {};
      if (name.trim() === '') {
        validationInfo.name = '이름을 입력해주세요.';
      }
      if (id.trim() === '') {
        validationInfo.id = '아이디를 입력해주세요.';
      }
      if (mobile.trim() === '') {
        validationInfo.mobile = '핸드폰 번호를 입력해주세요.';
      }
      if (email.trim() === '') {
        validationInfo.email = '이메일 주소를 입력해주세요.';
      }

      setMessageInfo(validationInfo);
      const isValid = Object.keys(validationInfo).length === 0;
      return isValid;
    };

    const sendFindPassword = async (e) => {
      e.preventDefault();

      const isValid = checkValid();
      if (isValid) {
        try {
          setIsLoading(true);
          const query = window.encodeURIComponent(
            `name=${name}&id=${id}&email=${email}&mobile=${mobile}`
          );
          const url = `${API_FIND_PASSWORD}?${query}`;
          const method = 'POST';
          const res = await axios({ url, method });
          console.log('res', res);
          const { data, code } = res.data;
          if (data) {
            setUuid(data.uuid);
            setPageIndex(1);
          } else {
            setMessageInfo(code.msg);
          }
        } catch (err) {
          console.log('err', err);

          // const errorMessage =
          //   err.response && err.response.data.msg
          //     ? err.response.data.msg
          //     : err.message;

          // 로그인/아이디 찾기/비밀번호 찾기 실패 메시지는 서버에서 내려오는 메시지 사용하지 않고, 하드코딩으로 고정
          const errorMessage = '회원 정보를 찾을 수 없습니다.';
          const newMessageInfo = { error: errorMessage };
          setMessageInfo(newMessageInfo);
        } finally {
          setIsLoading(false);
        }
      }
    };

    const clearMessage = (key) => {
      const newMessageInfo = { ...messageInfo };
      delete newMessageInfo[key];
      setMessageInfo(newMessageInfo);
    };

    return (
      <div className="find-password">
        {isLoading && <Loading />}
        <div className="find-password-header">
          <div className="find-password-title">비밀번호 찾기</div>
          <CgClose className="find-password-close" onClick={hideFindPassword} />
        </div>

        <div className="find-password-body">
          <div className="find-password-body-title">
            가입 시 등록한 정보를 입력해주세요.
          </div>

          <div className="find-password-form-wrap">
            <ul>
              <li
                className={`find-password-form-item ${
                  messageInfo.name && 'error-box'
                }`}
              >
                <span className="find-password-form-label">이름</span>
                <input
                  className="find-password-form-input"
                  name="name"
                  type="text"
                  placeholder="이름"
                  value={name}
                  onChange={(e) => {
                    clearMessage(e.target.name);
                    setName(e.target.value);
                  }}
                />

                {messageInfo.name && (
                  <ErrorText variant="danger">{messageInfo.name}</ErrorText>
                )}
              </li>

              <li
                className={`find-password-form-item ${
                  messageInfo.id && 'error-box'
                }`}
              >
                <span className="find-password-form-label">아이디</span>
                <input
                  className="find-password-form-input"
                  name="id"
                  type="text"
                  placeholder="아이디"
                  value={id}
                  onChange={(e) => {
                    clearMessage(e.target.name);
                    setId(e.target.value);
                  }}
                />

                {messageInfo.id && (
                  <ErrorText variant="danger">{messageInfo.id}</ErrorText>
                )}
              </li>

              <li
                className={`find-password-form-item ${
                  messageInfo.mobile && 'error-box'
                }`}
              >
                <span className="find-password-form-label">휴대폰 번호</span>
                <input
                  className="find-password-form-input"
                  name="mobile"
                  type="number"
                  placeholder="핸드폰번호 (예시 : 01012345678)"
                  value={mobile}
                  onChange={(e) => {
                    clearMessage(e.target.name);
                    setMobile(e.target.value);
                  }}
                />

                {messageInfo.mobile && (
                  <ErrorText variant="danger">{messageInfo.mobile}</ErrorText>
                )}
              </li>

              <li
                className={`find-password-form-item ${
                  messageInfo.email && 'error-box'
                }`}
              >
                <span className="find-password-form-label">이메일</span>
                <input
                  className="find-password-form-input"
                  name="email"
                  type="text"
                  placeholder="이메일 (예시 : abc@company.com)"
                  value={email}
                  onChange={(e) => {
                    clearMessage(e.target.name);
                    setEmail(e.target.value);
                  }}
                />

                {messageInfo.email && (
                  <ErrorText variant="danger">{messageInfo.email}</ErrorText>
                )}
              </li>
            </ul>
          </div>

          {messageInfo.error && (
            <Message variant="danger">{messageInfo.error}</Message>
          )}
        </div>

        <div className="find-password-footer">
          <button className="btn-cancel" onClick={hideFindPassword}>
            취소
          </button>

          <button className="btn-confirm" onClick={sendFindPassword}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const ResetPage = () => {
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [messageInfo, setMessageInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const checkValid = () => {
      let validationInfo = {};

      if (!/^(?=.*[a-zA-Z0-9~!@$#^]).{8,12}$/.test(password))
        validationInfo['password'] = '비밀번호를 다시 확인해 주세요.';

      if (!/^(?=.*[a-zA-Z0-9~!@$#^]).{8,12}$/.test(passwordConfirm))
        validationInfo['passwordConfirm'] =
          '비밀번호 재입력을 다시 확인해 주세요.';

      if (password !== passwordConfirm)
        validationInfo.passwordConfirm = '비밀번호가 일치하지 않습니다.';

      setMessageInfo(validationInfo);
      const isValid = Object.keys(validationInfo).length === 0;
      return isValid;
    };

    const resetPassword = async (e) => {
      e.preventDefault();

      const isValid = checkValid();
      if (isValid) {
        try {
          setIsLoading(true);
          const url = API_RESET_PASSWORD;
          const method = 'POST';
          const data = { uuid, password };
          const res = await axios({ url, method, data });
          console.log('resetPassword - res', res);

          // 비밀번호 성공 시, 비밀번호 변경 창 종료 후, 토스트 알림
          hideFindPassword();
          dispatch(
            showToast({
              message: '비밀번호가 재설정 되었습니다.',
            })
          );
        } catch (err) {
          console.log('err', err);
          const errorMessage =
            err.response && err.response.data.msg
              ? err.response.data.msg
              : err.message;
          const newMessageInfo = { error: errorMessage };
          setMessageInfo(newMessageInfo);
        } finally {
          setIsLoading(false);
        }
      }
    };

    const clearMessage = (key) => {
      const newMessageInfo = { ...messageInfo };
      delete newMessageInfo[key];
      setMessageInfo(newMessageInfo);
    };

    return (
      <div className="find-password find-password-reset">
        {isLoading && <Loading />}
        <div className="find-password-header">
          <div className="find-password-title">
            비밀번호를 다시 설정해주세요
          </div>
        </div>

        <div className="find-password-body">
          <div className="find-password-form-wrap">
            <ul>
              <li
                className={`find-password-form-item ${
                  messageInfo.password && 'error-box'
                }`}
              >
                <span className="find-password-form-label">비밀번호</span>
                <input
                  className="find-password-form-input"
                  name="password"
                  type="password"
                  placeholder="비밀번호 (8~12자의 영문 대소문자, 숫자, 특수문자)"
                  value={password}
                  onChange={(e) => {
                    clearMessage(e.target.name);
                    setPassword(e.target.value);
                  }}
                />

                {messageInfo.password && (
                  <ErrorText variant="danger">{messageInfo.password}</ErrorText>
                )}
              </li>

              <li
                className={`find-password-form-item ${
                  messageInfo.passwordConfirm && 'error-box'
                }`}
              >
                <span className="find-password-form-label">비밀번호 확인</span>
                <input
                  className="find-password-form-input"
                  name="passwordConfirm"
                  type="password"
                  placeholder="비밀번호 재입력"
                  value={passwordConfirm}
                  onChange={(e) => {
                    clearMessage(e.target.name);
                    setPasswordConfirm(e.target.value);
                  }}
                />

                {messageInfo.passwordConfirm && (
                  <ErrorText variant="danger">
                    {messageInfo.passwordConfirm}
                  </ErrorText>
                )}
              </li>
            </ul>

            <div className="find-password-text">
              <small>
                * 8~32자의 영문 대소문자, 숫자, 특수문자를 조합하여 설정해주세요
              </small>

              <br />
              <small>
                * 안전한 계정 사용을 위해 비밀번호는 주기적으로 변경해주세요.
              </small>
            </div>
          </div>

          {messageInfo.error && (
            <Message variant="danger">{messageInfo.error}</Message>
          )}
        </div>

        <div className="find-password-footer">
          <button className="btn-cancel" onClick={hideFindPassword}>
            취소
          </button>

          <button className="btn-confirm" onClick={resetPassword}>
            확인
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="find-password-modal">
      {pageIndex === 0 && <InputPage />}
      {pageIndex === 1 && <ResetPage />}
    </div>
  );
};
export default FindPasswordModal;
