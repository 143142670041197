import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  codeReducer,
  termsAndPrivacyReducer,
  menuReducer,
  alertReducer,
  confirmReducer,
  toastReducer,
} from './reducers/globalReducers.js';
import {
  userReducer,
  userDetailReducer,
  userUpdateReducer,
  passwordUpdateReducer,
  signUpReducer,
} from './reducers/userReducers.js';
import {
  assessmentReducer,
  assessmentCountReducer,
  assessmentAgreeReducer,
  surveyReducer,
  surveySubmitReducer,
  assessmentHistoryReducer,
} from './reducers/assessmentReducers.js';
import {
  noticeListReducer,
  noticeDetailReducer,
  faqListReducer,
  inquiryCreateReducer,
} from './reducers/customerReducers';
import {
  popupTodayReducer,
  popupMessageReducer,
  popupMessageCheckedReducer,
  popupCheckReducer,
} from './reducers/popupReducers';
import { fileUploadReducer, fileDeleteReducer } from './reducers/fileReducers';

const reducer = combineReducers({
  code: codeReducer,
  termsAndPrivacy: termsAndPrivacyReducer,
  menu: menuReducer,
  alert: alertReducer,
  confirm: confirmReducer,
  toast: toastReducer,
  user: userReducer,
  userDetail: userDetailReducer,
  userUpdate: userUpdateReducer,
  passwordUpdate: passwordUpdateReducer,
  signUp: signUpReducer,
  popupToday: popupTodayReducer,
  popupMessage: popupMessageReducer,
  popupMessageChecked: popupMessageCheckedReducer,
  popupCheck: popupCheckReducer,
  assessment: assessmentReducer,
  assessmentCount: assessmentCountReducer,
  assessmentAgree: assessmentAgreeReducer,
  survey: surveyReducer,
  surveySubmit: surveySubmitReducer,
  assessmentHistory: assessmentHistoryReducer,
  noticeList: noticeListReducer,
  noticeDetail: noticeDetailReducer,
  faqList: faqListReducer,
  inquiryCreate: inquiryCreateReducer,
  fileUpload: fileUploadReducer,
  fileDelete: fileDeleteReducer,
});

const initialState = {};

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
