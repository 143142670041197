import React from 'react';
import parse from 'html-react-parser';
import Indicator from '../../layouts/Indicator';
import Footer from '../../layouts/Footer';
import introductionImage2 from '../../../styles/images/introduction/introduction-img02.svg';
import introductionImage3 from '../../../styles/images/introduction/introduction-img03.svg';
import introductionItem4 from '../../../styles/images/introduction/introduction-item04.svg';
import introductionItem5 from '../../../styles/images/introduction/introduction-item05.svg';
import introductionItem6 from '../../../styles/images/introduction/introduction-item06.svg';
import introductionItem7 from '../../../styles/images/introduction/introduction-item07.svg';

const IntroductionAssessment = () => {
  const introductions = [
    {
      icon: introductionItem4,
      title: '권리의 유효성',
      text: `
        출원 경과, 권리의 무효 가능성,
        <br />
        (출원 건: 등록 가능성)
        <br />
        권리의 제한에 대한 평가
      `,
    },
    {
      icon: introductionItem5,
      title: '권리의 광협',
      text: `
        권리의 구성요소 및 한정요소, <br />
        권리 구성의 망라성에 대한 평가
      `,
    },
    {
      icon: introductionItem6,
      title: '권리의 보호강도',
      text: `
        침해발견 및 입증 용이성,<br />
        타인의 회피 가능성,<br />
        권리의 국내외 망라성에 대한 평가
      `,
    },
    {
      icon: introductionItem7,
      title: '기술 부합도',
      text: `
        특허와 기술동향의 부합정도,
        <br />
        제품과의 연계성에 대한 평가
      `,
    },
  ];

  const introductionTexts = [
    {
      title: '객관성',
      text: '평가자 간 상호작용을 배제함으로써 편승효과 또는 후광효과 배제',
    },
    {
      title: '신속·정확',
      text: '전문 변리사가 시스템을 이용하여 직접 평가함으로써 신속하면서도 정확한 정성평가 결과 도출',
    },
    {
      title: '좁은 편차 폭',
      text: '복수의 전문가가 평가하여 평가 누적에 의해 평가결과가 수렴되어 정확성 및 신뢰도 확보',
    },
  ];

  return (
    <div className="introduction introduction-service">
      <Indicator />

      <div className="introduction-page1">
        <div className="introduction-page-main">
          <div className="introduction-page-left">
            <div className="introduction-title">
              <strong className="underline">평가지표</strong>
            </div>

            <div className="introduction-list">
              {introductions.map((introduction, index) => (
                <div className="introduction-item" key={index}>
                  <img
                    className="introduction-item-icon"
                    src={introduction.icon}
                    alt="Introduction Item Icon"
                  ></img>

                  <div className="introduction-item-text-wrap">
                    <div className="introduction-item-title">
                      {index + 1}. {introduction.title}
                    </div>

                    <div className="introduction-item-text">
                      {parse(introduction.text)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="introduction-page-right">
            <img
              className="introduction-image"
              src={introductionImage2}
              alt="Introduction Image2"
            />
          </div>
        </div>
      </div>

      <div className="introduction-page2">
        <div className="introduction-page-main">
          <div className="introduction-page-left">
            <img
              className="introduction-image"
              src={introductionImage3}
              alt="Introduction Image3"
            />
          </div>

          <div className="introduction-page-right">
            <div className="introduction-title">
              <strong className="underline">평가요소</strong>
            </div>

            <div className="introduction-list">
              {introductionTexts.map((introductionText, index) => (
                <div className="introduction-item" key={index}>
                  <div className="introduction-item-title">
                    {index + 1}. {introductionText.title}
                  </div>
                  <div className="introduction-item-text">
                    {introductionText.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default IntroductionAssessment;
