import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/layouts/Header';
import Main from './components/layouts/Main';
import Loading from './components/common/Loading';
import Alert from './components/common/Alert';
import Confirm from './components/common/Confirm';
import Toast from './components/common/Toast';
import PopupManager from './components/popups/PopupManager';
import VersionInfo from './components/dev/VersionInfo';
import { checkLogin } from './redux/actions/userActions';
import { setCodeInfo, setTermsAndPrivacy } from './redux/actions/globalActions';

function App() {
  const dispatch = useDispatch();
  const [isLoginChecked, setIsLoginChecked] = useState(false);
  const [isDevelopment, setIsDevelopment] = useState(false);

  useEffect(async () => {
    setIsDevelopment(
      process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_DEPLOY_ENV === 'development'
    );

    await dispatch(setCodeInfo());
    await dispatch(setTermsAndPrivacy());
    await dispatch(checkLogin());
    setIsLoginChecked(true);
  }, []);

  return (
    <Router>
      {isLoginChecked ? (
        <>
          <Header></Header>
          <Main></Main>

          {/* 디자인이 변경됨으로 인해, 스타일 문제로 Footer를 각 페이지 컴포넌트 안으로 추가 */}
          {/* <Footer></Footer>  */}
        </>
      ) : (
        <Loading />
      )}

      <Alert />
      <Confirm />
      <Toast />
      <PopupManager />

      {isDevelopment && <VersionInfo />}
    </Router>
  );
}

export default App;
