import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const SignUpComplete = () => {
  const { newUser, name, roleName, crtDate } = useSelector(
    (state) => state.signUp
  );

  return (
    <div className="sign-up-complete">
      <div className="sign-up-complete-header">
        <div className="sign-up-complete-left">
          <div className="sign-up-complete-title">
            특허평가시스템
            <br />
            <strong>가입을 환영합니다.</strong>
          </div>

          <div className="sign-up-complete-text">
            특허평가시스템에 로그인하시면
            <br />
            특허문헌평가 서비스를 이용하실 수 있습니다.
            <br />
            <br />
            변리사 번호를 입력하신 경우
            <br />
            관리자 확인 후 정회원으로 승인됩니다.
          </div>
        </div>

        <div className="sign-up-complete-right">
          <div className="sign-up-complete-img" />
        </div>
      </div>

      <div className="sign-up-complete-body">
        <div className="sign-up-complete-jumbotron">
          <div className="sign-up-complete-info">
            <strong>{name}</strong> {roleName}님
            <span className="splitter">|</span>
            가입일시 {crtDate}
          </div>

          <div className="sign-up-complete-text">
            서비스 이용은 <strong>메일 인증 후 사용가능합니다.</strong>
            <br />
            메일함에서({newUser?.email}) 인증 메일을 확인 바랍니다.
          </div>

          <div className="sign-up-complete-btn">
            <Link to="/login">
              <button className="btn-confirm">로그인하기</button>
            </Link>

            <Link to="/">
              <button className="btn-inactive">메인으로</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpComplete;
