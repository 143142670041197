export const NOTICE_LIST_REQUEST = 'NOTICE_LIST_REQUEST';
export const NOTICE_LIST_SUCCESS = 'NOTICE_LIST_SUCCESS';
export const NOTICE_LIST_FAILURE = 'NOTICE_LIST_FAILURE';
export const NOTICE_LIST_RESET = 'NOTICE_LIST_RESET';

export const NOTICE_DETAIL_REQUEST = 'NOTICE_DETAIL_REQUEST';
export const NOTICE_DETAIL_SUCCESS = 'NOTICE_DETAIL_SUCCESS';
export const NOTICE_DETAIL_FAILURE = 'NOTICE_DETAIL_FAILURE';
export const NOTICE_DETAIL_RESET = 'NOTICE_DETAIL_RESET';

export const FAQ_LIST_REQUEST = 'FAQ_LIST_REQUEST';
export const FAQ_LIST_SUCCESS = 'FAQ_LIST_SUCCESS';
export const FAQ_LIST_FAILURE = 'FAQ_LIST_FAILURE';
export const FAQ_LIST_RESET = 'FAQ_LIST_RESET';

export const INQUIRY_CREATE_REQUEST = 'INQUIRY_CREATE_REQUEST';
export const INQUIRY_CREATE_SUCCESS = 'INQUIRY_CREATE_SUCCESS';
export const INQUIRY_CREATE_FAILURE = 'INQUIRY_CREATE_FAILURE';
export const INQUIRY_CREATE_RESET = 'INQUIRY_CREATE_RESET';

export const CUSTOMER_CLEAR_ERROR = 'CUSTOMER_CLEAR_ERROR';
