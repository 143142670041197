import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiSearchAlt2 } from 'react-icons/bi';
import Indicator from '../../layouts/Indicator';
import Footer from '../../layouts/Footer';
import Loading from '../../common/Loading';
import Message from '../../common/Message';
import Pagination from '../../common/Pagination';
import { getNoticeList } from '../../../redux/actions/customerActions';
import {
  BOARD_TYPE_INFO,
  BOARD_SIZE_INFO,
} from '../../../constants/dataConstants';

const CustomerNotice = ({ history, match }) => {
  const boardType = 'notice';
  const pageSize = BOARD_SIZE_INFO[boardType];

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);
  const { notices, totalCount, error, isLoading } = useSelector(
    (state) => state.noticeList
  );

  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('keyword');
  const [params, setParams] = useState({});
  const [pageIndex, setPageIndex] = useState(
    !isNaN(parseInt(match.params.pageIndex))
      ? parseInt(match.params.pageIndex)
      : 0
  );

  useEffect(() => {
    if (isAuthenticated) {
      console.log('CustomerNotice - isAuthenticated', isAuthenticated);
    } else {
      history.push('/login?redirect=/customer/notice');
    }
  }, []);

  const setNoticeList = (searchParams = params) => {
    searchParams['type'] = BOARD_TYPE_INFO[boardType];
    console.log('setNoticeList() - searchParams', searchParams);
    dispatch(getNoticeList({ pageSize, pageIndex }, searchParams));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      findNotice();
    }
  };

  const findNotice = () => {
    const newParams = {};
    if (searchTerm !== '') newParams[searchType] = searchTerm;

    setParams(newParams);
    setNoticeList(newParams);
  };

  return (
    <div className="customer">
      <div className="customer-wrap">
        <Indicator />

        {isLoading && <Loading />}
        {error && <Message variant="danger">{error}</Message>}

        <div className="customer-notice">
          <div className="customer-notice-header">
            <div className="customer-notice-count">
              Total: <strong>{totalCount}</strong>
            </div>

            <div className="customer-notice-search">
              <select
                name=""
                id="keywordCategory"
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
              >
                <option value="keyword">전체</option>
                <option value="title">제목</option>
                <option value="userName">작성자</option>
              </select>

              <input
                type="text"
                className="searchInput"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <BiSearchAlt2
                className="customer-notice-search-button"
                onClick={findNotice}
              />
            </div>
          </div>

          <div className="customer-notice-body">
            <div className="customer-notice-post-wrap">
              {notices.map((notice, index) => (
                <div
                  className="customer-notice-post-item"
                  onClick={() =>
                    history.push(`/customer/notice/${notice?.seq}/${pageIndex}`)
                  }
                  key={index}
                >
                  <div className="customer-notice-post-header">
                    <div className="customer-notice-post-title">
                      {notice?.crtDate.substr(8, 2)}
                    </div>
                    <div className="customer-notice-post-subtitle">
                      {notice?.crtDate.substr(2, 2)}.
                      {notice?.crtDate.substr(5, 2)}
                    </div>
                  </div>

                  <div className="customer-notice-post-content">
                    {notice?.title}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="customer-notice-footer">
            <Pagination
              totalCount={totalCount}
              pageSize={pageSize}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              renewPage={setNoticeList}
            />
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default CustomerNotice;
