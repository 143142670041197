import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import { hideConfirm } from '../../redux/actions/globalActions';

const Confirm = () => {
  const confirm = useSelector((state) => state.confirm);
  const {
    title = '',
    message = '',
    yes = '확인',
    no = '취소',
    callback = null,
    isShow,
  } = confirm;
  const dispatch = useDispatch();

  const hide = (isYes = false) => {
    dispatch(hideConfirm(isYes));

    if (typeof callback === 'function') callback(isYes);
  };

  return (
    <>
      {isShow ? (
        <div className="modal">
          <div
            className={`modal-wrap modal-confirm ${
              title === '' ? 'no-title' : ''
            }`}
          >
            <div className="modal-header">{parse(title)}</div>

            <div className="modal-body">{parse(message)}</div>

            <div className="modal-footer">
              <button
                className="btn-negative modal-btn-cancel"
                onClick={() => hide(false)}
              >
                {no}
              </button>

              <button
                className="btn-confirm modal-btn-confirm"
                onClick={() => hide(true)}
              >
                {yes}
              </button>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Confirm;
