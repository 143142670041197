import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_LOGIN_CHECK_REQUEST,
  USER_LOGIN_CHECK_SUCCESS,
  USER_LOGIN_CHECK_FAILURE,
  USER_SIGN_UP_REQUEST,
  USER_SIGN_UP_SUCCESS,
  USER_SIGN_UP_FAILURE,
  USER_SIGN_UP_RESET,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_FAILURE,
  USER_DETAIL_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_UPDATE_RESET,
  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PASSWORD_UPDATE_FAILURE,
  USER_PASSWORD_UPDATE_RESET,
  USER_CLEAR_ERROR,
} from '../types/userTypes';

const initialUserState = {
  userInfo: null,
  token: null,
  error: '',
  isLoading: false,
  isLogin: false, // 로그인 자체 성공 여부
  isAuthenticated: false, // 로그인 후, 이메일 확인 완료 여부
};
export const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
    case USER_LOGIN_CHECK_REQUEST:
      return { ...state, error: '', isLogin: false, isLoading: true };

    case USER_LOGIN_SUCCESS:
    case USER_LOGIN_CHECK_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        userInfo: action.payload.userInfo,
        isLoading: false,
        isLogin: true,
        isAuthenticated: action.payload.isAuthenticated,
      };

    case USER_LOGIN_FAILURE:
    case USER_LOGIN_CHECK_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLogin: false,
      };

    case USER_CLEAR_ERROR:
      return { ...state, error: '' };

    case USER_LOGOUT:
      return initialUserState;

    default:
      return { ...state };
  }
};

const initialSignUpState = {
  token: null,
  error: '',
  isLoading: false,
  isSuccess: false,
};
export const signUpReducer = (state = initialSignUpState, action) => {
  switch (action.type) {
    case USER_SIGN_UP_REQUEST:
      return { ...state, error: '', isLoading: true, isSuccess: false };

    case USER_SIGN_UP_SUCCESS:
      return {
        ...state,
        newUser: action.payload.newUser,
        name: action.payload.name,
        roleName: action.payload.roleName,
        crtDate: action.payload.crtDate,
        token: action.payload.token,
        isLoading: false,
        isSuccess: true,
      };

    case USER_SIGN_UP_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSuccess: false,
      };

    case USER_CLEAR_ERROR:
      return { ...state, error: '' };

    case USER_SIGN_UP_RESET:
      return initialSignUpState;

    default:
      return { ...state };
  }
};

export const userDetailReducer = (
  state = {
    userDetail: null,
    userCodes: null,
    userCompany: null,
    userFile: null,
    error: '',
    isLoading: false,
    isSuccess: false,
  },
  action
) => {
  switch (action.type) {
    case USER_DETAIL_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true,
        isSuccess: false,
      };

    case USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload.userDetail,
        userCodes: action.payload.userCodes,
        userCompany: action.payload.userCompany,
        userFile: action.payload.userFile,
        isLoading: false,
        isSuccess: true,
      };

    case USER_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSuccess: false,
      };

    case USER_DETAIL_RESET:
      return {
        ...state,
        userDetail: null,
        userCodes: null,
        error: '',
        isLoading: false,
        isSuccess: false,
      };

    case USER_CLEAR_ERROR:
      return { ...state, error: '' };

    case USER_LOGOUT:
      return {
        userDetail: null,
        userCodes: null,
        error: '',
        isLoading: false,
        isSuccess: false,
      };

    default:
      return { ...state };
  }
};

export const userUpdateReducer = (
  state = { error: '', isLoading: false, isSuccess: false },
  action
) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true,
        isSuccess: false,
      };

    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };

    case USER_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSuccess: false,
      };

    case USER_UPDATE_RESET:
      return {
        ...state,
        error: '',
        isLoading: false,
        isSuccess: false,
      };

    case USER_CLEAR_ERROR:
      return { ...state, error: '' };

    case USER_LOGOUT:
      return { error: '', isLoading: false, isSuccess: false };

    default:
      return { ...state };
  }
};

export const passwordUpdateReducer = (
  state = { error: '', isLoading: false, isSuccess: false },
  action
) => {
  switch (action.type) {
    case USER_PASSWORD_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true,
        isSuccess: false,
      };

    case USER_PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };

    case USER_PASSWORD_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSuccess: false,
      };

    case USER_PASSWORD_UPDATE_RESET:
      return {
        ...state,
        error: '',
        isLoading: false,
        isSuccess: false,
      };

    case USER_CLEAR_ERROR:
      return { ...state, error: '' };

    default:
      return { ...state };
  }
};
