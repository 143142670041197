import React from 'react';
import { useState, useEffect } from 'react';
import { CgClose } from 'react-icons/cg';
import utils from '../../modules/utils';

const PopupToday = ({ messageInfo, hidePopup }) => {
  /* Popup 타입: 텍스트형(B) / 이미지형(P) */
  const [isTextPopup, _] = useState(messageInfo.type === 'B');

  useEffect(() => {
    checkPopupShow();
  }, []);

  const checkPopupShow = () => {
    const todayPopupString = localStorage.getItem('todayPopupInfo');

    if (todayPopupString) {
      const todayPopupInfo = JSON.parse(todayPopupString);

      Object.keys(todayPopupInfo)?.some((seq) => {
        const popupInfo = todayPopupInfo[seq];
        const { today, week } = popupInfo;
        const weekTime = 60 * 60 * 24 * 7 * 1000;
        const isSamePopup = `${messageInfo?.seq}` === `${seq}`;
        const isTodayNoShow = today && today === utils.formatDate(new Date());
        const isWeekNoShow =
          week && new Date(week).getTime() + weekTime > Date.now();

        if (isSamePopup && (isTodayNoShow || isWeekNoShow)) {
          hidePopup();
        }
      });
    }
  };

  const handleNoShow = (e) => {
    const { name, checked } = e.target;

    if (checked) {
      let todayPopupInfo = {};
      const todayPopupString = localStorage.getItem('todayPopupInfo');
      if (todayPopupString) todayPopupInfo = JSON.parse(todayPopupString);

      const noShowInfo = { today: null, week: null };
      if (name === 'noShowToday') {
        noShowInfo.today = utils.formatDate(new Date());
      } else if (name === 'noShowWeek') {
        noShowInfo.week = utils.formatDate(new Date());
      }

      const seq = messageInfo?.seq;
      todayPopupInfo[seq] = noShowInfo;

      localStorage.setItem('todayPopupInfo', JSON.stringify(todayPopupInfo));
      hidePopup();
    }
  };

  function openLink(url) {
    if (url) {
      if (!url.match(/^https?:\/\//i)) {
        url = 'http://' + url;
      }
      return window.open(url);
    }
  }

  return (
    <div className="popup-today">
      <div
        className={`popup-today-close ${
          !isTextPopup ? 'popup-today-close-image' : ''
        }`}
      >
        <CgClose onClick={hidePopup} />
      </div>

      <div className="popup-today-body">
        <div
          className={messageInfo?.url ? 'url-link' : 'no-link'}
          onClick={() => openLink(messageInfo?.url)}
        >
          {isTextPopup ? (
            <textarea
              className="popup-today-text"
              value={messageInfo?.html}
              readOnly
            />
          ) : (
            <img
              className="popup-today-image"
              src={
                messageInfo?.attachFileVOs?.[0] &&
                messageInfo?.attachFileVOs?.[0]?.url
              }
              alt="Today popup"
            />
          )}
        </div>
      </div>

      <div className="popup-today-footer">
        <div className="popup-today-no-show-item">
          <input
            type="checkbox"
            name="noShowToday"
            id="noShowToday"
            onChange={handleNoShow}
          />{' '}
          <label htmlFor="noShowToday">오늘 더 이상 보지 않음</label>
        </div>

        <div className="popup-today-no-show-item">
          <input
            type="checkbox"
            name="noShowWeek"
            id="noShowWeek"
            onChange={handleNoShow}
          />{' '}
          <label htmlFor="noShowWeek">1주일간 보지 않음</label>
        </div>
      </div>
    </div>
  );
};

export default PopupToday;
