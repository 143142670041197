import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CgClose } from 'react-icons/cg';
import { MdDone } from 'react-icons/md';
import parse from 'html-react-parser';
import Message from '../../common/Message';
import Loading from '../../common/Loading';
import { showAlert, showConfirm } from '../../../redux/actions/globalActions';
import {
  getAssessmentCount,
  getSurvey,
  submitSurvey,
  resetSurvey,
  resetSubmit,
} from '../../../redux/actions/assessmentActions';
import {
  SURVEY_STEPS,
  ASSESSMENT_TOOLTIP,
} from '../../../constants/dataConstants';
import utils from '../../../modules/utils';

const AssessmentSurvey = ({
  history,
  documentId,
  groupId,
  setIsSurveyModal,
  endAssessment,
  assessmentStep,
}) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);
  const { surveys, patent, error, isLoading } = useSelector(
    (state) => state.survey
  );
  const {
    error: submitError,
    isLoading: isSubmitting,
    isSuccess: isSubmitted,
  } = useSelector((state) => state.surveySubmit);

  const [steps, setSteps] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [displayTooltips, setDisplayTooltips] = useState([]);

  useEffect(() => {
    if (documentId && groupId) {
      const second = assessmentStep === 'second';
      dispatch(getSurvey(documentId, groupId, second));
      utils.blockClose(true);
    } else {
      dispatch(showAlert({ message: '평가 데이터를 불러올 수 없습니다.' }));
      history.push('/');
    }
  }, []);

  useEffect(() => {
    const newSteps = [];
    SURVEY_STEPS.forEach((step) => {
      const stepQuestions = surveys.filter(
        (survey) => survey.type === step.type
      );
      if (stepQuestions.length > 0) {
        newSteps.push({
          name: step.name,
          code: step.type,
          questions: stepQuestions,
        });
      }
    });

    const initAnswers = surveys.map((_) => '');
    setAnswers(initAnswers);
    setSteps(newSteps);
  }, [surveys]);

  useEffect(() => {
    if (isSubmitted) {
      dispatch(
        showAlert({
          title:
            assessmentStep === 'first'
              ? '평가가 모두 완료되었습니다.'
              : '2차 평가가 완료되었습니다.',
          message:
            assessmentStep === 'first'
              ? '평가 기록은 마이페이지 > 내 평가 관리에서 확인할 수<br />있으며, 2차 평가 대상으로 분류될 경우 개별 안내 드립니다.'
              : '평가 기록은 마이페이지 > 내 평가 관리에서<br />확인할 수 있습니다.',
          callback: () => {
            dispatch(resetSurvey());
            dispatch(resetSubmit());
          },
        })
      );
    } else if (submitError) {
      dispatch(showAlert({ title: '제출 실패', message: submitError }));
    }
  }, [submitError, isSubmitted]);

  useEffect(() => {
    setDisplayTooltips([]);
  }, [stepIndex]);

  const inputAnswer = (answer, question) => {
    if (!question.prevInput || !question.prevInput.includes(parseInt(answer))) {
      const newAnswers = [...answers];
      const answerIndex = parseInt(question.no) - 1;
      newAnswers[answerIndex] = answer;
      setAnswers(newAnswers);
    }
  };

  const prevStep = () => {
    scrollToTop();

    const newIndex = Math.max(stepIndex - 1, 0);
    setStepIndex(newIndex);
  };

  const nextStep = () => {
    scrollToTop();

    const newIndex = Math.min(stepIndex + 1, steps.length - 1);
    setStepIndex(newIndex);
  };

  const scrollToTop = () => {
    const scrollWrap = document.querySelector('.survey-modal');
    scrollWrap.scrollTop = 0;
  };

  const cancel = () => {
    dispatch(
      showConfirm({
        title: '평가가 완료되지 않았습니다.',
        message:
          '페이지 이탈 시 평가는 저장되지 않습니다.<br />평가를 종료하시겠습니까?',
        yes: '종료',
        no: '평가로 돌아가기',
        callback: (isYes) => {
          if (isYes) {
            endAssessment();
            setIsSurveyModal(false);
          }
        },
      })
    );
  };

  const submit = () => {
    const isAllAnswered = answers.every((answer) => answer !== '');

    if (!isAllAnswered) {
      return dispatch(
        showAlert({
          title: '평가 미완료',
          message:
            '평가되지 않은 항목이 있습니다.<br/>모두 평가 후 평가완료를 시도해주세요.',
        })
      );
    }

    dispatch(
      showConfirm({
        title: '평가 완료 확인',
        message:
          '평가 후에는 내용을 수정할 수 없습니다.<br/>평가를 완료하시겠습니까?',
        callback: async (isYes) => {
          if (isYes) {
            const surveyInfo = {
              documentId,
              groupId,
              inputData: {
                inputs: answers,
              },
              second: assessmentStep === 'second',
            };

            await dispatch(submitSurvey(surveyInfo));
            await dispatch(getAssessmentCount());
            endAssessment();
            setIsSurveyModal(false);
          }
        },
      })
    );
  };

  const getIsStepDone = (step) => {
    const stepQuestionIndexes = step.questions.map(
      (question) => parseInt(question.no) - 1
    );

    const isAllAnswered = answers.every(
      (answer, index) => !stepQuestionIndexes.includes(index) || answer.trim()
    );

    return isAllAnswered;
  };

  const showTooltip = (questionNumber) => {
    let newDisplayTooltips = [...displayTooltips];
    const isShowTooltip = displayTooltips.includes(questionNumber);
    if (!isShowTooltip) {
      newDisplayTooltips.push(questionNumber);
      setDisplayTooltips(newDisplayTooltips);
    }
  };

  const hideTooltip = (questionNumber) => {
    let newDisplayTooltips = displayTooltips.filter(
      (tooltipNumber) => tooltipNumber !== questionNumber
    );

    setDisplayTooltips(newDisplayTooltips);
  };

  return (
    <>
      {error && <Message>{error}</Message>}
      {(isLoading || isSubmitting) && <Loading />}

      <div className="modal survey-modal-wrap">
        <div className="survey-modal">
          <div className="survey-header">
            <div className="survey-logo"></div>

            <div className="survey-etc">
              <div className="btn-negative survey-user">
                평가자 <strong>{userInfo.name}</strong>
              </div>

              <div className="survey-close" onClick={cancel}>
                <CgClose size="35" />
              </div>
            </div>
          </div>

          <div className="survey-body">
            <div className="survey-info">
              <div className="survey-info-desc">
                <div className="survey-info-desc-title">특허문헌 평가지</div>

                <div className="survey-info-desc-subtitle">
                  상세보기 버튼을 클릭해 상세 내용을 확인 후 평가를
                  진행해주십시오.
                </div>
              </div>

              <div className="survey-info-document">
                <div className="survey-info-document-id">{patent.viewData}</div>

                <div className="survey-info-document-summary">
                  {patent.inventionTitle}
                </div>

                <div className="survey-info-document-detail">
                  <a href={patent.link} target="_blank">
                    <button className="btn-cancel">상세보기</button>
                  </a>
                </div>
              </div>
            </div>

            <div className="survey-main">
              <div className="survey-main-tab">
                {steps.map((step, index) => (
                  <button
                    className={`survey-main-tab-item 
                      ${getIsStepDone(step) && 'done'}
                    ${index === stepIndex && 'current'}`}
                    key={index}
                    onClick={() => setStepIndex(index)}
                  >
                    <MdDone
                      size="25"
                      fill={
                        getIsStepDone(step)
                          ? '#fff'
                          : index === stepIndex
                          ? '#ccc'
                          : '#aaa'
                      }
                    />
                    <div className="survey-main-tab-item-text">{step.name}</div>
                  </button>
                ))}
              </div>

              <div className="survey-main-desc">
                <div className="survey-main-desc-title">
                  {steps[stepIndex]?.name}
                </div>

                <div className="survey-main-desc-text">
                  <div className="survey-main-desc-icon" />
                  해당 유형에는 총 {steps[stepIndex]?.questions?.length}개의
                  질문이 있습니다. 이전/다음 버튼을 클릭해 자유롭게
                  평가해주세요.
                </div>
              </div>

              {steps[stepIndex]?.questions?.map((question, index) => (
                <Fragment key={index}>
                  {question.dataType === 'R' ? (
                    <div className="survey-main-question">
                      <div className="survey-main-question-title">
                        {question.no}. {question.title}
                      </div>

                      <div className="survey-main-question-subtitle">
                        {parse(utils.convertTextToHtml(question.desc) || '')}

                        {!utils.isEmptyString(
                          ASSESSMENT_TOOLTIP[question.no]
                        ) && (
                          <div
                            className="survey-main-question-tooltip-icon"
                            onClick={() => showTooltip(question.no)}
                          >
                            {displayTooltips.includes(question.no) && (
                              <div className="survey-main-question-tooltip">
                                <CgClose
                                  className="survey-main-question-tooltip-close"
                                  onClick={() => hideTooltip(question.no)}
                                />
                                {parse(ASSESSMENT_TOOLTIP[question.no] || '')}
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="survey-main-question-input">
                        <div
                          className={`survey-main-question-range-wrap ${
                            answers[question.no - 1] === '' && 'not-answered'
                          }`}
                        >
                          <div className="survey-main-question-range-bar"></div>

                          <div className="survey-main-question-range">
                            <div className="survey-main-question-range-point">
                              {Array(11)
                                .fill()
                                .map((_, index) =>
                                  !question.prevInput ||
                                  !question.prevInput.includes(index) ? (
                                    <div
                                      className={`range-point ${
                                        `${
                                          answers[question.no - 1] ||
                                          question.startIndex ||
                                          0
                                        }` === `${index}`
                                          ? 'current'
                                          : ''
                                      }`}
                                      key={`range_point_${index}`}
                                      onClick={(e) =>
                                        inputAnswer(`${index}`, question)
                                      }
                                    />
                                  ) : (
                                    <div
                                      className="range-none"
                                      key={`range_point_${index}`}
                                    />
                                  )
                                )}
                            </div>

                            <div
                              className="survey-main-question-range-fill"
                              style={{
                                width: `${
                                  (answers[question.no - 1] ||
                                    question.startIndex) !== '0'
                                    ? (answers[question.no - 1] ||
                                        question.startIndex ||
                                        0) *
                                        10 +
                                      ((answers[question.no - 1] ||
                                        question.startIndex ||
                                        0) === 0
                                        ? 0
                                        : +2.5)
                                    : 0
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>

                        <div className="survey-main-question-range-number">
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                            (number, index) => (
                              <div
                                className="range-number"
                                key={`range_number_${index}`}
                              >
                                {number}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="survey-main-question">
                      <div className="survey-main-question-title">
                        {question.no}. {question.title}
                      </div>

                      <div className="survey-main-question-input">
                        <textarea
                          className="survey-main-question-textarea"
                          placeholder="내용을 입력하세요."
                          value={
                            answers[question.no - 1]
                              ? answers[question.no - 1]
                              : ''
                          }
                          onChange={(e) =>
                            inputAnswer(e.target.value, question)
                          }
                        />
                      </div>
                    </div>
                  )}

                  {!utils.isNull(question?.etcRev) &&
                    Array.isArray(question?.etcRev) &&
                    question?.etcRev?.length > 0 && (
                      <div className="survey-main-info">
                        <div className="survey-main-info-title">
                          <div className="survey-main-info-icon" />
                          평가척도는 참고 기준일 뿐 절대적인 것이 아니며, 평가자
                          판단에 따라 평가해주시기 바랍니다.
                        </div>

                        <div className="survey-main-standard">
                          <div className="survey-main-standard-title">
                            평가 척도
                          </div>

                          <ul className="survey-main-standard-table">
                            {question?.etcRev?.map((standard, index) =>
                              standard?.desc.indexOf('점:') > -1 ? (
                                <li
                                  className="survey-main-standard-item"
                                  key={`standard_${index}`}
                                >
                                  <div className="survey-main-standard-score">
                                    {standard?.desc.substr(
                                      0,
                                      standard?.desc.indexOf('점:') + 1
                                    )}
                                  </div>
                                  <div className="survey-main-standard-content">
                                    {standard?.desc.substr(
                                      standard?.desc.indexOf('점:') + 2
                                    )}
                                  </div>
                                </li>
                              ) : (
                                <li
                                  className="survey-main-standard-item"
                                  key={`standard_${index}`}
                                >
                                  {standard.desc}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    )}
                </Fragment>
              ))}

              <div className="survey-main-btn">
                {stepIndex > 0 && (
                  <button className="btn-survey-move" onClick={prevStep}>
                    이전으로
                  </button>
                )}
                {stepIndex < steps.length - 1 && (
                  <button className="btn-survey-move" onClick={nextStep}>
                    다음으로
                  </button>
                )}

                {stepIndex === steps.length - 1 && (
                  <button className="btn-confirm" onClick={submit}>
                    평가 완료
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentSurvey;
