import React from 'react';
import parse from 'html-react-parser';
import Indicator from '../../layouts/Indicator';
import Footer from '../../layouts/Footer';
import { AiOutlineDownload } from 'react-icons/ai';
import introductionImage1 from '../../../styles/images/introduction/introduction-img01.svg';
import introductionItem1 from '../../../styles/images/introduction/introduction-item01.svg';
import introductionItem2 from '../../../styles/images/introduction/introduction-item02.svg';
import introductionItem3 from '../../../styles/images/introduction/introduction-item03.svg';
import introductionGraph1 from '../../../styles/images/introduction/introduction-graph01.svg';
import introductionGraph2 from '../../../styles/images/introduction/introduction-graph02.svg';
import introductionGraphArrow from '../../../styles/images/introduction/introduction-graph-arrow.svg';
import introductionTable from '../../../styles/images/introduction/introduction-table.svg';

const IntroductionAssessment = () => {
  const introductions = [
    {
      icon: introductionItem1,
      text: `
        전문 변리사에 의한
        <br />
        <strong>신뢰성 있는 평가결과 제공</strong>
      `,
    },
    {
      icon: introductionItem2,
      text: `
        전문가 집단 정성평가에 관한
        <br />
        <strong>델파이 기법(Delphi technique)</strong>
        <br />
        적용
      `,
    },
    {
      icon: introductionItem3,
      text: `
        전문 변리사의 평가 참여 전
        <br />
        공정하고 성실한 평가 서약을
        <br />
        통한 <strong>평가 신뢰도 확보</strong>
      `,
    },
  ];

  return (
    <div className="introduction introduction-assessment">
      <Indicator />

      <div className="introduction-page1">
        <div className="introduction-page-main">
          <div className="introduction-page-left">
            <img
              className="introduction-image"
              src={introductionImage1}
              alt="Introduction Image1"
            />
          </div>

          <div className="introduction-page-right">
            <div className="introduction-text-wrap">
              <div className="introduction-subtitle">
                Expert collective intelligence system for patent valuation.
              </div>

              <div className="introduction-title">
                <strong>엑시스 밸류(EXsys Value)</strong>
                란?
              </div>

              <div className="introduction-text">
                전문성이 검증된 변리사가 평가자로 직접 참여하여
                <br />
                특허 권리의 유효성, 광협, 보호강도, 기술동향 부합도 등에
                <br />
                정성평가가 이루어지는 온라인 특허등급평가 시스템입니다.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="introduction-page2">
        <div className="introduction-page-main">
          <div className="introduction-list">
            {introductions.map((introduction, index) => (
              <div className="introduction-item" key={index}>
                <img
                  className="introduction-icon"
                  src={introduction.icon}
                  alt="Introduction Item Icon"
                ></img>

                <div className="introduction-text-wrap">
                  <div className="introduction-number">{`0${index + 1}`}</div>
                  <div className="introduction-text">
                    {parse(introduction.text)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="introduction-page3">
        <div className="introduction-page-main">
          <div className="introduction-graph">
            <div className="introduction-title">
              엑시스 밸류(EXsys Value) <strong>활용 분야</strong>
            </div>

            <div className="introduction-graph-list">
              <img
                className="introduction-graph1"
                src={introductionGraph1}
                alt="Introduction Graph"
              />

              <img
                src={introductionGraphArrow}
                className="introduction-graph-arrow"
                alt="Introduction Graph Arrow"
              />

              <img
                className="introduction-graph2"
                src={introductionGraph2}
                alt="Introduction Graph"
              />
            </div>
          </div>

          <div className="introduction-table">
            <div className="introduction-title">
              엑시스 밸류(EXsys Value)의 <strong>구성도</strong>
            </div>

            <img
              className="introduction-table-image"
              src={introductionTable}
              alt="Introduction Table"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default IntroductionAssessment;
