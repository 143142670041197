import {
  ASSESSMENT_LIST_REQUEST,
  ASSESSMENT_LIST_SUCCESS,
  ASSESSMENT_LIST_FAILURE,
  ASSESSMENT_COUNT_REQUEST,
  ASSESSMENT_COUNT_SUCCESS,
  ASSESSMENT_COUNT_FAILURE,
  ASSESSMENT_AGREE_REQUEST,
  ASSESSMENT_AGREE_SUCCESS,
  ASSESSMENT_AGREE_FAILURE,
  ASSESSMENT_AGREE_CLEAR_ERROR,
  ASSESSMENT_AGREE_RESET,
  ASSESSMENT_SURVEY_REQUEST,
  ASSESSMENT_SURVEY_SUCCESS,
  ASSESSMENT_SURVEY_FAILURE,
  ASSESSMENT_SURVEY_RESET,
  ASSESSMENT_SUBMIT_REQUEST,
  ASSESSMENT_SUBMIT_SUCCESS,
  ASSESSMENT_SUBMIT_FAILURE,
  ASSESSMENT_SUBMIT_RESET,
  ASSESSMENT_HISTORY_REQUEST,
  ASSESSMENT_HISTORY_SUCCESS,
  ASSESSMENT_HISTORY_FAILURE,
  ASSESSMENT_HISTORY_RESET,
} from '../types/assessmentTypes';

export const assessmentReducer = (state = { assessments: [] }, action) => {
  switch (action.type) {
    case ASSESSMENT_LIST_REQUEST:
      return { ...state, error: '', isLoading: true };

    case ASSESSMENT_LIST_SUCCESS:
      return { ...state, assessments: action.payload, isLoading: false };

    case ASSESSMENT_LIST_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    default:
      return { ...state };
  }
};

export const assessmentCountReducer = (state = { assessments: [] }, action) => {
  switch (action.type) {
    case ASSESSMENT_COUNT_REQUEST:
      return { ...state, error: '', isLoading: true };

    case ASSESSMENT_COUNT_SUCCESS:
      return { ...state, countInfo: action.payload, isLoading: false };

    case ASSESSMENT_COUNT_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    default:
      return { ...state };
  }
};

export const assessmentAgreeReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSESSMENT_AGREE_REQUEST:
      return { ...state, error: '', isLoading: true };

    case ASSESSMENT_AGREE_SUCCESS:
      return { ...state, isAgree: true, isLoading: false };

    case ASSESSMENT_AGREE_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    case ASSESSMENT_AGREE_CLEAR_ERROR:
      return { ...state, error: '', isLoading: false };

    case ASSESSMENT_AGREE_RESET:
      return { ...state, error: '', isLoading: false, isAgree: false };

    default:
      return { ...state };
  }
};

export const surveyReducer = (state = { surveys: [], patent: {} }, action) => {
  switch (action.type) {
    case ASSESSMENT_SURVEY_REQUEST:
      return { ...state, error: '', isLoading: true };

    case ASSESSMENT_SURVEY_SUCCESS:
      return {
        ...state,
        patent: action.payload.patent,
        groupVO: action.payload.groupVO,
        surveys: action.payload.surveyData?.surveys,
        isLoading: false,
      };

    case ASSESSMENT_SURVEY_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    case ASSESSMENT_SURVEY_RESET:
      return { ...state, surveys: [], patent: {}, isLoading: false };

    default:
      return { ...state };
  }
};

export const surveySubmitReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSESSMENT_SUBMIT_REQUEST:
      return { ...state, error: '', isLoading: true, isSuccess: false };

    case ASSESSMENT_SUBMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };

    case ASSESSMENT_SUBMIT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSuccess: false,
      };

    case ASSESSMENT_SUBMIT_RESET:
      return {
        error: '',
        isLoading: false,
        isSuccess: false,
      };

    default:
      return { ...state };
  }
};

export const assessmentHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSESSMENT_HISTORY_REQUEST:
      return { ...state, error: '', isLoading: true };

    case ASSESSMENT_HISTORY_SUCCESS:
      return {
        ...state,
        patents: action.payload.patents,
        totalCount: action.payload.totalCount,
        countInfo: action.payload.countInfo,
        rankInfo: action.payload.rankInfo,
        isLoading: false,
      };

    case ASSESSMENT_HISTORY_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    case ASSESSMENT_HISTORY_RESET:
      return { ...state, error: '', isLoading: false, isAgree: false };

    default:
      return { ...state };
  }
};
