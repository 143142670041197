import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import Indicator from '../../layouts/Indicator';
import Footer from '../../layouts/Footer';
import Message from '../../common/Message';
import Loading from '../../common/Loading';
import Pagination from '../../common/Pagination';
import AssessmentAgree from './AssessmentAgree';
import AssessmentSurvey from './AssessmentSurvey';
import {
  getAssessmentCount,
  getAssessments,
  resetAgree,
  resetSurvey,
} from '../../../redux/actions/assessmentActions';
import { getPopupMessage } from '../../../redux/actions/popupActions';
import utils from '../../../modules/utils';
import { ASSESSMENT_PAGE_SIZE } from '../../../constants/dataConstants';

const Assessment = ({ history, match }) => {
  const assessmentStep = match.params.assessmentStep;
  const pageSize = ASSESSMENT_PAGE_SIZE;

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);
  const { assessments, error, isLoading } = useSelector(
    (state) => state.assessment
  );
  const { countInfo, countError, isLoadingCount } = useSelector(
    (state) => state.assessmentCount
  );
  const { popupMessageInfo } = useSelector((state) => state.popupMessage);

  const [selectedAssessment, setSelectedAssessment] = useState({});
  const [openRows, setOpenRows] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [isAgreeModal, setIsAgreeModal] = useState(false);
  const [isSurveyModal, setIsSurveyModal] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      // URL Parameter assessmentStep가 'first', 'second'가 아니면 home으로 리턴
      const isValidAssessmentStep = ['first', 'second'].includes(
        assessmentStep
      );

      if (isValidAssessmentStep) {
        dispatch(resetAgree());
        dispatch(getAssessments(assessmentStep, { pageIndex, pageSize }));
        dispatch(getAssessmentCount());
      } else {
        history.push('/');
      }
    } else {
      history.push(`/login?redirect=/assessment/${assessmentStep}`);
    }
  }, [match]);

  useEffect(() => {
    setOpenRows([]);
  }, [assessments]);

  const refreshList = () => {
    dispatch(getAssessments(assessmentStep));
  };

  const handleOpen = (uniqueId) => {
    let newOpenRows = [...openRows];
    const isOpened = newOpenRows.includes(uniqueId);
    if (isOpened) {
      newOpenRows = newOpenRows.filter((openRow) => openRow !== uniqueId);
    } else {
      newOpenRows.push(uniqueId);
    }

    setOpenRows(newOpenRows);
  };

  const renewPage = () => {
    dispatch(getAssessments(assessmentStep, { pageIndex, pageSize }));
  };

  const showAssessmentAgree = (assessment) => {
    setSelectedAssessment(assessment);
    setIsAgreeModal(true);
  };

  const showAssessmentSurvey = () => {
    setIsSurveyModal(true);
  };

  const startAssessment = () => {
    dispatch(resetSurvey());
    showAssessmentSurvey();
  };

  const endAssessment = () => {
    dispatch(getAssessments(assessmentStep));
    dispatch(getPopupMessage());
  };

  return (
    <div className="assessment">
      <div className="assessment-wrap">
        <Indicator />

        {(isLoading || isLoadingCount) && <Loading />}
        {isAgreeModal && (
          <AssessmentAgree
            assessmentStep={assessmentStep}
            documentId={selectedAssessment?.documentId}
            groupId={selectedAssessment?.groupId}
            startAssessment={startAssessment}
            setIsAgreeModal={setIsAgreeModal}
          />
        )}
        {isSurveyModal && (
          <AssessmentSurvey
            assessmentStep={assessmentStep}
            documentId={selectedAssessment?.documentId}
            groupId={selectedAssessment?.groupId}
            endAssessment={endAssessment}
            setIsSurveyModal={setIsSurveyModal}
          />
        )}

        {error && <Message variant="danger">{error}</Message>}
        {countError && <Message variant="danger">{countError}</Message>}

        <div className="assessment-header">
          <div className="assessment-header-title">
            {assessmentStep === 'first'
              ? '평가대상 특허문헌'
              : '2차 평가대상 특허문헌'}
          </div>

          <div className="assessment-header-subtitle">
            평가할 특허문헌을 선택해주세요
          </div>

          <div className="assessment-header-count">
            평가 완료건수:{' '}
            {assessmentStep === 'first'
              ? !utils.isNull(countInfo?.firstCount)
                ? `${countInfo?.firstCount}건`
                : '0건'
              : !utils.isNull(countInfo?.secondCount)
              ? `${countInfo?.secondCount}건`
              : '0건'}
          </div>
        </div>

        <div className="assessment-body">
          <div className="assessment-info">
            <div className="assessment-info-message">
              <span className="assessment-info-icon"></span>페이지를 벗어나거나
              다시 로그인할 경우 새로운 목록으로 업데이트 되오니 참고해주세요.
            </div>

            <button className="assessment-info-refresh" onClick={refreshList}>
              <span className="assessment-refresh-icon"></span> 새로운 평가목록
              보기
            </button>
          </div>

          {assessments.length > 0 ? (
            <table className="assessment-table">
              <thead>
                <tr>
                  <th>IPC</th>
                  <th>기술분야</th>
                  <th>발명의 명칭</th>
                  {assessmentStep !== 'first' && <th>1차 평가 일시</th>}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {assessments.map((assessment, index) => (
                  <Fragment key={index}>
                    <tr className="assessment-list-row">
                      {/* IE11 에서, 자세히보기 클릭 시, 각 열의 길이 안맞는 현상 때문에 Inline 스타일 추가 */}
                      <td style={{ width: '125px' }}>{assessment.ipc}</td>
                      <td style={{ width: '150px' }}>{assessment.fieldName}</td>

                      {assessmentStep === 'first' ? (
                        <td
                          style={{ width: '475px' }}
                          className="assessment-item-title"
                        >
                          {assessment.inventionTitle}
                        </td>
                      ) : (
                        <>
                          <td
                            style={{ width: '350px' }}
                            className="assessment-item-title"
                          >
                            {assessment.inventionTitle}
                          </td>

                          <td style={{ width: '125px' }}>
                            {assessment.firstDate}
                          </td>
                        </>
                      )}

                      <td>
                        <div className="assessment-btn-wrap">
                          <button
                            className={`btn-inactive ${
                              openRows.includes(assessment?.documentId)
                                ? 'more-open'
                                : ''
                            }`}
                            onClick={() => handleOpen(assessment?.documentId)}
                          >
                            자세히보기
                          </button>
                          <button
                            className="btn-confirm"
                            onClick={() => showAssessmentAgree(assessment)}
                          >
                            평가하기
                          </button>
                        </div>
                      </td>
                    </tr>

                    {openRows.includes(assessment?.documentId) && (
                      <tr>
                        <td
                          className="assessment-item-desc"
                          colSpan={assessmentStep === 'first' ? 4 : 5}
                        >
                          <div className="assessment-desc-row">
                            <div className="title">발명의 명칭</div>
                            <div className="text">
                              {parse(assessment?.inventionTitle || '')}
                            </div>
                          </div>

                          <div className="assessment-desc-row">
                            <div className="title">요약</div>
                            <div className="text">
                              {parse(assessment?.summary || '')}
                            </div>
                          </div>

                          <div className="assessment-desc-row">
                            <div className="title">평가횟수</div>
                            <div className="text">
                              {assessment?.totalEvaluateDesc}건
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>

              {assessmentStep === 'second' &&
                popupMessageInfo?.secondCount > pageSize && (
                  <tfoot>
                    <tr>
                      <td colSpan={assessmentStep === 'first' ? 4 : 5}>
                        <Pagination
                          totalCount={popupMessageInfo?.secondCount || 0}
                          pageSize={pageSize}
                          pageIndex={pageIndex}
                          setPageIndex={setPageIndex}
                          renewPage={renewPage}
                        />
                      </td>
                    </tr>
                  </tfoot>
                )}
            </table>
          ) : (
            !isLoading && (
              <div className="assessment-empty">
                <div className="assessment-empty-img" />
                <div className="assessment-empty-text">
                  <strong>
                    {assessmentStep === 'first'
                      ? '평가대상 특허문헌이 없습니다.'
                      : '2차 평가대상 특허문헌이 없습니다.'}
                  </strong>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Assessment;
