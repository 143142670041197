import axios from 'axios';
import {
  ASSESSMENT_LIST_REQUEST,
  ASSESSMENT_LIST_SUCCESS,
  ASSESSMENT_LIST_FAILURE,
  ASSESSMENT_COUNT_REQUEST,
  ASSESSMENT_COUNT_SUCCESS,
  ASSESSMENT_COUNT_FAILURE,
  ASSESSMENT_AGREE_REQUEST,
  ASSESSMENT_AGREE_SUCCESS,
  ASSESSMENT_AGREE_FAILURE,
  ASSESSMENT_AGREE_CLEAR_ERROR,
  ASSESSMENT_AGREE_RESET,
  ASSESSMENT_SURVEY_REQUEST,
  ASSESSMENT_SURVEY_SUCCESS,
  ASSESSMENT_SURVEY_FAILURE,
  ASSESSMENT_SURVEY_RESET,
  ASSESSMENT_SUBMIT_REQUEST,
  ASSESSMENT_SUBMIT_SUCCESS,
  ASSESSMENT_SUBMIT_FAILURE,
  ASSESSMENT_SUBMIT_RESET,
  ASSESSMENT_HISTORY_REQUEST,
  ASSESSMENT_HISTORY_SUCCESS,
  ASSESSMENT_HISTORY_FAILURE,
  ASSESSMENT_HISTORY_RESET,
} from '../types/assessmentTypes';
import {
  API_ASSESSMENT_LIST,
  API_ASSESSMENT_LIST_SECOND,
  API_ASSESSMENT_COUNT,
  API_ASSESSMENT_AGREE,
  API_ASSESSMENT_REQUEST,
  API_ASSESSMENT_SUBMIT,
  API_ASSESSMENT_HISTORY,
} from '../../constants/apiConstants';
import { checkAuth } from '../middlewares/checkAuth';

export const getAssessments =
  (assessmentStep, options = { pageIndex: 0, pageSize: 10 }) =>
  async (dispatch, getState) => {
    try {
      const isAuth = checkAuth(dispatch, getState);
      if (!isAuth) return;

      dispatch({ type: ASSESSMENT_LIST_REQUEST });

      let url;
      if (assessmentStep === 'first') url = API_ASSESSMENT_LIST;
      else if (assessmentStep === 'second') {
        const query = window.encodeURIComponent(
          `index=${options.pageIndex + 1}&size=${options.pageSize}`
        );
        url = `${API_ASSESSMENT_LIST_SECOND}?${query}`;
      } else throw 'Invalid Assessement Step';
      const method = 'GET';

      const res = await axios({ url, method });
      const resData = res.data || {};
      const assessments = resData.data || [];
      console.log('getAssessments() - resData', resData);

      const {
        code: { codeInfo },
      } = getState();
      assessments.forEach((assessment) => {
        const code = codeInfo[assessment.code];
        assessment.fieldName = `${code.field_kr}/${code.sector_kr}`;
      });

      dispatch({ type: ASSESSMENT_LIST_SUCCESS, payload: assessments });
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: ASSESSMENT_LIST_FAILURE,
        payload:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.message,
      });
    }
  };

export const getAssessmentCount = () => async (dispatch, getState) => {
  try {
    const isAuth = checkAuth(dispatch, getState);
    if (!isAuth) return;

    dispatch({ type: ASSESSMENT_COUNT_REQUEST });

    const url = API_ASSESSMENT_COUNT;
    const method = 'GET';

    const res = await axios({ url, method });
    const resData = res.data || {};
    const { patent_count: firstCount, second_count: secondCount } =
      resData?.data || {};
    console.log('getAssessmentCount() - resData', resData);

    dispatch({
      type: ASSESSMENT_COUNT_SUCCESS,
      payload: { firstCount, secondCount },
    });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: ASSESSMENT_COUNT_FAILURE,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const agreeAssessment =
  (documentId, groupId, second = false) =>
  async (dispatch, getState) => {
    try {
      const isAuth = checkAuth(dispatch, getState);
      if (!isAuth) return;

      dispatch({ type: ASSESSMENT_AGREE_REQUEST });

      const url = `${API_ASSESSMENT_AGREE}?documentId=${documentId}&groupId=${groupId}&second=${second}`;
      const method = 'POST';

      const res = await axios({ url, method });
      const resData = res.data || {};
      console.log('agreeAssessment() - resData', resData);

      dispatch({ type: ASSESSMENT_AGREE_SUCCESS, payload: resData.data });
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: ASSESSMENT_AGREE_FAILURE,
        // payload:
        //   err.response && err.response.data.msg
        //     ? err.response.data.msg
        //     : err.message,

        // 청렴 서약 실패 메시지는 서버에서 내려오는 메시지 사용하지 않고, 하드코딩으로 고정
        payload: '정회원만 이용 가능한 서비스입니다.',
      });
    }
  };

export const getSurvey =
  (documentId, groupId, second = false) =>
  async (dispatch, getState) => {
    try {
      const isAuth = checkAuth(dispatch, getState);
      if (!isAuth) return;

      dispatch({ type: ASSESSMENT_SURVEY_REQUEST });

      const query = window.encodeURIComponent(
        `documentId=${documentId}&groupId=${groupId}&second=${second}`
      );
      const url = `${API_ASSESSMENT_REQUEST}?${query}`;
      const method = 'GET';

      const res = await axios({ url, method });
      const resData = res.data || {};
      console.log('getSurvey() - resData', resData);

      dispatch({ type: ASSESSMENT_SURVEY_SUCCESS, payload: resData.data });
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: ASSESSMENT_SURVEY_FAILURE,
        payload:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.message,
      });
    }
  };

export const submitSurvey = (surveyInfo) => async (dispatch, getState) => {
  try {
    const isAuth = checkAuth(dispatch, getState);
    if (!isAuth) return;

    dispatch({ type: ASSESSMENT_SUBMIT_REQUEST });

    const url = `${API_ASSESSMENT_SUBMIT}`;
    const method = 'POST';
    const data = surveyInfo;

    const res = await axios({ url, method, data });
    const resData = res.data || {};
    console.log('submitSurvey() - resData', resData);

    dispatch({ type: ASSESSMENT_SUBMIT_SUCCESS, payload: resData.data });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: ASSESSMENT_SUBMIT_FAILURE,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const getAssessmentHistory =
  (options = {}, searchParams = {}) =>
  async (dispatch, getState) => {
    try {
      const isAuth = checkAuth(dispatch, getState);
      if (!isAuth) return;

      dispatch({ type: ASSESSMENT_HISTORY_REQUEST });

      const query = window.encodeURIComponent(
        `index=${options.pageIndex + 1}&size=${options.pageSize}`
      );
      const url = `${API_ASSESSMENT_HISTORY}?${query}`;
      const method = 'POST';
      const data = searchParams;

      const res = await axios({ url, method, data });
      const resData = res.data || {};
      const assessmentHistoryData = resData?.data;
      const {
        patents,
        patentCount: totalCount,
        count,
        completeCount,
        rank,
      } = assessmentHistoryData;
      const countInfo = {
        assessmentTotalCount: count?.patent_count || 0,
        secondTotalCount: count?.second_count || 0,
        secondCompleteCount: completeCount?.second_count || 0,
      };
      const rankInfo = {
        totalAvg: Math.round(rank?.total_avg || 0),
        userAvg: Math.round(rank?.user_avg || 0),
      };
      console.log('getAssessmentHistory() - resData', resData);

      dispatch({
        type: ASSESSMENT_HISTORY_SUCCESS,
        payload: { patents, totalCount, countInfo, rankInfo },
      });
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: ASSESSMENT_HISTORY_FAILURE,
        payload:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.message,
      });
    }
  };

export const resetAgree = () => ({ type: ASSESSMENT_AGREE_RESET });

export const resetSurvey = () => ({ type: ASSESSMENT_SURVEY_RESET });

export const resetSubmit = () => ({ type: ASSESSMENT_SUBMIT_RESET });

export const resetAssessmentHistory = () => ({
  type: ASSESSMENT_HISTORY_RESET,
});

export const clearAgreeError = () => (dispatch) => {
  dispatch({ type: ASSESSMENT_AGREE_CLEAR_ERROR });
};
