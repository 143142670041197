import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav, NavDropdown } from 'react-bootstrap';
import {
  showAlert,
  showMenu,
  hideMenu,
} from '../../redux/actions/globalActions';
import { logout } from '../../redux/actions/userActions';

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userInfo, isLogin } = useSelector((state) => state.user);
  const { popupMessageInfo } = useSelector((state) => state.popupMessage);
  const { isShow: isShowMenu } = useSelector((state) => state.menu);

  const toggleMenu = () => {
    if (isShowMenu) closeMenu();
    else dispatch(showMenu());
  };

  const closeMenu = () => {
    dispatch(hideMenu());
  };

  const logoutHandler = async () => {
    await dispatch(logout());
    history.push('/');
    closeMenu();
  };

  const checkGrantToMove = (path) => {
    closeMenu();

    const { role } = userInfo;
    if (role === 'ROLE_EMPTY') {
      dispatch(showAlert({ message: '정회원만 이용 가능한 서비스 입니다.' }));
    } else {
      history.push(path);
    }
  };

  return (
    <div id="header">
      <div className="header-brand">
        <Link to="/" onClick={closeMenu}>
          <div className="header-logo"></div>
        </Link>
      </div>

      <div className="header-nav-hamburger" onClick={toggleMenu} />

      <div className={`header-nav ${isShowMenu ? 'show-menu' : 'hide-menu'}`}>
        <div className="header-nav-close" onClick={closeMenu} />

        <div className="header-nav-wrap">
          <div className="header-menu">
            <NavDropdown className="nav-menu" title="소개">
              <LinkContainer to="/introduction/assessment" onClick={closeMenu}>
                <NavDropdown.Item>평가시스템 소개</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/introduction/service" onClick={closeMenu}>
                <NavDropdown.Item>이용안내</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            <NavDropdown className="nav-menu" title="특허평가">
              <LinkContainer to="/assessment/first" onClick={closeMenu}>
                <NavDropdown.Item>평가 대상 특허문헌</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/assessment/second" onClick={closeMenu}>
                <NavDropdown.Item>2차 특허문헌평가 대상</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            <NavDropdown className="nav-menu" title="고객센터">
              <LinkContainer to="/customer/notice" onClick={closeMenu}>
                <NavDropdown.Item>공지사항</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/customer/faq" onClick={closeMenu}>
                <NavDropdown.Item>FAQ</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            <div className="nav-menu">
              <LinkContainer to="/customer/inquiry" onClick={closeMenu}>
                <Nav.Link>문의하기</Nav.Link>
              </LinkContainer>
            </div>

            <div className="nav-menu nav-user">
              {isLogin ? (
                <>
                  <div
                    className={`nav-user-info ${
                      popupMessageInfo?.secondCount > 0
                        ? 'nav-user-info-link'
                        : ''
                    }`}
                    onClick={() => {
                      if (popupMessageInfo?.secondCount > 0)
                        history.push('/assessment/second');
                    }}
                  >
                    <div className="nav-user-info-count">
                      {popupMessageInfo?.secondCount || 0}
                    </div>
                  </div>

                  <NavDropdown
                    className="btn-user-menu"
                    title={`${userInfo.name} 님`}
                  >
                    <LinkContainer to="/mypage/info" onClick={closeMenu}>
                      <NavDropdown.Item>내 정보 관리</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item
                      onClick={() => checkGrantToMove('/mypage/assessment')}
                    >
                      내 평가 관리
                    </NavDropdown.Item>

                    <NavDropdown.Item onClick={logoutHandler}>
                      로그아웃
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <LinkContainer to="/login" onClick={closeMenu}>
                  <button className="btn-confirm btn-login">로그인</button>
                </LinkContainer>
              )}
            </div>
          </div>

          <div className="header-user"></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
