import React from 'react';
// import { Spinner } from 'react-bootstrap';

const Loading = () => {
  return (
    <div className="loading-wrap">
      <div className="loading"></div>
    </div>
  );
};

export default Loading;
