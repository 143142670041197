import {
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_RESET,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_FAILURE,
  FILE_DELETE_RESET,
  FILE_CLEAR_ERROR,
} from '../types/fileTypes';

export const fileUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case FILE_UPLOAD_REQUEST:
      return { ...state, error: '', isLoading: true, isSuccess: false };

    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        error: '',
        fileData: action.payload.fileData,
        isLoading: false,
        isSuccess: true,
      };

    case FILE_UPLOAD_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    case FILE_UPLOAD_RESET:
      return {
        ...state,
        fileData: null,
        error: '',
        isLoading: false,
        isSuccess: false,
      };

    case FILE_CLEAR_ERROR:
      return { ...state, error: '' };

    default:
      return { ...state };
  }
};

export const fileDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FILE_DELETE_REQUEST:
      return { ...state, error: '', isLoading: true, isSuccess: false };

    case FILE_DELETE_SUCCESS:
      return {
        ...state,
        error: '',
        isLoading: false,
        isSuccess: true,
      };

    case FILE_DELETE_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    case FILE_DELETE_RESET:
      return {
        ...state,
        error: '',
        isLoading: false,
        isSuccess: false,
      };

    case FILE_CLEAR_ERROR:
      return { ...state, error: '' };

    default:
      return { ...state };
  }
};
