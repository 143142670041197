import axios from 'axios';
import {
  FIELD_CODE_INFO_REQUEST,
  FIELD_CODE_INFO_SUCCESS,
  FIELD_CODE_INFO_FAILURE,
  TERMS_AND_PRIVACY_REQUEST,
  TERMS_AND_PRIVACY_SUCCESS,
  TERMS_AND_PRIVACY_FAILURE,
  SHOW_MENU,
  HIDE_MENU,
  SHOW_ALERT,
  HIDE_ALERT,
  SHOW_CONFIRM,
  HIDE_CONFIRM,
  SHOW_TOAST,
  HIDE_TOAST,
} from '../types/globalTypes';
import {
  API_GET_FIELD_CODE_INFO,
  API_TERMS_AND_PRIVACY,
} from '../../constants/apiConstants';

export const setCodeInfo = () => async (dispatch) => {
  try {
    dispatch({ type: FIELD_CODE_INFO_REQUEST });

    const url = API_GET_FIELD_CODE_INFO;
    const method = 'GET';

    const res = await axios({ url, method });
    const resData = res.data || {};
    const fieldCodeData = resData.data;
    console.log('setCodeInfo() - resData', resData);

    // Code 데이터
    const classInfo = {};
    const fieldInfo = {};
    const codeInfo = fieldCodeData;
    Object.keys(fieldCodeData).forEach((data) => {
      if (data === 'EMPTY') return;

      const field = fieldCodeData[data];
      field['code'] = data;

      // 회원 가입 페이지 용 Field Codes 정리
      const { sector_code: sectorCode, sector_kr } = field;
      const sectorName = sector_kr.trim();
      const fields = fieldInfo[sectorName] || [];
      fields.push(field);
      fieldInfo[sectorName] = fields;

      // Class 정보
      const classFields = classInfo[sectorCode]?.fields || [];
      classFields.push(field);
      classInfo[sectorCode] = {
        name: sectorName,
        fields: classFields,
      };
    });

    // fieldInfo 에서, 기타 항목이 있다면, 제일 마지막으로 이동
    const classNames = Object.keys(fieldInfo);
    const isEtcExist = classNames.includes('기타');
    if (isEtcExist) {
      const etcInfo = fieldInfo['기타'];
      delete fieldInfo['기타'];
      fieldInfo['기타'] = etcInfo;
    }

    dispatch({
      type: FIELD_CODE_INFO_SUCCESS,
      payload: { classInfo, fieldInfo, codeInfo },
    });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: FIELD_CODE_INFO_FAILURE,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const setTermsAndPrivacy = () => async (dispatch) => {
  try {
    dispatch({ type: TERMS_AND_PRIVACY_REQUEST });

    // terms: 'U', // U("이용약관")
    // privacy: 'C', // C("개인정보약관")
    const termsAndPrivacyTypes = ['U', 'C'];
    const fetches = termsAndPrivacyTypes.map((type) => {
      const query = window.encodeURIComponent(`type=${type}`);
      const url = `${API_TERMS_AND_PRIVACY}?${query}`;
      const method = 'GET';

      return axios({ url, method });
    });

    const responses = await Promise.all(fetches);
    const termsRes = responses[0];
    const privacyRes = responses[1];

    const termsResData = termsRes.data || {};
    const privacyResData = privacyRes.data || {};
    const terms = termsResData.data;
    const privacy = privacyResData.data;
    console.log('setTermsAndPrivacy() - termsRes', termsRes);
    console.log('setTermsAndPrivacy() - privacyRes', privacyRes);

    dispatch({
      type: TERMS_AND_PRIVACY_SUCCESS,
      payload: { terms, privacy },
    });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: TERMS_AND_PRIVACY_FAILURE,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const showMenu = () => (dispatch) => {
  dispatch({ type: SHOW_MENU });
};

export const hideMenu = () => (dispatch) => {
  dispatch({ type: HIDE_MENU });
};

export const showAlert = (options) => (dispatch) => {
  dispatch({ type: SHOW_ALERT, payload: options });
};

export const hideAlert = () => (dispatch) => {
  dispatch({ type: HIDE_ALERT });
};

export const showConfirm = (options) => (dispatch) => {
  dispatch({ type: SHOW_CONFIRM, payload: options });
};

export const hideConfirm = () => (dispatch) => {
  dispatch({ type: HIDE_CONFIRM });
};

export const showToast =
  (options, duration = 2000) =>
  (dispatch) => {
    dispatch({
      type: SHOW_TOAST,
      payload: { ...options, duration },
    });
  };

export const hideToast = () => (dispatch) => {
  dispatch({ type: HIDE_TOAST });
};
