import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiSearchAlt2 } from 'react-icons/bi';
import { CgChevronDown } from 'react-icons/cg';
import parse from 'html-react-parser';
import Indicator from '../../layouts/Indicator';
import Footer from '../../layouts/Footer';
import Loading from '../../common/Loading';
import Message from '../../common/Message';
import Pagination from '../../common/Pagination';
import { getFaqList } from '../../../redux/actions/customerActions';
import {
  BOARD_TYPE_INFO,
  BOARD_SIZE_INFO,
  FAQ_TYPE_INFO,
} from '../../../constants/dataConstants';

const CustomerFaq = ({ history }) => {
  const boardType = 'faq';
  const pageSize = BOARD_SIZE_INFO[boardType];
  const faqTypes = ['전체', ...Object.values(FAQ_TYPE_INFO)];

  const dispatch = useDispatch();
  const { faqs, totalCount, error, isLoading } = useSelector(
    (state) => state.faqList
  );
  const { isAuthenticated } = useSelector((state) => state.user);

  const [faqType, setFaqType] = useState('전체');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('keyword');
  const [params, setParams] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [openRows, setOpenRows] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      console.log('CustomerFaq - isAuthenticated', isAuthenticated);
    } else {
      history.push('/login?redirect=/customer/faq');
    }
  }, []);

  useEffect(() => {
    if (isLoaded) changeFaqs();
  }, [faqType]);

  useEffect(() => {
    clearOpen();
  }, [faqs]);

  const setFaqList = (searchParams = params) => {
    searchParams['type'] = BOARD_TYPE_INFO[boardType];
    console.log('setFaqList() - searchParams', searchParams);
    dispatch(getFaqList({ pageSize, pageIndex }, searchParams));
    setIsLoaded(true);
  };

  const changeFaqs = () => {
    const newParams = { ...params };
    const subType = getSubType(faqType);
    if (subType) newParams['subType'] = subType;

    setFaqList(newParams);
  };

  const getSubType = (faqType) => {
    return Object.keys(FAQ_TYPE_INFO).find(
      (key) => FAQ_TYPE_INFO[key] === faqType
    );
  };

  const handleOpen = (uniqueId) => {
    let newOpenRows = [...openRows];
    const isOpened = newOpenRows.includes(uniqueId);
    if (isOpened) {
      newOpenRows = newOpenRows.filter((openRow) => openRow !== uniqueId);
    } else {
      newOpenRows.push(uniqueId);
    }

    setOpenRows(newOpenRows);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      findFaq();
    }
  };

  const findFaq = () => {
    const newParams = {};
    const subType = getSubType(faqType);
    if (searchTerm !== '') newParams[searchType] = searchTerm;
    if (subType) newParams['subType'] = subType;

    setParams(newParams);
    setFaqList(newParams);
  };

  const clearOpen = () => {
    // // 변경된 FAQ 리스트에 없는 오픈된 FAQ는 닫기
    // const seqs = faqs.map((faq) => faq.seq);
    // const newOpenRows = openRows.filter((openRow) => seqs.includes(openRow));
    // setOpenRows(newOpenRows);

    // 오픈된 FAQ 전체 초기화
    setOpenRows([]);
  };

  return (
    <div className="customer">
      <div className="customer-wrap">
        <Indicator />

        {isLoading && <Loading />}
        {error && <Message variant="danger">{error}</Message>}

        <div className="customer-faq">
          <div className="customer-faq-header">
            <div className="customer-faq-type">
              {faqTypes.map((type, index) => (
                <div
                  className={`customer-faq-type-item ${
                    faqType === type ? 'active' : ''
                  }`}
                  key={index}
                  onClick={() => setFaqType(type)}
                >
                  {type}
                </div>
              ))}
            </div>

            <div className="customer-faq-search">
              <select
                name=""
                id="keywordCategory"
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
              >
                <option value="keyword">전체</option>
                <option value="title">제목</option>
                <option value="userName">작성자</option>
              </select>

              <input
                type="text"
                className="searchInput"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <BiSearchAlt2
                className="customer-faq-search-button"
                onClick={findFaq}
              />
            </div>
          </div>

          <div className="customer-faq-body">
            <table className="customer-faq-post-wrap">
              <tbody>
                {faqs.map((faq, index) => (
                  <Fragment key={index}>
                    <tr
                      className="customer-faq-post-item"
                      onClick={() => handleOpen(faq?.seq)}
                    >
                      <td className="customer-faq-post-mark">Q</td>

                      <td className="customer-faq-post-title">
                        [{FAQ_TYPE_INFO[faq?.subType]}] {faq?.title}
                      </td>

                      <td className="customer-faq-post-more">
                        <CgChevronDown
                          className={`customer-faq-post-more-icon ${
                            openRows.includes(faq?.seq) && 'more-open'
                          }`}
                        />
                      </td>
                    </tr>

                    {openRows.includes(faq?.seq) && (
                      <tr className="customer-faq-post-content">
                        <td className="customer-faq-post-mark">A</td>
                        <td className="customer-faq-post-content" colSpan="2">
                          {parse(faq?.html || '')}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>

          <div className="customer-faq-footer">
            <Pagination
              totalCount={totalCount}
              pageSize={pageSize}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              renewPage={setFaqList}
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CustomerFaq;
