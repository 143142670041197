import axios from 'axios';
import {
  NOTICE_LIST_REQUEST,
  NOTICE_LIST_SUCCESS,
  NOTICE_LIST_FAILURE,
  NOTICE_LIST_RESET,
  NOTICE_DETAIL_REQUEST,
  NOTICE_DETAIL_SUCCESS,
  NOTICE_DETAIL_FAILURE,
  NOTICE_DETAIL_RESET,
  FAQ_LIST_REQUEST,
  FAQ_LIST_SUCCESS,
  FAQ_LIST_FAILURE,
  FAQ_LIST_RESET,
  INQUIRY_CREATE_REQUEST,
  INQUIRY_CREATE_SUCCESS,
  INQUIRY_CREATE_FAILURE,
  INQUIRY_CREATE_RESET,
  CUSTOMER_CLEAR_ERROR,
} from '../types/customerTypes';
import {
  API_BOARD_LIST,
  API_BOARD_DETAIL,
  API_BOARD_CREATE,
} from '../../constants/apiConstants';
import { checkAuth } from '../middlewares/checkAuth';

export const getNoticeList =
  (options = { pageIndex: 0, pageSize: 10 }, searchParams = {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: NOTICE_LIST_REQUEST });

      const query = window.encodeURIComponent(
        `index=${options.pageIndex + 1}&size=${options.pageSize}`
      );
      const url = `${API_BOARD_LIST}?${query}`;
      const method = 'POST';
      const data = { ...searchParams, viewFlag: true };

      const res = await axios({ url, method, data });
      const resData = res.data || {};
      const noticeListData = resData.data;
      const { data: notices, totalCount } = noticeListData;
      console.log('getNoticeList() - resData', resData);

      dispatch({ type: NOTICE_LIST_SUCCESS, payload: { notices, totalCount } });
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: NOTICE_LIST_FAILURE,
        payload:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.message,
      });
    }
  };

export const getNoticeDetail = (seq) => async (dispatch, getState) => {
  try {
    const isAuth = checkAuth(dispatch, getState);
    if (!isAuth) return;

    dispatch({ type: NOTICE_DETAIL_REQUEST });

    const query = window.encodeURIComponent(`seq=${seq}`);
    const url = `${API_BOARD_DETAIL}?${query}`;
    const method = 'GET';

    const res = await axios({ url, method });
    const resData = res.data || {};
    const notice = resData.data;
    console.log('getNoticeDetail() - resData', resData);

    dispatch({
      type: NOTICE_DETAIL_SUCCESS,
      payload: { notice },
    });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: NOTICE_DETAIL_FAILURE,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const getFaqList =
  (options = { pageIndex: 0, pageSize: 10 }, searchParams = {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: FAQ_LIST_REQUEST });

      const query = window.encodeURIComponent(
        `index=${options.pageIndex + 1}&size=${options.pageSize}`
      );
      const url = `${API_BOARD_LIST}?${query}`;
      const method = 'POST';
      const data = { ...searchParams, viewFlag: true };

      const res = await axios({ url, method, data });
      const resData = res.data || {};
      const faqListData = resData.data;
      const { data: faqs, totalCount } = faqListData;
      console.log('getFaqList() - resData', resData);

      dispatch({ type: FAQ_LIST_SUCCESS, payload: { faqs, totalCount } });
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: FAQ_LIST_FAILURE,
        payload:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.message,
      });
    }
  };

export const createInquiry = (data) => async (dispatch, getState) => {
  try {
    const isAuth = checkAuth(dispatch, getState);
    if (!isAuth) return;

    dispatch({ type: INQUIRY_CREATE_REQUEST });

    const url = API_BOARD_CREATE;
    const method = 'POST';

    const res = await axios({ url, method, data });
    const resData = res.data || {};
    console.log('createInquiry() - resData', resData);

    dispatch({ type: INQUIRY_CREATE_SUCCESS });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: INQUIRY_CREATE_FAILURE,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const resetNoticeList = () => ({
  type: NOTICE_LIST_RESET,
});

export const resetNoticeDetail = () => ({
  type: NOTICE_DETAIL_RESET,
});

export const resetFaqList = () => ({
  type: FAQ_LIST_RESET,
});

export const resetCreateInquiry = () => ({
  type: INQUIRY_CREATE_RESET,
});

export const clearError = () => ({
  type: CUSTOMER_CLEAR_ERROR,
});
