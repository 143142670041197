import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Message from '../../common/Message';
import Loading from '../../common/Loading';
import FindCompany from '../signUp/FindCompany';
import { signUp } from '../../../redux/actions/userActions';
import {
  uploadFile,
  resetFileUpload,
} from '../../../redux/actions/fileActions';
import { showToast } from '../../../redux/actions/globalActions';
import {
  SIGN_UP_EDUCATION_LEVELS,
  SIGN_UP_EDUCATION_STATUS,
} from '../../../constants/dataConstants';
import { API_ID_CHECK } from '../../../constants/apiConstants';
import utils from '../../../modules/utils';

const Register = ({ history, agree, goNext }) => {
  const dispatch = useDispatch();

  const { fieldInfo } = useSelector((state) => state.code);
  const { error, isLoading, isSuccess } = useSelector((state) => state.signUp);
  const {
    error: errorUpload,
    isLoading: isLoadingUpload,
    isSuccess: isSuccessUpload,
  } = useSelector((state) => state.fileUpload);

  const [fileInfo, setFileInfo] = useState(null);
  const [companyInfo, setCompanyInfo] = useState({ id: '', name: '' });
  const [editInfo, setEditInfo] = useState({});
  const [messageInfo, setMessageInfo] = useState({});
  const [idExistMessage, setIdExistMessage] = useState('');
  const [isShowFindCompany, setIsShowFindCompany] = useState(false);

  const years = Array.from(
    { length: new Date().getFullYear() - 1950 },
    (v, i) => i + 1950 + 1
  ).reverse();

  useEffect(() => {
    if (isSuccess) handleSuccess();
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessUpload) handleUploadSuccess();
    else if (errorUpload) {
      dispatch(
        showToast({
          message: '건강보험득실확인서 업로드에 실패했습니다.',
        })
      );
    }
  }, [isLoadingUpload, isSuccessUpload]);

  const showFindCompany = () => {
    setIsShowFindCompany(true);
  };

  const handleSuccess = () => {
    dispatch(
      showToast({
        message: '회원가입에 성공하였습니다.',
      })
    );

    setMessageInfo({});
    dispatch(resetFileUpload());
    goNext();
  };

  const handleUploadSuccess = () => {
    console.log('handleUploadSuccess() - editInfo (userInfo)', editInfo);
    dispatch(signUp(editInfo));
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;

    editInfo[name] = value;
    clearMessage(name);
  };

  const changeCompanyInfo = (newCompanyInfo) => {
    setCompanyInfo(newCompanyInfo);
    handleChange({ target: { name: 'companyId', value: newCompanyInfo.id } });
  };

  const changeEducationInfo = (key, value) => {
    const newEducationInfo = { ...editInfo.educationInfo };
    newEducationInfo[key] = value;
    handleChange({
      target: { name: 'educationInfo', value: newEducationInfo },
    });
  };

  const changeCode = (selectedCode, isChecked) => {
    const codes = [...(editInfo?.code || [])];

    if (isChecked) {
      if (codes.length < 3) {
        const newCodes = [...codes, selectedCode];
        handleChange({ target: { name: 'code', value: newCodes } });
      } else {
        dispatch(
          showToast({ message: '기술분야는 최대 3개까지 선택 가능합니다.' })
        );
      }
    } else {
      const newCodes = codes.filter((code) => code !== selectedCode);
      handleChange({ target: { name: 'code', value: newCodes } });
    }
  };

  const changeFileInfo = ({ target }) => {
    const files = target.files;
    const file = files[0];

    if (file) {
      setFileInfo(file);
    }
  };

  const clearMessage = (key) => {
    const newMessageInfo = { ...messageInfo };
    delete newMessageInfo[key];
    setMessageInfo(newMessageInfo);
  };

  // 정보 Validation
  const checkValid = () => {
    const {
      id,
      password,
      passwordConfirm,
      email,
      name,
      mobile,
      birthDate,
      companyId,
      code,
    } = editInfo;
    const validationInfo = {};

    if (!/^[가-힣]{2,24}$/.test(name))
      validationInfo['name'] = '이름을 다시 확인해 주세요.';

    if (utils.isEmptyString(id) || !/^[a-zA-Z0-9]{6,24}$/.test(id))
      validationInfo['id'] = 'id을 다시 확인해 주세요.';

    if (
      utils.isEmptyString(password) ||
      !/^(?=.*[a-zA-Z0-9~!@$#^]).{8,12}$/.test(password)
    )
      validationInfo['password'] = '비밀번호를 다시 확인해 주세요.';

    if (
      utils.isEmptyString(passwordConfirm) ||
      !/^(?=.*[a-zA-Z0-9~!@$#^]).{8,12}$/.test(passwordConfirm)
    )
      validationInfo['passwordConfirm'] =
        '비밀번호 재입력을 다시 확인해 주세요.';
    else if (password !== passwordConfirm)
      validationInfo['passwordConfirm'] = '비밀번호가 일치하지 않습니다.';

    if (!/^[0]{1}[1]{1}[0-9]{1}\d{3,4}\d{4}$/.test(mobile))
      validationInfo['mobile'] = '휴대폰 번호를 다시 확인해 주세요.';

    if (
      !/^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(
        birthDate
      )
    )
      validationInfo['birthDate'] = '생년월일을 다시 확인해 주세요.';

    if (!/.+@.+\.[a-z]+/.test(email) || /\s/.test(email))
      validationInfo['email'] = '이메일 주소를 다시 확인해 주세요.';

    if (!companyId)
      validationInfo['companyId'] = '소속회사를 다시 확인해 주세요.';

    if (utils.isNull(code) || code?.length <= 0)
      validationInfo['code'] = '기술 분야 하나 이상을 선택해 주세요';

    setMessageInfo(validationInfo);
    const isValid = Object.keys(validationInfo).length === 0;
    return isValid;
  };

  const saveMyInfo = () => {
    const isValid = checkValid();

    if (isValid) {
      const params = { ...editInfo, agree, role: 'ROLE_EMPTY' };
      const isFileInfoChanged = !utils.isNull(fileInfo);

      // 파일이 추가 된 경우, 우선 파일 전송 해서 완료 된 후
      // (isSuccessUpload가 true로 변경 되었을 때)
      // 회원 가입 실행
      // - 파일 업로드가 실패 했을 경우에는 회원가입 진행을 시키지 않기 위하여
      if (isFileInfoChanged) {
        console.log('saveMyInfo() - fileInfo (fileUpload)', fileInfo);
        sendFile(fileInfo);
      } else {
        console.log('saveMyInfo() - params (userInfo)', params);
        // dispatch(updateUser(params));
        dispatch(signUp(params));
      }
    } else {
      dispatch(
        showToast({
          message: '입력된 정보를 다시 확인해 주세요.',
        })
      );
    }
  };

  const sendFile = async (file) => {
    const fileType = 'USER';
    const fileBlob = await utils.getFileBlob(file);
    const filename = file.name;
    dispatch(uploadFile(fileType, fileBlob, filename, editInfo.id));
  };

  const browseFile = () => {
    const fileWrap = document.querySelector('.register-file-input');
    if (fileWrap) fileWrap.click();
  };

  const checkIdExist = async (userId) => {
    try {
      const url = `${API_ID_CHECK}?userId=${userId}`;
      const method = 'POST';
      const res = await axios({ url, method });
      const resData = res.data;
      const isIdExist = resData.data === 'duplicate';

      if (isIdExist) {
        setIdExistMessage('이미 존재하는 아이디 입니다.');
      } else {
        setIdExistMessage('');
      }
    } catch (err) {
      console.log('err', err);
      setIdExistMessage('아이디 중복 검사에 실패했습니다.');
    }
  };

  return (
    <div className="register">
      <div className="register-title">회원정보 입력</div>

      <div className="register-form-wrap">
        {(isLoading || isLoadingUpload) && (
          <>
            <Loading />
            {/* CSS Style 에서, 짝수에 걸린 margin 속성으로 인해, 빈 div 추가 */}
            <div />
          </>
        )}
        {error && (
          <>
            <Message variant="danger">{error}</Message>
            {/* CSS Style 에서, 짝수에 걸린 margin 속성으로 인해, 빈 div 추가 */}
            <div />
          </>
        )}
        {errorUpload && (
          <>
            <Message variant="danger">{errorUpload}</Message>
            {/* CSS Style 에서, 짝수에 걸린 margin 속성으로 인해, 빈 div 추가 */}
            <div />
          </>
        )}
        {isShowFindCompany && (
          <>
            <FindCompany
              changeCompanyInfo={changeCompanyInfo}
              setIsShowFindCompany={setIsShowFindCompany}
            />
            {/* CSS Style 에서, 짝수에 걸린 margin 속성으로 인해, 빈 div 추가 */}
            <div />
          </>
        )}

        <div
          className={`register-form-item ${messageInfo?.name ? 'error' : ''}`}
        >
          <label htmlFor="name">이름</label>

          <div className="form-input-wrap required">
            <input
              id="name"
              name="name"
              type="text"
              placeholder="이름을 입력해주세요."
              value={editInfo?.name}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className={`register-form-item ${messageInfo?.id ? 'error' : ''}`}>
          <label htmlFor="id">아이디</label>
          <div className="form-input-wrap required">
            <input
              id="id"
              name="id"
              type="text"
              placeholder="아이디를 입력해주세요."
              value={editInfo?.id}
              onKeyUp={(e) => checkIdExist(e.target.value)}
              onChange={handleChange}
            />
          </div>
          <small>
            * 2자 이상의 영문, 숫자, 특수문자를 사용하여 설정해주세요.
          </small>

          {idExistMessage && (
            <Message variant="danger">{idExistMessage}</Message>
          )}
        </div>

        <div
          className={`register-form-item ${
            messageInfo?.password ? 'error' : ''
          }`}
        >
          <label htmlFor="password">비밀번호</label>
          <div className="form-input-wrap required">
            <input
              id="password"
              name="password"
              type="password"
              placeholder="비밀번호를 입력해주세요."
              value={editInfo?.password}
              onChange={handleChange}
            />
          </div>
          <small>
            * 8~32자의 영문 대소문자, 숫자, 특수문자를 조합하여 설정해주세요
          </small>

          <br />
          <small>
            * 안전한 계정 사용을 위해 비밀번호는 주기적으로 변경해주세요.
          </small>
        </div>

        <div
          className={`register-form-item ${
            messageInfo?.passwordConfirm ? 'error' : ''
          }`}
        >
          <label htmlFor="passwordConfirm">비밀번호 확인</label>
          <div className="form-input-wrap required">
            <input
              id="passwordConfirm"
              name="passwordConfirm"
              type="password"
              placeholder="비밀번호를 한번 더 입력해주세요."
              value={editInfo?.passwordConfirm}
              onChange={handleChange}
            />
          </div>
        </div>

        <div
          className={`register-form-item ${messageInfo?.mobile ? 'error' : ''}`}
        >
          <label htmlFor="mobile">휴대폰 번호</label>
          <div className="form-input-wrap required">
            <input
              id="mobile"
              name="mobile"
              type="number"
              placeholder="휴대폰 번호를 입력해주세요."
              value={editInfo?.mobile}
              onChange={handleChange}
            />
          </div>
        </div>

        <div
          className={`register-form-item ${
            messageInfo?.birthDate ? 'error' : ''
          }`}
        >
          <label htmlFor="birthDate">생년월일</label>
          <div className="form-input-wrap required">
            <input
              id="birthDate"
              name="birthDate"
              type="text"
              placeholder="생년월일을 입력해주세요."
              value={editInfo?.birthDate}
              onChange={handleChange}
            />
          </div>

          <small>* YYYY-MM-DD 형식으로 입력해주세요. 예) 1990-12-31</small>
        </div>

        <div
          className={`register-form-item ${messageInfo?.email ? 'error' : ''}`}
        >
          <label htmlFor="email">이메일</label>
          <div className="form-input-wrap required">
            <input
              id="email"
              name="email"
              type="text"
              placeholder="이메일을 입력해주세요."
              value={editInfo?.email}
              onChange={handleChange}
            />
          </div>

          <small>* 입력한 이메일로 인증메일이 발송됩니다.</small>

          <br />
          <small>
            * 회사 이메일 또는 실제 사용중인 이메일 주소를 입력해주세요.
          </small>
        </div>

        <div
          className={`register-form-item ${
            messageInfo?.companyId ? 'error' : ''
          }`}
        >
          <label htmlFor="companyId">소속회사</label>
          <div className="form-input-wrap required">
            <input
              id="companyId"
              name="companyId"
              type="text"
              placeholder="소속회사를 입력해주세요."
              value={companyInfo?.name || ''}
              onFocus={() => showFindCompany()}
            />
          </div>
        </div>

        <div className="register-form-item register-form-eduction">
          <label>학력/전공</label>

          <div className="school-info">
            <select
              className="graduate-date-input"
              name="educationInfo.graduateDate"
              value={editInfo?.educationInfo?.graduateDate}
              onChange={(e) =>
                changeEducationInfo('graduateDate', e.target.value)
              }
            >
              <option value="" disabled selected>
                졸업연도
              </option>

              {years.map((year, index) => (
                <option value={year} key={index}>
                  {year}
                </option>
              ))}
            </select>

            <input
              className="school-name-input"
              name="educationInfo.educationName"
              type="text"
              placeholder="학교명"
              value={editInfo?.educationInfo?.educationName}
              onChange={(e) =>
                changeEducationInfo('educationName', e.target.value)
              }
            />
          </div>

          <div className="study-info">
            <div className="major-wrap">
              <input
                className="major-input"
                name="educationInfo.major"
                type="text"
                placeholder="전공을 입력해주세요."
                value={editInfo?.educationInfo?.major}
                onChange={(e) => changeEducationInfo('major', e.target.value)}
              />

              <input
                className="second-major-input"
                name="educationInfo.subMajor"
                type="text"
                placeholder="부전공을 입력해주세요."
                value={editInfo?.educationInfo?.subMajor}
                onChange={(e) =>
                  changeEducationInfo('subMajor', e.target.value)
                }
              />
            </div>

            <div className="degree-wrap">
              <select
                className="degree-input"
                name="educationInfo.level"
                value={editInfo?.educationInfo?.level}
                onChange={(e) => changeEducationInfo('level', e.target.value)}
              >
                <option value="" disabled selected>
                  학위
                </option>

                {SIGN_UP_EDUCATION_LEVELS.map((level, index) => (
                  <option value={level.value} key={index}>
                    {level.name}
                  </option>
                ))}
              </select>

              <select
                className="graduate-status-input"
                name="educationInfo.status"
                value={editInfo?.educationInfo?.status}
                onChange={(e) => changeEducationInfo('status', e.target.value)}
              >
                <option value="" disabled selected>
                  수료 상태
                </option>

                {SIGN_UP_EDUCATION_STATUS.map((status, index) => (
                  <option value={status.value} key={index}>
                    {status.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="register-form-item register-form-career">
          <label htmlFor="careerInfo">경력사항</label>
          <textarea
            id="careerInfo"
            name="careerInfo"
            placeholder="경력사항을 입력해주세요."
            value={editInfo?.careerInfo}
            onChange={handleChange}
          />
        </div>

        <>
          <div className="register-form-item register-form-file">
            <label htmlFor="careerInfo">건강보험득실확인서</label>

            <input
              id="file"
              name="file"
              className="register-file-input"
              type="file"
              onChange={changeFileInfo}
            ></input>

            <input
              className="register-file-name"
              value={fileInfo?.name || ''}
              onClick={browseFile}
              readOnly
            ></input>

            <button className="register-file-btn" onClick={browseFile}>
              파일 찾기
            </button>
          </div>

          {/* CSS Style 에서, 짝수에 걸린 margin 속성으로 인해, 빈 div 추가 */}
          <div></div>
        </>

        <div
          className={`register-form-item register-form-field ${
            messageInfo?.code ? 'error' : ''
          }`}
        >
          <label>기술분야 (최대 3개)</label>

          <div className="field-wrap form-input-wrap required">
            {Object.keys(fieldInfo).map((class1Name, class1Index) => (
              <div className="field-info" key={class1Index}>
                <div className="field-title">{class1Name}</div>

                <div className="field-list">
                  {fieldInfo[class1Name].map((field, codeIndex) => (
                    <div className="field-item" key={codeIndex}>
                      <input
                        type="checkbox"
                        id={`field-${field.code}`}
                        checked={editInfo?.code?.includes(field.code)}
                        onChange={(e) =>
                          changeCode(field.code, e.target.checked)
                        }
                      />
                      <label htmlFor={`field-${field.code}`}>
                        {field.field_kr}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <div className="field-note">
              <small>* 본인의 전문분야를 최대 3개 선택해주세요.</small>
              <br />
              <small>
                * 선택한 전문분야에 맞는 특허문헌 평가 목록을 자동으로 매칭해
                추천드립니다.
              </small>
            </div>
          </div>
        </div>

        <div className="register-btn-wrap">
          <button className="btn-cancel" onClick={saveMyInfo}>
            저장하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
