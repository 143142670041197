export const USER_ROLE_NAME_INFO = {
  ROLE_ADMIN: '일반 관리자',
  ROLE_GRANT: '권한자',
  ROLE_CLIENT: '정회원',
  ROLE_EMPTY: '준회원',
  ROLE_WITH: '탈퇴',
};

export const SIGN_UP_EDUCATION_LEVELS = [
  {
    name: '학사',
    value: 'N',
  },
  {
    name: '석사',
    value: 'U',
  },
  {
    name: '박사',
    value: 'M',
  },
  // {
  //   name: '',
  //   value: 'H',
  // },
  // {
  //   name: '',
  //   value: 'S',
  // },
];

export const SIGN_UP_EDUCATION_STATUS = [
  {
    name: '졸업',
    value: 'G',
  },
  {
    name: '수료',
    value: 'S',
  },
  {
    name: '중퇴',
    value: 'U',
  },
];

export const SURVEY_STEPS = [
  {
    type: 'I',
    name: '권리의 요소',
  },
  {
    type: 'V',
    name: '권리의 유효성',
  },
  {
    type: 'G',
    name: '권리의 광협',
  },
  {
    type: 'P',
    name: '권리의 보호강도',
  },
  {
    type: 'T',
    name: '기술 부합도',
  },
];

export const BOARD_TYPE_INFO = {
  notice: 'N', // N("공지사항")
  user: 'O', // O("일반게시판")
  faq: 'F', // F("FAQ")
  inquiry: 'Q', // Q("문의내역")
  terms: 'U', // U("이용약관")
  privacy: 'C', // C("개인정보약관")
};

export const BOARD_NAME_INFO = {
  notice: '공지사항',
  user: '일반게시판',
  faq: 'FAQ',
  inquiry: '문의내역',
  terms: '이용약관',
  privacy: '개인정보약관',
};

export const ASSESSMENT_PAGE_SIZE = 10;
export const BOARD_SIZE_INFO = {
  home: 4,
  notice: 8,
  faq: 7,
  assessment: 7,
};

export const FAQ_TYPE_INFO = {
  A: '시스템 점검',
  B: '서비스',
};

export const ASSESSMENT_TOOLTIP = {
  4: `쉽게 유사한 특허문헌을 찾는 방법<br />
  키프리스(www.kipris.or.kr)에서의 유사특허,<br />
  google patent 이용 가능(무료)<br />
  키워트의 선행조사용 검색(유료) 가능`,
  13: `쉽게 유사한 특허문헌을 찾는 방법<br />
  키프리스(www.kipris.or.kr)에서의 유사특허,<br />
  google patent 이용 가능(무료)<br />
  키워트의 선행조사용 검색(유료) 가능`,
};

export const FOOTER_RELATIVE_SITES = [
  // {
  //   name: '워트인텔리전스',
  //   url: 'http://wert.co.kr',
  // },
  {
    name: '대한변리사회',
    url: 'http://www.kpaa.or.kr',
  },
];
