import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Indicator from '../../layouts/Indicator';
import Footer from '../../layouts/Footer';
import Loading from '../../common/Loading';
import Message from '../../common/Message';
import {
  createInquiry,
  resetCreateInquiry,
} from '../../../redux/actions/customerActions';
import {
  uploadFile,
  resetFileUpload,
} from '../../../redux/actions/fileActions';
import { showToast } from '../../../redux/actions/globalActions';
import utils from '../../../modules/utils';
import { BOARD_TYPE_INFO } from '../../../constants/dataConstants';

const CustomerInquiry = ({ history }) => {
  const boardType = 'inquiry';

  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);
  const { error, isLoading, isSuccess } = useSelector(
    (state) => state.inquiryCreate
  );
  const {
    fileData,
    error: errorUpload,
    isLoading: isLoadingUpload,
  } = useSelector((state) => state.fileUpload);
  const { isAuthenticated } = useSelector((state) => state.user);

  const [title, setTitle] = useState('');
  const [html, setHtml] = useState('');
  const [fileInfo, setFileInfo] = useState(null);
  const [messageInfo, setMessageInfo] = useState({});

  useEffect(() => {
    if (isAuthenticated) {
      console.log('CustomerInquiry - isAuthenticated', isAuthenticated);
      dispatch(resetFileUpload());
    } else {
      history.push('/login?redirect=/customer/inquiry');
    }
  }, []);

  useEffect(() => {
    if (isSuccess) handleSuccess();
  }, [isSuccess]);

  const handleSuccess = () => {
    dispatch(
      showToast({
        message: '문의사항 업로드에 성공했습니다.',
      })
    );

    setTitle('');
    setHtml('');
    setFileInfo(null);
    setMessageInfo({});
    dispatch(resetCreateInquiry());
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;

    if (name === 'title') {
      setTitle(value);
    } else if (name === 'html') {
      setHtml(value);
    }

    removeErrorMessage(name);
  };

  const changeFileInfo = ({ target }) => {
    const files = target.files;
    const file = files[0];

    if (file) {
      setFileInfo(file);
      sendFile(file);
    }
  };

  // 정보 Validation
  const checkValid = () => {
    const validationInfo = {};
    if (utils.isEmptyString(title)) {
      validationInfo['title'] = '제목을 확인해주세요';
    }

    if (utils.isEmptyString(html)) {
      validationInfo['html'] = '내용을 확인해주세요';
    }

    setMessageInfo(validationInfo);
    const isValid = Object.keys(validationInfo).length === 0;
    return isValid;
  };

  const saveInquiry = async () => {
    const isValidInput = checkValid();

    if (isValidInput) {
      const type = BOARD_TYPE_INFO[boardType];
      const contents = utils.removeWordBreaker(utils.getHtmlText(html));
      const params = {
        type,
        title,
        html,
        contents,
        viewFlag: true, // viewFlag (노출 여부) 기본값 true로 전송
      };
      if (fileData) params['attachId'] = fileData.id;

      console.log('saveInquiry() - params', params);

      dispatch(createInquiry(params));
    } else {
      dispatch(showToast({ message: '입력된 정보를 다시 확인해주세요.' }));
    }
  };

  const sendFile = async (file) => {
    const fileType = 'BOARD';
    const fileBlob = await utils.getFileBlob(file);
    const filename = file.name;
    dispatch(uploadFile(fileType, fileBlob, filename));
  };

  const browseFile = () => {
    const fileWrap = document.querySelector('.customer-inquiry-file-input');
    if (fileWrap) fileWrap.click();
  };

  const removeErrorMessage = (node) => {
    const newMessageInfo = { ...messageInfo };
    delete newMessageInfo[node];
    setMessageInfo(newMessageInfo);
  };

  return (
    <div className="customer">
      <div className="customer-wrap">
        <Indicator />

        {(isLoading || isLoadingUpload) && <Loading />}
        {error && <Message variant="danger">{error}</Message>}
        {errorUpload && <Message variant="danger">{errorUpload}</Message>}

        <div className="customer-inquiry">
          <div className="customer-inquiry-left">
            <div className="customer-inquiry-title">문의하기</div>

            <div className="customer-inquiry-text">
              1:1 문의 서비스입니다.
              <br />
              문의에 대한 답변은 빠른 시일안에
              <br />
              이메일로 발송드리겠습니다.
            </div>
          </div>

          <div className="customer-inquiry-right">
            <div className="customer-inquiry-form">
              <div
                className={`customer-inquiry-form-item ${
                  messageInfo['name'] ? 'error' : ''
                }`}
              >
                <label htmlFor="name">
                  고객명 <small>(필수)</small>
                </label>

                <input
                  id="name"
                  className="customer-inquiry-input"
                  type="text"
                  value={userInfo?.name}
                  disabled
                ></input>
              </div>

              <div
                className={`customer-inquiry-form-item ${
                  messageInfo['email'] ? 'error' : ''
                }`}
              >
                <label htmlFor="email">
                  이메일 주소 <small>(필수)</small>
                </label>

                <input
                  id="email"
                  className="customer-inquiry-input"
                  type="text"
                  value={userInfo?.email}
                  disabled
                ></input>
              </div>

              {/* 문의 유형 기획서에서 삭제 됨 (ADMIN 기획서 참고) */}
              {/* <div className={`customer-inquiry-form-item ${messageInfo['type'] ? 'error': ''}`}>
                <label htmlFor="type">문의유형</label>

                <select
                  id="type"
                  className="customer-inquiry-input"
                  type="text"
                >
                  <option
                    value=""
                    className="customer-inquiry-type-placeholder"
                    disabled
                    selected
                  >
                    문의유형을 선택해주세요.
                  </option>
                  <option value="abc">abc</option>
                </select>
              </div> */}

              <div
                className={`customer-inquiry-form-item ${
                  messageInfo['title'] ? 'error' : ''
                }`}
              >
                <label htmlFor="title">
                  제목 <small>(필수)</small>
                </label>

                <input
                  id="title"
                  name="title"
                  className="customer-inquiry-input"
                  type="text"
                  placeholder="제목을 입력해주세요."
                  maxLength="100"
                  value={title}
                  onChange={handleChange}
                ></input>

                <small className="limit-text">100자 이하</small>
              </div>

              <div
                className={`customer-inquiry-form-item ${
                  messageInfo['html'] ? 'error' : ''
                }`}
              >
                <label htmlFor="html">
                  내용 <small>(필수)</small>
                </label>

                <textarea
                  id="html"
                  name="html"
                  className="customer-inquiry-textarea"
                  placeholder="문의내용을 입력해주세요."
                  maxLength="1000"
                  value={html}
                  onChange={handleChange}
                ></textarea>

                <small className="limit-text">1000자 이하</small>
              </div>

              <div
                className={`customer-inquiry-form-item ${
                  messageInfo['file'] ? 'error' : ''
                }`}
              >
                <label htmlFor="file">파일첨부</label>

                <div className="customer-inquiry-form-file">
                  <input
                    id="file"
                    name="file"
                    className="customer-inquiry-file-input"
                    type="file"
                    onChange={changeFileInfo}
                  ></input>

                  <input
                    className="customer-inquiry-file-name"
                    value={fileInfo?.name || ''}
                    onClick={browseFile}
                    readOnly
                  ></input>

                  <button
                    className="customer-inquiry-file-btn"
                    onClick={browseFile}
                  >
                    파일 찾기
                  </button>
                </div>
              </div>

              <div className="customer-inquiry-form-btn">
                <button
                  className="btn-confirm customer-inquiry-btn"
                  onClick={saveInquiry}
                >
                  문의하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CustomerInquiry;
