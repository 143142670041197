import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { FormGroup, Form, Button } from 'react-bootstrap';
import { API_FIND_COMPANY } from '../../../constants/apiConstants';
import { showToast } from '../../../redux/actions/globalActions';

const FindCompany = ({ changeCompanyInfo, setIsShowFindCompany }) => {
  const [keyword, setKeyword] = useState('');
  const [companies, setCompanies] = useState([]);
  const [targetIndex, setTargetIndex] = useState(-1);
  const dispatch = useDispatch();

  const findCompany = async () => {
    try {
      const query = window.encodeURIComponent(`name=${keyword}`);
      const url = `${API_FIND_COMPANY}?${query}`;
      const method = 'GET';
      const res = await axios({ url, method });
      const resData = res.data;
      const companies = resData.data;
      console.log('findCompany() - resData', resData);

      setTargetIndex(-1);
      setCompanies(companies);

      if (companies.length <= 0)
        dispatch(showToast({ message: '해당 이름의 회사가 없습니다.' }));
    } catch (err) {
      console.log('err', err);
      const errorMessage =
        err.response && err.response.data.msg
          ? err.response.data.msg
          : '회사 검색에 실패했습니다.<br/>다시 시도해 주세요.';
      dispatch(showToast({ message: errorMessage }));
    }
  };

  const complete = () => {
    const selectedCompany = companies[targetIndex];
    if (selectedCompany) {
      changeCompanyInfo(selectedCompany);
      hideFindCompany();
    } else {
      dispatch(showToast({ message: '회사를 선택해 주세요.' }));
    }
  };

  const hideFindCompany = () => {
    setIsShowFindCompany(false);
  };

  const changeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') findCompany();
  };

  return (
    <div className="modal">
      <div className="modal-wrap modal-find-company">
        <div className="modal-header">회사 찾기</div>

        <div className="modal-body">
          <FormGroup className="modal-body-find">
            <div className="modal-body-find-input">
              <Form.Control
                className="find-input"
                type="text"
                placeholder="회사 이름을 입력해 주세요"
                value={keyword}
                onChange={changeKeyword}
                onKeyDown={handleKeyDown}
                autoFocus
              ></Form.Control>

              <Button className="find-button" onClick={findCompany}>
                검색
              </Button>
            </div>
          </FormGroup>

          <div className="modal-body-companies">
            <div className="modal-body-companies-title mb-1">회사 리스트</div>
            <div className="modal-body-company-list">
              {companies.map((company, index) => (
                <div
                  className={`modal-body-company-item ${
                    index === targetIndex ? 'active' : ''
                  }`}
                  key={company.id}
                  onClick={() => setTargetIndex(index)}
                >
                  {company.name}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button className="btn-cancel" onClick={hideFindCompany}>
            취소
          </button>
          <button className="btn-confirm" onClick={complete}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default FindCompany;
