import axios from 'axios';
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_LOGIN_CHECK_REQUEST,
  USER_LOGIN_CHECK_SUCCESS,
  USER_LOGIN_CHECK_FAILURE,
  USER_SIGN_UP_REQUEST,
  USER_SIGN_UP_SUCCESS,
  USER_SIGN_UP_FAILURE,
  USER_SIGN_UP_RESET,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_FAILURE,
  USER_DETAIL_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_UPDATE_RESET,
  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PASSWORD_UPDATE_FAILURE,
  USER_PASSWORD_UPDATE_RESET,
  USER_CLEAR_ERROR,
} from '../types/userTypes';
import {
  API_SIGN_IN,
  API_SIGN_UP,
  API_USER_REFRESH,
  API_USER_DETAIL,
  API_USER_UPDATE,
  API_PASSWORD_UPDATE,
} from '../../constants/apiConstants';
import { checkAuth } from '../middlewares/checkAuth';

export const login =
  ({ id, password }, isIdRemember) =>
  async (dispatch) => {
    try {
      // 로그인 작업 시작
      dispatch({ type: USER_LOGIN_REQUEST });

      const url = API_SIGN_IN;
      const data = { id, password };
      const method = 'POST';

      const res = await axios({ url, method, data });
      const resData = res.data ? res.data.data : res.data;
      const { token, userInfo } = resData;
      userInfo.id = id;
      console.log('login() - resData', resData);

      // 아이디 기억하기 저장
      if (isIdRemember) {
        const loginInfo = { id, isIdRemember };
        localStorage.setItem('loginInfo', JSON.stringify(loginInfo));
      } else {
        localStorage.removeItem('loginInfo');
      }

      // 이메일 인증 완료 된 사용자인지 확인
      const isAuthenticated = userInfo.authEmail;
      if (isAuthenticated) {
        // 로그인 정보 localStorage 에 저장
        localStorage.setItem('token', token);
        localStorage.setItem('userInfo', JSON.stringify(userInfo));

        // Header 에 토큰 세팅
        axios.defaults.headers['Authorization'] = token;
      }

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: { token, userInfo, isAuthenticated },
      });
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: USER_LOGIN_FAILURE,
        // payload:
        //   err.response && err.response.data.msg
        //     ? err.response.data.msg
        //     : err.message,

        // 로그인/아이디 찾기/비밀번호 찾기 실패 메시지는 서버에서 내려오는 메시지 사용하지 않고, 하드코딩으로 고정
        payload: '회원 정보를 찾을 수 없습니다.',
      });
    }
  };

export const logout = () => (dispatch) => {
  // 로그인 정보 localStorage 에서 제거
  localStorage.removeItem('token');
  localStorage.removeItem('userInfo');

  // 헤더에 토큰 제거
  delete axios.defaults.headers['Authorization'];
  dispatch({ type: USER_LOGOUT });
};

export const checkLogin = () => async (dispatch) => {
  try {
    // localStorage에 로그인 정보 있는지 확인
    let token = localStorage.getItem('token');
    let userInfo = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : null;

    if (!token || !userInfo) {
      return dispatch({ type: USER_LOGIN_CHECK_FAILURE });
    }

    // 로그인 확인 작업 시작
    dispatch({ type: USER_LOGIN_CHECK_REQUEST });

    const url = API_USER_REFRESH;
    const method = 'GET';
    const headers = { Authorization: token };

    const res = await axios({ url, method, headers });
    const resData = res.data;
    token = resData;
    console.log('checkLogin() - resData', resData);

    // 이메일 인증 완료 된 사용자인지 확인
    const isAuthenticated = userInfo.authEmail;
    if (isAuthenticated) {
      // 로그인 정보 localStorage 에 저장
      localStorage.setItem('token', token);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));

      // Header 에 토큰 세팅
      axios.defaults.headers['Authorization'] = token;
    }

    dispatch({
      type: USER_LOGIN_CHECK_SUCCESS,
      payload: { token, userInfo, isAuthenticated },
    });
  } catch (err) {
    console.log('err', err);
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');

    dispatch({
      type: USER_LOGIN_CHECK_FAILURE,
      payload: '',
    });
  }
};

export const signUp = (newUser) => async (dispatch) => {
  try {
    dispatch({ type: USER_SIGN_UP_REQUEST });

    const url = API_SIGN_UP;
    const method = 'POST';
    const data = newUser;

    const res = await axios({ url, method, data });
    const resData = res.data;
    const { name, crtDate, roleName, token } = resData.data;
    console.log('signUp() - resData', resData);

    dispatch({
      type: USER_SIGN_UP_SUCCESS,
      payload: { newUser, name, crtDate, roleName, token },
    });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: USER_SIGN_UP_FAILURE,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const getUserDetail = () => async (dispatch, getState) => {
  try {
    const isAuth = checkAuth(dispatch, getState);
    if (!isAuth) return;

    dispatch({ type: USER_DETAIL_REQUEST });

    const url = API_USER_DETAIL;
    const method = 'GET';

    const res = await axios({ url, method });
    const resData = res.data;
    const {
      user: userDetail,
      companyInfo: userCompany,
      codes: userCodes,
      attachFileVO: userFile,
    } = resData.data;
    console.log('getUserDetail() - resData', resData);

    dispatch({
      type: USER_DETAIL_SUCCESS,
      payload: { userDetail, userCodes, userCompany, userFile },
    });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: USER_DETAIL_FAILURE,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const updateUser =
  (userInfo = {}) =>
  async (dispatch, getState) => {
    try {
      const isAuth = checkAuth(dispatch, getState);
      if (!isAuth) return;

      dispatch({ type: USER_UPDATE_REQUEST });

      const url = API_USER_UPDATE;
      const method = 'PUT';
      const data = userInfo;

      const res = await axios({ url, method, data });
      const resData = res.data;
      console.log('updateUser() - resData', resData);

      dispatch({ type: USER_UPDATE_SUCCESS });
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: USER_UPDATE_FAILURE,
        payload:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.message,
      });
    }
  };

export const updatePassword =
  (passwordInfo = {}) =>
  async (dispatch, getState) => {
    try {
      const isAuth = checkAuth(dispatch, getState);
      if (!isAuth) return;

      dispatch({ type: USER_PASSWORD_UPDATE_REQUEST });

      const url = API_PASSWORD_UPDATE;
      const method = 'PUT';
      const data = passwordInfo;

      const res = await axios({ url, method, data });
      const resData = res.data;
      console.log('updatePassword() - resData', resData);

      dispatch({ type: USER_PASSWORD_UPDATE_SUCCESS });
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: USER_PASSWORD_UPDATE_FAILURE,
        payload:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.message,
      });
    }
  };

export const resetSignUp = () => ({
  type: USER_SIGN_UP_RESET,
});

export const resetUserDetail = () => ({
  type: USER_DETAIL_RESET,
});

export const resetUserUpdate = () => ({
  type: USER_UPDATE_RESET,
});

export const resetPasswordUpdate = () => ({
  type: USER_PASSWORD_UPDATE_RESET,
});

export const clearError = () => ({
  type: USER_CLEAR_ERROR,
});
