import React from 'react';
import Footer from '../layouts/Footer';
import PopupEmailCheck from '../popups/PopupEmailCheck';

const EmailCheck = ({ history }) => {
  return (
    <div className="email-check">
      <div className="email-check-wrap">
        <PopupEmailCheck />
      </div>

      <Footer />
    </div>
  );
};

export default EmailCheck;
