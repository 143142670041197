import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import axios from 'axios';
import store from './redux/index.js';
import { API_HEADER_SYSTEM_NAME } from './constants/apiConstants';
import './styles/main.scss';

axios.defaults.headers['Pragma'] = 'no-cache';
axios.defaults.headers['K-System'] = API_HEADER_SYSTEM_NAME;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
