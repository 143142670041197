import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import {
  getPopupMessageChecked,
  resetPopupMessageChecked,
} from '../../redux/actions/popupActions';
import { USER_ROLE_NAME_INFO } from '../../constants/dataConstants';

const PopupUserRole = ({ messageInfo, hidePopup }) => {
  const dispatch = useDispatch();
  const { isSuccess } = useSelector((state) => state.popupMessageChecked);

  const [textInfo, setTextInfo] = useState({
    title: '',
    subTitle: '',
    message: '',
    memo: '',
  });

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    if (isSuccess) dispatch(resetPopupMessageChecked());
  }, [isSuccess]);

  const handleConfirm = () => {
    dispatch(getPopupMessageChecked());
    hidePopup();
  };

  const setData = () => {
    const { detail, memo } = messageInfo;
    const changeInfo = detail.split('|');
    const role = changeInfo[0];
    const changeType = changeInfo[1];
    const changeText =
      changeType === 'UP' ? '승인' : changeType === 'DOWN' ? '변경' : '미승인';
    const roleName = USER_ROLE_NAME_INFO[role];

    const title = getTitle(roleName, changeText);
    const subTitle = getSubTitle(roleName, changeText);
    const message = getMessage(roleName, changeText, memo);

    setTextInfo({
      title,
      subTitle,
      message,
      memo,
    });
  };

  const getTitle = (roleName, changeText) => {
    if (roleName === '준회원' && changeText === '미승인') {
      return '정회원 미승인';
    } else {
      return `${roleName} ${changeText}`;
    }
  };

  const getSubTitle = (roleName, changeText) => {
    if (roleName === '정회원' && changeText === '승인') {
      return '정회원 가입이 승인되었습니다.';
    } else if (roleName === '준회원' && changeText === '미승인') {
      return '정회원으로 승인되지 않았습니다.';
    } else {
      return `${roleName}으로 ${changeText}되었습니다.`;
    }
  };

  const getMessage = (roleName, changeText, memo) => {
    if (roleName === '정회원' && changeText === '승인') {
      return `안녕하세요. EXsys Value입니다.
      <br />
      <br />
      정회원으로 승인되셨습니다.
      <br />
      올바른 평가 문화에 힘이 되어주세요.
      <br />
      <br />
      감사합니다.`;
    } else if (roleName === '준회원' && changeText === '미승인') {
      return `안녕하세요. EXsys Value입니다.
      <br />
      <br />
      정회원으로 승인되지 않았습니다.
      <br />
      <br />
      <br />
      사유:
      <br />
      <br />
      <strong>${memo}</strong>`;
    } else if (roleName === '준회원' && changeText === '변경') {
      return `안녕하세요. EXsys Value입니다.
      <br />
      <br />
      준회원으로 변경되었습니다.
      <br />
      <br />
      <br />
      사유:
      <br />
      <br />
      <strong>${memo}</strong>`;
    } else {
      return `안녕하세요. EXsys Value입니다.
      <br />
      <br />
      회원 등급이 ${roleName}으로 ${changeText}되었습니다.
      <br />
      <br />
      감사합니다.`;
    }
  };

  return (
    <div className="popup">
      <div className="popup-wrap popup-user-role">
        <div className="popup-header">
          <div className="popup-logo"></div>
        </div>

        <div className="popup-body">
          <div className="popup-title">{textInfo?.title}</div>

          <div className="popup-main">
            <div className="popup-main-title">{textInfo?.subTitle}</div>

            <div className="popup-main-content">{parse(textInfo?.message)}</div>

            <div className="popup-main-btn">
              <button className="btn-confirm" onClick={handleConfirm}>
                EXsysValue 바로가기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupUserRole;
