import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import { hideAlert } from '../../redux/actions/globalActions';

const Alert = () => {
  const alert = useSelector((state) => state.alert);
  const {
    title = '',
    yes = '확인',
    message = '',
    callback = null,
    isShow,
  } = alert;
  const dispatch = useDispatch();

  const hide = () => {
    dispatch(hideAlert());

    if (typeof callback === 'function') callback();
  };

  return (
    <>
      {isShow ? (
        <div className="modal">
          <div
            className={`modal-wrap modal-alert ${
              title === '' ? 'no-title' : ''
            }`}
          >
            <div className="modal-header">{parse(title)}</div>
            <div className="modal-body">{parse(message)}</div>

            <div className="modal-footer">
              <button className="btn-confirm modal-btn-confirm" onClick={hide}>
                {yes}
              </button>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Alert;
