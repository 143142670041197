import axios from 'axios';
import {
  POPUP_TODAY_REQUEST,
  POPUP_TODAY_SUCCESS,
  POPUP_TODAY_FAILURE,
  POPUP_TODAY_RESET,
  POPUP_MESSAGE_REQUEST,
  POPUP_MESSAGE_SUCCESS,
  POPUP_MESSAGE_FAILURE,
  POPUP_MESSAGE_RESET,
  POPUP_MESSAGE_CHECKED_REQUEST,
  POPUP_MESSAGE_CHECKED_SUCCESS,
  POPUP_MESSAGE_CHECKED_FAILURE,
  POPUP_MESSAGE_CHECKED_RESET,
  POPUP_CHECKED,
  POPUP_CHECKED_CLOSE,
  POPUP_CHECKED_RESET,
  POPUP_CLEAR_ERROR,
} from '../types/popupTypes';
import {
  API_POPUP_TODAY,
  API_POPUP_MESSAGE,
  API_POPUP_MESSAGE_CHECKED,
} from '../../constants/apiConstants';
import { checkAuth } from '../middlewares/checkAuth';

export const getPopupTodays = () => async (dispatch) => {
  try {
    dispatch({ type: POPUP_TODAY_REQUEST });

    const url = API_POPUP_TODAY;
    const method = 'GET';

    const res = await axios({ url, method });
    const resData = res.data || {};
    const popupTodayData = resData.data;
    const popupTodays = popupTodayData || [];
    console.log('getTodayPopup() - resData', resData);

    dispatch({ type: POPUP_TODAY_SUCCESS, payload: { popupTodays } });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: POPUP_TODAY_FAILURE,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const getPopupMessage = () => async (dispatch, getState) => {
  try {
    const isAuth = checkAuth(dispatch, getState);
    if (!isAuth) return;

    dispatch({ type: POPUP_MESSAGE_REQUEST });

    const url = API_POPUP_MESSAGE;
    const method = 'GET';

    const res = await axios({ url, method });
    const resData = res.data;
    const messageData = resData.data || {};
    const { adminActionVO, secondCount } = messageData;
    const popupMessageInfo = {
      userRoleMessageInfo: adminActionVO,
      secondCount,
    };
    console.log('getPopupMessage() - resData', resData);

    dispatch({
      type: POPUP_MESSAGE_SUCCESS,
      payload: { popupMessageInfo },
    });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: POPUP_MESSAGE_FAILURE,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const getPopupMessageChecked = () => async (dispatch, getState) => {
  try {
    const isAuth = checkAuth(dispatch, getState);
    if (!isAuth) return;

    dispatch({ type: POPUP_MESSAGE_CHECKED_REQUEST });

    const url = API_POPUP_MESSAGE_CHECKED;
    const method = 'PUT';

    const res = await axios({ url, method });
    const resData = res.data;
    console.log('getPopupMessageChecked() - resData', resData);

    dispatch({ type: POPUP_MESSAGE_CHECKED_SUCCESS });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: POPUP_MESSAGE_CHECKED_FAILURE,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const checkPopup = () => ({
  type: POPUP_CHECKED,
});

export const forceClosePopup = () => ({
  type: POPUP_CHECKED_CLOSE,
});

export const resetPopupToday = () => ({
  type: POPUP_TODAY_RESET,
});

export const resetPopupMessage = () => ({
  type: POPUP_MESSAGE_RESET,
});

export const resetPopupMessageChecked = () => ({
  type: POPUP_MESSAGE_CHECKED_RESET,
});

export const resetPopupCheck = () => ({
  type: POPUP_CHECKED_RESET,
});

export const clearError = () => ({
  type: POPUP_CLEAR_ERROR,
});
