import utils from '../../modules/utils';
import { showAlert } from '../actions/globalActions';
import { logout } from '../actions/userActions';

export const checkAuth = (dispatch, getState) => {
  const state = getState();
  const { token } = state.user;
  let isValidToken = false;

  if (token) {
    const parsedToken = utils.parseJwt(token);
    const expireDate = parsedToken.exp * 1000;
    isValidToken = Date.now() < expireDate;
  }

  if (!isValidToken) {
    dispatch(
      showAlert({
        message: '세션이 만료되었습니다.<br/>다시 로그인해주세요.',
      })
    );

    dispatch(logout());
  }

  return isValidToken;
};
