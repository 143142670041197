import axios from 'axios';
import {
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_RESET,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_FAILURE,
  FILE_DELETE_RESET,
  FILE_CLEAR_ERROR,
} from '../types/fileTypes';
import { API_FILE_UPLOAD, API_FILE_DELETE } from '../../constants/apiConstants';

export const uploadFile =
  (type, file, filename, userId) => async (dispatch) => {
    try {
      dispatch({ type: FILE_UPLOAD_REQUEST });

      const query = window.encodeURIComponent(`type=${type}&userId=${userId}`);
      const url = `${API_FILE_UPLOAD}?${query}`;
      const method = 'POST';
      const data = new FormData();
      data.append('file', file, filename);

      const res = await axios({ url, method, data });
      const resData = res.data || {};
      const fileData = resData.data;
      console.log('uploadFile() - resData', resData);

      dispatch({
        type: FILE_UPLOAD_SUCCESS,
        payload: { fileData },
      });
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: FILE_UPLOAD_FAILURE,
        payload:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.message,
      });
    }
  };

export const deleteFile = (fileInfo) => async (dispatch) => {
  try {
    dispatch({ type: FILE_DELETE_REQUEST });

    const query = window.encodeURIComponent(
      `id=${fileInfo.id}&userId=${fileInfo.userId}&expireDate=${fileInfo.expireDate}`
    );
    const url = `${API_FILE_DELETE}?${query}`;
    const method = 'DELETE';

    const res = await axios({ url, method });
    const resData = res.data || {};
    const fileData = resData.data;
    console.log('deleteFile() - resData', resData);

    dispatch({
      type: FILE_DELETE_SUCCESS,
      payload: { fileData },
    });
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: FILE_DELETE_FAILURE,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const resetFileUpload = () => ({
  type: FILE_UPLOAD_RESET,
});

export const resetFileDelete = () => ({
  type: FILE_DELETE_RESET,
});

export const clearError = () => ({
  type: FILE_CLEAR_ERROR,
});
