import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import AgreementModal from '../pages/signUp/AgreementModal';
import { FOOTER_RELATIVE_SITES } from '../../constants/dataConstants';

const Footer = () => {
  const terms = [
    {
      title: '서비스 이용 약관',
      type: 'terms',
      isEssential: true,
      isAgree: false,
      isEvent: false,
    },
    {
      title: '개인정보 수집 및 이용 동의',
      type: 'privacy',
      isEssential: true,
      isAgree: false,
      isEvent: false,
    },
  ];

  const history = useHistory();
  const [agreementDetail, setAgreementDetail] = useState(null);

  const showDetail = (index) => {
    setAgreementDetail(terms[index]);
  };

  const goNotice = () => {
    history.push('/customer/notice');
  };

  const openWebsite = (url) => {
    window.open(url);
  };

  return (
    <div id="footer">
      {agreementDetail ? (
        <AgreementModal
          agreementDetail={agreementDetail}
          setAgreementDetail={setAgreementDetail}
        />
      ) : (
        ''
      )}

      <ul>
        <li onClick={() => showDetail(0)}>이용약관</li>
        <li onClick={() => showDetail(1)}>개인정보처리방침</li>
        <li onClick={goNotice}>공지사항</li>
        <li>
          <NavDropdown title="관련사이트">
            {FOOTER_RELATIVE_SITES.map((website, index) => (
              <LinkContainer
                to="#"
                onClick={() => openWebsite(website.url)}
                key={index}
              >
                <NavDropdown.Item>{website.name}</NavDropdown.Item>
              </LinkContainer>
            ))}
          </NavDropdown>
        </li>
      </ul>
      <small>
        COPYRIGHT © 2021 KOREA PATENT ATTORNEYS ASSOCIATION. Developed by WERT
        Intelligence Co., Ltd
      </small>
    </div>
  );
};

export default Footer;
