import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import { hideToast } from '../../redux/actions/globalActions';

const Toast = () => {
  const toast = useSelector((state) => state.toast);
  const [toastIndex, setToastIndex] = useState(-1);
  const { message = '', duration = 2000, callback = null, isShow } = toast;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isShow) {
      const toastHideIndex = toastIndex + 1;
      setToastIndex(toastHideIndex);
      setTimeout(() => hide(toastHideIndex - 1), duration);
    }
  }, [isShow]);

  const hide = (toastHideIndex = toastIndex) => {
    if (toastHideIndex === toastIndex) {
      dispatch(hideToast());

      if (typeof callback === 'function') callback();
    }
  };

  return (
    <>
      {isShow ? (
        <div className="app-toast" onClick={() => hide(toastIndex)}>
          <div className="toast-content">{parse(message)}</div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Toast;
