import React from 'react';
import { WORKING_DATE, APP_VERSION } from '../../constants/devConstants';

const VersionInfo = () => {
  return (
    <div id="version-info">
      작업 날짜 : {WORKING_DATE}
      <span>/</span>
      버전 : v{APP_VERSION}
    </div>
  );
};

export default VersionInfo;
