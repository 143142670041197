import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import Message from '../../common/Message';
import Loading from '../../common/Loading';
import ErrorText from '../../common/ErrorText';
import Footer from '../../layouts/Footer';
import FindIdModal from './FindIdModal';
import FindPasswordModal from './FindPasswordModal';
import { login, clearError } from '../../../redux/actions/userActions';

const Login = ({ location, history }) => {
  const [loginInfo, setLoginInfo] = useState({ id: '', password: '' });
  const [foundedId, setFoundedId] = useState('');
  const [isIdRemember, setIsIdRemember] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});

  const dispatch = useDispatch();
  const { error, isLoading, isLogin, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const redirect = location.search ? location.search.split('=')[1] : '/';
  const [isShowFindId, setIsShowFindId] = useState(false);
  const [isShowFindPassword, setIsShowFindPassword] = useState(false);

  // 로그인 페이지 진입 시
  useEffect(() => {
    if (isLogin) {
      if (isAuthenticated) {
        history.push(redirect);
      } else {
        history.push('/email_check');
      }
    }
  }, [history, isLogin, redirect]);

  // 아이디 찾기로 찾은 아이디 세팅
  useEffect(() => {
    if (foundedId) changeToFoundedId();
  }, [foundedId]);

  useEffect(() => {
    setMessageInfo({});
    dispatch(clearError());

    const savedLoginInfo = localStorage.getItem('loginInfo')
      ? JSON.parse(localStorage.getItem('loginInfo'))
      : null;
    if (savedLoginInfo) {
      let newLoginInfo = loginInfo || {};
      newLoginInfo['id'] = savedLoginInfo['id'] || '';
      setLoginInfo(newLoginInfo);
      setIsIdRemember(savedLoginInfo.isIdRemember);
    }
  }, []);

  const checkValid = () => {
    const { id, password } = loginInfo;
    const validationInfo = {};

    if (id.trim() === '') {
      validationInfo.id = '아이디를 입력해주세요.';
    }
    if (password.trim() === '') {
      validationInfo.password = '비밀번호를 입력해주세요.';
    }

    setMessageInfo(validationInfo);
    const isValid = Object.keys(validationInfo).length === 0;
    return isValid;
  };

  const loginHandler = async (e) => {
    e.preventDefault();

    let isValid = checkValid();
    if (isValid) dispatch(login(loginInfo, isIdRemember));
  };

  const changeToFoundedId = () => {
    const newLoginInfo = { ...loginInfo };
    newLoginInfo['id'] = foundedId;
    setLoginInfo(newLoginInfo);
  };

  const showFindId = () => {
    setIsShowFindId(true);
  };

  const hideFindId = () => {
    setIsShowFindId(false);
  };

  const showFindPassword = () => {
    setIsShowFindPassword(true);
  };

  const hideFindPassword = () => {
    setIsShowFindPassword(false);
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;

    loginInfo[name] = value;

    // 타이핑이 되면 에러 메시지 초기화
    setMessageInfo({});
  };

  return (
    <div className="login">
      <div className="login-wrap">
        <div className="login-text">로그인</div>

        {isLoading && <Loading />}
        {isShowFindId && (
          <FindIdModal
            setFoundedId={setFoundedId}
            showFindPassword={showFindPassword}
            hideFindId={hideFindId}
          />
        )}
        {isShowFindPassword && (
          <FindPasswordModal
            foundedId={foundedId}
            setFoundedId={setFoundedId}
            hideFindPassword={hideFindPassword}
          />
        )}

        <Form className="login-form" onSubmit={loginHandler}>
          <Form.Group
            controlId="formLoginId"
            className={messageInfo.id ? 'login-form-error' : ''}
          >
            <input
              name="id"
              type="text"
              placeholder="아이디"
              value={loginInfo?.id}
              onChange={handleChange}
            />
          </Form.Group>
          {messageInfo.id && (
            <ErrorText variant="danger">{messageInfo.id}</ErrorText>
          )}

          <Form.Group
            className="login-form-password"
            controlId="formLoginPassword"
            className={messageInfo.password ? 'login-form-error' : ''}
          >
            <input
              name="password"
              type="password"
              placeholder="비밀번호"
              value={loginInfo?.password}
              onChange={handleChange}
            />
          </Form.Group>
          {messageInfo.password && (
            <ErrorText variant="danger">{messageInfo.password}</ErrorText>
          )}
          {error && <Message variant="danger">{error}</Message>}

          <div className="login-form-etc">
            <div className="login-id-remember">
              <input
                id="idRemember"
                type="checkbox"
                checked={isIdRemember}
                onChange={(e) => setIsIdRemember(e.target.checked)}
              />
              <label htmlFor="idRemember">아이디 기억하기</label>
            </div>

            <div className="login-find">
              <div
                className="login-find-id login-footer-link"
                onClick={showFindId}
              >
                아이디 찾기
              </div>

              <div className="login-find-split">|</div>

              <div
                className="login-find-password login-footer-link"
                onClick={showFindPassword}
              >
                비밀번호 찾기
              </div>
            </div>
          </div>

          <button type="submit" className="btn-confirm btn-login">
            로그인
          </button>
          <button
            type="button"
            className="btn-cancel btn-register"
            onClick={() =>
              history.push(
                redirect ? `/signup?redirect=${redirect}` : '/signup'
              )
            }
          >
            회원가입
          </button>
        </Form>

        {/* <div className="login-message">
          본 시스템은 대한변리사회가 기획한 시스템으로서
          <br />
          현재 시범 테스트 중이며, <br />
          테스트 완료 후 정식서비스될 예정입니다.
        </div> */}

        <div className="login-maintenance">
          매일 23:00~01:00 서버 점검 및 업데이트로, 서비스가 중단될 수 있습니다.
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
