const utils = {
  // Default값이 있는 parseInt 함수
  parseInt(target, defaultValue) {
    if (
      isNaN(target) ||
      typeof parseInt(target) !== 'number' ||
      this.isEmptyString(target)
    ) {
      return defaultValue;
    }

    return parseInt(target);
  },

  // JWT 의 payload 를 파싱해서 리턴하는 함수
  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  },

  // Null, Undefined 체크 함수
  isNull(target) {
    return target == null || typeof target === 'undefined';
  },

  // object null 체크함수
  isEmpty(object) {
    return Object.keys(object).length === 0;
  },

  // Null, Undefined, Empty String 체크 함수
  isEmptyString(target) {
    return this.isNull(target) || target === '';
  },

  // 입력된 날짜 유효한 날짜 형식인지 확인하는 함수
  isValidDate(value) {
    let date = new Date(value);
    return date instanceof Date && !isNaN(date);
  },

  // 현재 날짜 및 시간을 DateTime 형식으로 반환해주는 함수
  getNowDate() {
    const date = new Date();
    let dateString = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
    dateString = dateString.replace('T', ' ').split('.')[0];

    return dateString;
  },

  // 입력된 날짜를 yyyy-mm-dd 형태로 반환해주는 함수
  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    let newDate = [year, month, day].join('-');
    let isValidDate = this.isValidDate(newDate);

    return isValidDate ? newDate : '';
  },

  // 텍스트의 하이픈 제거 (핸드폰 번호에 사용)
  clearHyphen(txt) {
    if (typeof txt === 'string') {
      txt = txt.replace('-', '');
    }

    return txt;
  },

  // 창 강제종료 방지 코드
  blockClose(isBlock = true) {
    if (isBlock) {
      onbeforeunload = (event) => {
        return isBlock;
      };
    } else {
      onbeforeunload = null;
    }
  },

  // Input[type=file]에 입력된 file 정보를 blob으로 반환하는 함수
  getFileBlob(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        try {
          let fileData = e.target.result;
          let fileRes = await fetch(fileData);
          let fileBlob = await fileRes.blob();

          resolve(fileBlob);
        } catch (err) {
          reject(err);
        }
      };
    });
  },

  // HTML 형태의 String에서 Text만 추출해주는 함수
  getHtmlText(html) {
    const htmlWrap = document.createElement('div');
    htmlWrap.innerHTML = html;
    const htmlText = htmlWrap.innerText || '';
    return htmlText;
  },

  // Text에 포함되어 있는 줄바꿈/탭 제거
  removeWordBreaker(text) {
    return typeof text === 'string'
      ? text.replace(/\n/g, '').replace(/\t/g, '')
      : '';
  },

  // 서버에서 내려온 Text를 Html Text로 변경하는 함수
  convertTextToHtml(text) {
    return typeof text === 'string' ? text.replace(/\n/g, '<br />') : '';
  },
};

export default utils;
