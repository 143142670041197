export const POPUP_TODAY_REQUEST = 'POPUP_TODAY_REQUEST';
export const POPUP_TODAY_SUCCESS = 'POPUP_TODAY_SUCCESS';
export const POPUP_TODAY_FAILURE = 'POPUP_TODAY_FAILURE';
export const POPUP_TODAY_RESET = 'POPUP_TODAY_RESET';

export const POPUP_MESSAGE_REQUEST = 'POPUP_MESSAGE_REQUEST';
export const POPUP_MESSAGE_SUCCESS = 'POPUP_MESSAGE_SUCCESS';
export const POPUP_MESSAGE_FAILURE = 'POPUP_MESSAGE_FAILURE';
export const POPUP_MESSAGE_RESET = 'POPUP_MESSAGE_RESET';

export const POPUP_MESSAGE_CHECKED_REQUEST = 'POPUP_MESSAGE_CHECKED_REQUEST';
export const POPUP_MESSAGE_CHECKED_SUCCESS = 'POPUP_MESSAGE_CHECKED_SUCCESS';
export const POPUP_MESSAGE_CHECKED_FAILURE = 'POPUP_MESSAGE_CHECKED_FAILURE';
export const POPUP_MESSAGE_CHECKED_RESET = 'POPUP_MESSAGE_CHECKED_RESET';

export const POPUP_CHECKED = 'POPUP_CHECKED';
export const POPUP_CHECKED_CLOSE = 'POPUP_CHECKED_CLOSE';
export const POPUP_CHECKED_RESET = 'POPUP_CHECKED_RESET';

export const POPUP_CLEAR_ERROR = 'POPUP_CLEAR_ERROR';
