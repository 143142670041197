import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import introductionImage from '../../styles/images/etc/jumbotron-introduction.svg';
import assessmentImage from '../../styles/images/etc/jumbotron-assessment.svg';
import customerImage from '../../styles/images/etc/jumbotron-customer.svg';
import mypageImage from '../../styles/images/etc/jumbotron-mypage.svg';

import utils from '../../modules/utils';

const Indicator = () => {
  const history = useHistory();
  const location = useLocation();
  const animatedSelect = makeAnimated();
  const pathMap = {
    introduction: {
      name: '소개',
      text: '대한변리사회 엑시스밸류 소개 페이지입니다.',
      image: introductionImage,
      sub: {
        assessment: {
          name: '평가 시스템 소개',
          path: '/introduction/assessment',
        },
        service: {
          name: '이용안내',
          path: '/introduction/service',
        },
      },
    },
    assessment: {
      name: '특허평가',
      text: '대한변리사회 엑시스밸류 특허평가 페이지입니다.',
      image: assessmentImage,
      sub: {
        first: {
          name: '평가 대상 특허문헌',
          path: '/assessment/first',
        },
        second: {
          name: '2차 특허문헌평가 대상',
          path: '/assessment/second',
        },
      },
    },
    customer: {
      name: '고객센터',
      text: '대한변리사회 엑시스밸류 고객센터입니다.',
      image: customerImage,
      sub: {
        notice: {
          name: '공지사항',
          path: '/customer/notice',
        },
        faq: {
          name: 'FAQ',
          path: '/customer/faq',
        },
        inquiry: {
          name: '문의하기',
          path: '/customer/inquiry',
        },
      },
    },
    mypage: {
      name: '마이페이지',
      text: '대한변리사회 엑시스밸류 마이페이지입니다.',
      image: mypageImage,
      sub: {
        info: {
          name: '내 정보 관리',
          path: '/mypage/info',
        },
        assessment: {
          name: '내 평가 관리',
          path: '/mypage/assessment',
        },
      },
    },
  };

  const [mainPath, setMainPath] = useState('');
  const [subPath, setSubPath] = useState('');
  const [subInfo, setSubInfo] = useState({});
  const [mainOptions, _] = useState(
    Object.keys(pathMap).map((pathKey) => ({
      label: pathMap[pathKey]?.name,
      value: pathKey,
    }))
  );
  const [subOptions, setSubOptions] = useState(
    Object.values(subInfo).map((info) => ({
      label: info?.name,
      value: info?.path,
    }))
  );
  const [mainOptionIndex, setMainOptionIndex] = useState(0);
  const [subOptionIndex, setSubOptionIndex] = useState(0);

  useEffect(() => {
    changeMainPath();
    changeSubPath();
    checkOptions();
  }, [history, location]);

  useEffect(() => {
    changeSubInfo();
  }, [mainPath]);

  useEffect(() => {
    checkSubPath();
    setSubOptions(
      Object.values(subInfo).map((info) => ({
        label: info?.name,
        value: info?.path,
      }))
    );
  }, [subInfo, subPath]);

  useEffect(() => {
    checkOptions();
  }, [subOptions]);

  const checkSubPath = () => {
    const isSameMainPath = subPath.split('/')[1] === mainPath;
    if (!isSameMainPath) {
      const defaultSubPath = Object.values(subInfo)[0]?.path;
      changePath(defaultSubPath);
    }
  };

  const changeMainPath = () => {
    const pathname = location.pathname;
    const paths = pathname.split('/') || [];
    const newMainPath = paths.find((path) => !utils.isEmptyString(path));
    setMainPath(newMainPath);
  };

  const changeSubPath = () => {
    const paths = location.pathname.split('/');
    const subPath = paths[2] || '';
    const path = `/${mainPath}/${subPath}`;
    if (path !== subPath) setSubPath(path);
  };

  const changeSubInfo = () => {
    if (!mainPath) return;

    const mainPathInfo = pathMap[mainPath];
    const subPathInfo = mainPathInfo.sub;
    setSubInfo(subPathInfo);
  };

  const changePath = (newPath) => {
    history.push(newPath);
  };

  const changeMainOption = (selectedOption) => {
    const { value } = selectedOption;
    const mainIndex = mainOptions.findIndex((option) => option.value === value);
    setMainOptionIndex(mainIndex);
    setMainPath(value);
  };

  const changeSubOption = (selectedOption) => {
    const { value } = selectedOption;
    const subIndex = subOptions.findIndex((option) => option.value === value);
    setSubOptionIndex(subIndex);
    setSubPath(value);
    changePath(value);
  };

  const checkOptions = () => {
    const mainIndex = mainOptions.findIndex(
      (option) => option.value === mainPath
    );
    const subIndex = subOptions.findIndex((option) => option.value === subPath);
    setMainOptionIndex(mainIndex);
    setSubOptionIndex(subIndex);
  };

  return (
    <>
      <div className={`jumbotron jumbotron-${mainPath}`}>
        <div className="jumbotron-text-wrap">
          <div className="jumbotron-title">{pathMap[mainPath]?.name}</div>

          <div className="jumbotron-text">{pathMap[mainPath]?.text}</div>
        </div>

        <div className="jumbotron-image">
          <img src={pathMap[mainPath]?.image} alt="Jumbotron" />
        </div>
      </div>

      <div className="indicator">
        <div className="indicator-item-wrap">
          <div
            className="indicator-icon"
            onClick={() => history.push('/')}
          ></div>

          <div className="indicator-main">
            <Select
              name="mainPath"
              className="indicator-main-select"
              classNamePrefix="select"
              defaultValue={{ label: '', value: '' }}
              value={mainOptions[mainOptionIndex]}
              options={mainOptions}
              onChange={changeMainOption}
              components={animatedSelect}
              isSearchable={false}
              isClearable={false}
            />
          </div>

          <div className="indicator-sub">
            <Select
              name="subPath"
              className="indicator-sub-select"
              classNamePrefix="select"
              defaultValue={{ label: '', value: '' }}
              value={subOptions[subOptionIndex]}
              options={subOptions}
              onChange={changeSubOption}
              components={animatedSelect}
              isSearchable={false}
              isClearable={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Indicator;
